export const forex_symbols = {
  AUDCAD: {
    symbol: 'AUDCAD',
    path: 'FOREX\\Minor\\FOREX\\AUDCAD',
    full_name: 'AUDCAD',
    description: 'Australian Dollar vs Canadian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CAD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  AUDCHF: {
    symbol: 'AUDCHF',
    path: 'FOREX\\Minor\\FOREX\\AUDCHF',
    full_name: 'AUDCHF',
    description: 'Australian Dollar vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  AUDJPY: {
    symbol: 'AUDJPY',
    path: 'FOREX\\Minor\\FOREX\\AUDJPY',
    full_name: 'AUDJPY',
    description: 'Australian Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  AUDNOK: {
    symbol: 'AUDNOK',
    path: 'FOREX\\Minor\\FOREX\\AUDNOK',
    full_name: 'AUDNOK',
    description: 'Australian Dollar vs Norwegian Krone',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  AUDNZD: {
    symbol: 'AUDNZD',
    path: 'FOREX\\Minor\\FOREX\\AUDNZD',
    full_name: 'AUDNZD',
    description: 'Australian Dollar vs New Zealand Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NZD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  AUDSEK: {
    symbol: 'AUDSEK',
    path: 'FOREX\\Minor\\FOREX\\AUDSEK',
    full_name: 'AUDSEK',
    description: 'Australian Dollar vs Swedish Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SEK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  AUDSGD: {
    symbol: 'AUDSGD',
    path: 'FOREX\\Minor\\FOREX\\AUDSGD',
    full_name: 'AUDSGD',
    description: 'Australian Dollar vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  AUDUSD: {
    symbol: 'AUDUSD',
    path: 'FOREX\\Minor\\FOREX\\AUDUSD',
    full_name: 'AUDUSD',
    description: 'Australian Dollar vs US Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CADCHF: {
    symbol: 'CADCHF',
    path: 'FOREX\\Major\\FOREX\\CADCHF',
    full_name: 'CADCHF',
    description: 'Canadian Dollar vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  CADJPY: {
    symbol: 'CADJPY',
    path: 'FOREX\\Major\\FOREX\\CADJPY',
    full_name: 'CADJPY',
    description: 'Canadian Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  CADSGD: {
    symbol: 'CADSGD',
    path: 'FOREX\\Minor\\FOREX\\CADSGD',
    full_name: 'CADSGD',
    description: 'Canada Dollar vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  CHFJPY: {
    symbol: 'CHFJPY',
    path: 'FOREX\\Major\\FOREX\\CHFJPY',
    full_name: 'CHFJPY',
    description: 'Swiss Frank vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  CHFNOK: {
    symbol: 'CHFNOK',
    path: 'FOREX\\Minor\\FOREX\\CHFNOK',
    full_name: 'CHFNOK',
    description: 'Swiss Frank vs Norwegian Krone',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  CHFSGD: {
    symbol: 'CHFSGD',
    path: 'FOREX\\Minor\\FOREX\\CHFSGD',
    full_name: 'CHFSGD',
    description: 'Swiss Franc vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  EURAUD: {
    symbol: 'EURAUD',
    path: 'FOREX\\Minor\\FOREX\\EURAUD',
    full_name: 'EURAUD',
    description: 'Euro vs Australian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  EURCAD: {
    symbol: 'EURCAD',
    path: 'FOREX\\Major\\FOREX\\EURCAD',
    full_name: 'EURCAD',
    description: 'Euro vs Canadian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CAD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  EURCHF: {
    symbol: 'EURCHF',
    path: 'FOREX\\Major\\FOREX\\EURCHF',
    full_name: 'EURCHF',
    description: 'Euro vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  EURCZK: {
    symbol: 'EURCZK',
    path: 'FOREX\\Minor\\FOREX\\EURCZK',
    full_name: 'EURCZK',
    description: 'Euro vs Czech Koruna',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CZK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CZ.svg',
    ],
  },
  EURDKK: {
    symbol: 'EURDKK',
    path: 'FOREX\\Minor\\FOREX\\EURDKK',
    full_name: 'EURDKK',
    description: 'Euro vs Danish Krone',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'DKK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  EURGBP: {
    symbol: 'EURGBP',
    path: 'FOREX\\Major\\FOREX\\EURGBP',
    full_name: 'EURGBP',
    description: 'Euro vs Great Britain Pound ',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  EURHKD: {
    symbol: 'EURHKD',
    path: 'FOREX\\Minor\\FOREX\\EURHKD',
    full_name: 'EURHKD',
    description: 'Euro vs Hong Kong Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  EURHUF: {
    symbol: 'EURHUF',
    path: 'FOREX\\Minor\\FOREX\\EURHUF',
    full_name: 'EURHUF',
    description: 'Euro vs Hungarian Forint',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HUF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/HU.svg',
    ],
  },
  EURJPY: {
    symbol: 'EURJPY',
    path: 'FOREX\\Major\\FOREX\\EURJPY',
    full_name: 'EURJPY',
    description: 'Euro vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  EURMXN: {
    symbol: 'EURMXN',
    path: 'FOREX\\Minor\\FOREX\\EURMXN',
    full_name: 'EURMXN',
    description: 'Euro vs Mexican Peso',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'MXN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/MX.svg',
    ],
  },
  EURNOK: {
    symbol: 'EURNOK',
    path: 'FOREX\\Minor\\FOREX\\EURNOK',
    full_name: 'EURNOK',
    description: 'Euro vs Norwegian Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  EURNZD: {
    symbol: 'EURNZD',
    path: 'FOREX\\Minor\\FOREX\\EURNZD',
    full_name: 'EURNZD',
    description: 'Euro vs New Zealand Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NZD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  EURPLN: {
    symbol: 'EURPLN',
    path: 'FOREX\\Minor\\FOREX\\EURPLN',
    full_name: 'EURPLN',
    description: 'Euro vs Polish Zloty',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'PLN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/PL.svg',
    ],
  },
  EURSEK: {
    symbol: 'EURSEK',
    path: 'FOREX\\Minor\\FOREX\\EURSEK',
    full_name: 'EURSEK',
    description: 'Euro vs Swedish Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SEK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  EURSGD: {
    symbol: 'EURSGD',
    path: 'FOREX\\Minor\\FOREX\\EURSGD',
    full_name: 'EURSGD',
    description: 'Euro vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  EURTRY: {
    symbol: 'EURTRY',
    path: 'FOREX\\Minor\\FOREX\\EURTRY',
    full_name: 'EURTRY',
    description: 'Euro vs New Turkish Lira',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'TRY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
    ],
  },
  EURUSD: {
    symbol: 'EURUSD',
    path: 'FOREX\\Major\\FOREX\\EURUSD',
    full_name: 'EURUSD',
    description: 'Euro vs US Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EURZAR: {
    symbol: 'EURZAR',
    path: 'FOREX\\Minor\\FOREX\\EURZAR',
    full_name: 'EURZAR',
    description: 'Euro vs South African Rand',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'ZAR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
    ],
  },
  GBPAUD: {
    symbol: 'GBPAUD',
    path: 'FOREX\\Minor\\FOREX\\GBPAUD',
    full_name: 'GBPAUD',
    description: 'Great Britain Pound vs Australian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  GBPCAD: {
    symbol: 'GBPCAD',
    path: 'FOREX\\Major\\FOREX\\GBPCAD',
    full_name: 'GBPCAD',
    description: 'Great Britain Pound vs Canadian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CAD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  GBPCHF: {
    symbol: 'GBPCHF',
    path: 'FOREX\\Major\\FOREX\\GBPCHF',
    full_name: 'GBPCHF',
    description: 'Great Britain Pound vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  GBPDKK: {
    symbol: 'GBPDKK',
    path: 'FOREX\\Minor\\FOREX\\GBPDKK',
    full_name: 'GBPDKK',
    description: 'British Pound vs Danish Krone',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'DKK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  GBPHKD: {
    symbol: 'GBPHKD',
    path: 'FOREX\\Minor\\FOREX\\GBPHKD',
    full_name: 'GBPHKD',
    description: 'British Pound vs Hong Kong Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  GBPHUF: {
    symbol: 'GBPHUF',
    path: 'FOREX\\Minor\\FOREX\\GBPHUF',
    full_name: 'GBPHUF',
    description: 'Great Britain Pound vs Hungarian Forint',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HUF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/HU.svg',
    ],
  },
  GBPJPY: {
    symbol: 'GBPJPY',
    path: 'FOREX\\Major\\FOREX\\GBPJPY',
    full_name: 'GBPJPY',
    description: 'Great Britain Pound vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  GBPMXN: {
    symbol: 'GBPMXN',
    path: 'FOREX\\Minor\\FOREX\\GBPMXN',
    full_name: 'GBPMXN',
    description: 'Great Britain Pound vs Mexican Peso',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'MXN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/MX.svg',
    ],
  },
  GBPNOK: {
    symbol: 'GBPNOK',
    path: 'FOREX\\Minor\\FOREX\\GBPNOK',
    full_name: 'GBPNOK',
    description: 'Great Britain Pound vs Norwegian Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  GBPNZD: {
    symbol: 'GBPNZD',
    path: 'FOREX\\Minor\\FOREX\\GBPNZD',
    full_name: 'GBPNZD',
    description: 'Great Britan Pound vs New Zealand Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NZD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  GBPPLN: {
    symbol: 'GBPPLN',
    path: 'FOREX\\Minor\\FOREX\\GBPPLN',
    full_name: 'GBPPLN',
    description: 'Great Britain Pound vs Polish Zloty',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'PLN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/PL.svg',
    ],
  },
  GBPSEK: {
    symbol: 'GBPSEK',
    path: 'FOREX\\Minor\\FOREX\\GBPSEK',
    full_name: 'GBPSEK',
    description: 'Great Britain Pound vs Swedish Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SEK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  GBPSGD: {
    symbol: 'GBPSGD',
    path: 'FOREX\\Minor\\FOREX\\GBPSGD',
    full_name: 'GBPSGD',
    description: 'Great Britain Pound vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  GBPTRY: {
    symbol: 'GBPTRY',
    path: 'FOREX\\Minor\\FOREX\\GBPTRY',
    full_name: 'GBPTRY',
    description: 'Great Britain Pound vs New Turkish Lira',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'TRY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
    ],
  },
  GBPUSD: {
    symbol: 'GBPUSD',
    path: 'FOREX\\Major\\FOREX\\GBPUSD',
    full_name: 'GBPUSD',
    description: 'Great Britain Pound vs US Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GBPZAR: {
    symbol: 'GBPZAR',
    path: 'FOREX\\Minor\\FOREX\\GBPZAR',
    full_name: 'GBPZAR',
    description: 'Great Britain Pound vs South African',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'ZAR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
    ],
  },
  HKDJPY: {
    symbol: 'HKDJPY',
    path: 'FOREX\\Minor\\FOREX\\HKDJPY',
    full_name: 'HKDJPY',
    description: 'Hong Kong Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  MXNJPY: {
    symbol: 'MXNJPY',
    path: 'FOREX\\Minor\\FOREX\\MXNJPY',
    full_name: 'MXNJPY',
    description: 'Mexican Peso vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/MX.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  NOKJPY: {
    symbol: 'NOKJPY',
    path: 'FOREX\\Minor\\FOREX\\NOKJPY',
    full_name: 'NOKJPY',
    description: 'Norwegian Krona vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  NOKSEK: {
    symbol: 'NOKSEK',
    path: 'FOREX\\Minor\\FOREX\\NOKSEK',
    full_name: 'NOKSEK',
    description: 'Norwegian Krona vs Swedish Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SEK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  NZDCAD: {
    symbol: 'NZDCAD',
    path: 'FOREX\\Minor\\FOREX\\NZDCAD',
    full_name: 'NZDCAD',
    description: 'New Zealand Dollar vs Canadian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CAD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  NZDCHF: {
    symbol: 'NZDCHF',
    path: 'FOREX\\Minor\\FOREX\\NZDCHF',
    full_name: 'NZDCHF',
    description: 'New Zealand Dollar vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  NZDJPY: {
    symbol: 'NZDJPY',
    path: 'FOREX\\Minor\\FOREX\\NZDJPY',
    full_name: 'NZDJPY',
    description: 'New Zealand Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  NZDSGD: {
    symbol: 'NZDSGD',
    path: 'FOREX\\Minor\\FOREX\\NZDSGD',
    full_name: 'NZDSGD',
    description: 'New Zealand Dollar vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  NZDUSD: {
    symbol: 'NZDUSD',
    path: 'FOREX\\Minor\\FOREX\\NZDUSD',
    full_name: 'NZDUSD',
    description: 'New Zealand Dollar vs US Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SEKJPY: {
    symbol: 'SEKJPY',
    path: 'FOREX\\Minor\\FOREX\\SEKJPY',
    full_name: 'SEKJPY',
    description: 'Swedish Krona vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  SGDJPY: {
    symbol: 'SGDJPY',
    path: 'FOREX\\Minor\\FOREX\\SGDJPY',
    full_name: 'SGDJPY',
    description: 'Singapore Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  TRYJPY: {
    symbol: 'TRYJPY',
    path: 'FOREX\\Minor\\FOREX\\TRYJPY',
    full_name: 'TRYJPY',
    description: 'New Turkish Lira vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  USDBRL: {
    symbol: 'USDBRL',
    path: 'FOREX\\Minor\\FOREX\\USDBRL',
    full_name: 'USDBRL',
    description: 'United states dollar vs Brazilian Real',
    type: 'forex',
    session: '1;1230-2000:2|1230-2000:3|1230-2000:4|1230-2000:5|1230-2000:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'BRL',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/BR.svg',
    ],
  },
  USDCAD: {
    symbol: 'USDCAD',
    path: 'FOREX\\Major\\FOREX\\USDCAD',
    full_name: 'USDCAD',
    description: 'US Dollar vs Canadian Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CAD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  USDCHF: {
    symbol: 'USDCHF',
    path: 'FOREX\\Major\\FOREX\\USDCHF',
    full_name: 'USDCHF',
    description: 'US Dollar vs Swiss Franc',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  USDCLP: {
    symbol: 'USDCLP',
    path: 'FOREX\\Minor\\FOREX\\USDCLP',
    full_name: 'USDCLP',
    description: 'United states dollar vs Chile Peso',
    type: 'forex',
    session: '1;1150-1510:2|1150-1510:3|1150-1510:4|1150-1510:5|1150-1510:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CLP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CL.svg',
    ],
  },
  USDCNH: {
    symbol: 'USDCNH',
    path: 'FOREX\\Minor\\FOREX\\USDCNH',
    full_name: 'USDCNH',
    description: 'US Dollar vs Chinese Yuan',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'CNH',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CN.svg',
    ],
  },
  USDDKK: {
    symbol: 'USDDKK',
    path: 'FOREX\\Minor\\FOREX\\USDDKK',
    full_name: 'USDDKK',
    description: 'United States Dollar vs Danish Krone',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'DKK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  USDHKD: {
    symbol: 'USDHKD',
    path: 'FOREX\\Minor\\FOREX\\USDHKD',
    full_name: 'USDHKD',
    description: 'US Dollar vs Hong Kong Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  USDHUF: {
    symbol: 'USDHUF',
    path: 'FOREX\\Minor\\FOREX\\USDHUF',
    full_name: 'USDHUF',
    description: 'US Dollar vs Hungarian Forint',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'HUF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/HU.svg',
    ],
  },
  USDILS: {
    symbol: 'USDILS',
    path: 'FOREX\\Minor\\FOREX\\USDILS',
    full_name: 'USDILS',
    description: 'US Dollar vs Israeli Shekel',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'ILS',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/IL.svg',
    ],
  },
  USDJPY: {
    symbol: 'USDJPY',
    path: 'FOREX\\Major\\FOREX\\USDJPY',
    full_name: 'USDJPY',
    description: 'US Dollar vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  USDMXN: {
    symbol: 'USDMXN',
    path: 'FOREX\\Minor\\FOREX\\USDMXN',
    full_name: 'USDMXN',
    description: 'US Dollar vs Mexican Peso',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'MXN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/MX.svg',
    ],
  },
  USDNOK: {
    symbol: 'USDNOK',
    path: 'FOREX\\Minor\\FOREX\\USDNOK',
    full_name: 'USDNOK',
    description: 'US Dollar vs Norwegian Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  USDPLN: {
    symbol: 'USDPLN',
    path: 'FOREX\\Minor\\FOREX\\USDPLN',
    full_name: 'USDPLN',
    description: 'US Dollar vs Polish Zloty',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'PLN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/PL.svg',
    ],
  },
  USDRON: {
    symbol: 'USDRON',
    path: 'FOREX\\Minor\\FOREX\\USDRON',
    full_name: 'USDRON',
    description: 'US Dollar vs Romanian New Leu',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'RON',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/RO.svg',
    ],
  },
  USDRUB: {
    symbol: 'USDRUB',
    path: 'FOREX\\Minor\\FOREX\\USDRUB',
    full_name: 'USDRUB',
    description: 'US Dollar vs Russian Ruble',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'RUB',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/RU.svg',
    ],
  },
  USDSEK: {
    symbol: 'USDSEK',
    path: 'FOREX\\Minor\\FOREX\\USDSEK',
    full_name: 'USDSEK',
    description: 'US Dollar vs Swedish Krona',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SEK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  USDSGD: {
    symbol: 'USDSGD',
    path: 'FOREX\\Minor\\FOREX\\USDSGD',
    full_name: 'USDSGD',
    description: 'US Dollar vs Singapore Dollar',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  USDTHB: {
    symbol: 'USDTHB',
    path: 'FOREX\\Minor\\FOREX\\USDTHB',
    full_name: 'USDTHB',
    description: 'United States Dollar vs Thai Baht',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'THB',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/TH.svg',
    ],
  },
  USDTRY: {
    symbol: 'USDTRY',
    path: 'FOREX\\Minor\\FOREX\\USDTRY',
    full_name: 'USDTRY',
    description: 'US Dollar vs New Turkish Lira',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'TRY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
    ],
  },
  USDZAR: {
    symbol: 'USDZAR',
    path: 'FOREX\\Minor\\FOREX\\USDZAR',
    full_name: 'USDZAR',
    description: 'US Dollar vs South African Rand',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'ZAR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
    ],
  },
  ZARJPY: {
    symbol: 'ZARJPY',
    path: 'FOREX\\Minor\\FOREX\\ZARJPY',
    full_name: 'ZARJPY',
    description: 'South African Rand vs Japanese Yen',
    type: 'forex',
    session:
      '1;2100-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
};

export const indices_symbols = {
  'AXCAUD.c': {
    symbol: 'AXCAUD.c',
    path: 'CFD\\CFD.c\\AXCAUD.c',
    full_name: 'AXCAUD.c',
    description: 'ASX S&P Index (SPI) CFD',
    type: 'index',
    session:
      '1;2150-0000:1|0000-2100,2150-0000:2|0000-2100,2150-0000:3|0000-2100,2150-0000:4|0000-2100,2150-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  'CHCUSD.c': {
    symbol: 'CHCUSD.c',
    path: 'CFD\\CFD.c\\CHCUSD.c',
    full_name: 'CHCUSD.c',
    description: 'Chinese top 50 companies index',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.1,
      max: 1000000,
      step: 0.1,
      default: 0.1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'DJCUSD.c': {
    symbol: 'DJCUSD.c',
    path: 'CFD\\CFD.c\\DJCUSD.c',
    full_name: 'DJCUSD.c',
    description: 'Wall Street Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dow-30.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ES35: {
    symbol: 'ES35',
    path: 'CFD\\CFD.c\\ES35',
    full_name: 'ES35',
    description: 'Spain 35 Cash Index',
    type: 'index',
    session: '1;0600-1800:2|0600-1800:3|0600-1800:4|0600-1800:5|0600-1800:6',
    decimals: 2,
    qty: {
      min: 0.1,
      max: 1000000,
      step: 0.1,
      default: 0.1,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EXCEUR.c': {
    symbol: 'EXCEUR.c',
    path: 'CFD\\CFD.c\\EXCEUR.c',
    full_name: 'EXCEUR.c',
    description: 'Blue-chip stocks leaders in Eurozone (Euro Stoxx 50 Index)',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FRCEUR.c': {
    symbol: 'FRCEUR.c',
    path: 'CFD\\CFD.c\\FRCEUR.c',
    full_name: 'FRCEUR.c',
    description: 'France 40 Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/cac-40.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GECEUR.c': {
    symbol: 'GECEUR.c',
    path: 'CFD\\CFD.c\\GECEUR.c',
    full_name: 'GECEUR.c',
    description: 'DAX 30 Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dax.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HKCHKD.c': {
    symbol: 'HKCHKD.c',
    path: 'CFD\\CFD.c\\HKCHKD.c',
    full_name: 'HKCHKD.c',
    description: 'Hang Seng Index',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.5,
      max: 1000000,
      step: 0.5,
      default: 0.5,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  'JPCJPY.c': {
    symbol: 'JPCJPY.c',
    path: 'CFD\\CFD.c\\JPCJPY.c',
    full_name: 'JPCJPY.c',
    description: 'Nikkei Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/nikkei-225.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  N25: {
    symbol: 'N25',
    path: 'CFD\\CFD.c\\N25',
    full_name: 'N25',
    description: 'Netherlands 25 Cash Index',
    type: 'index',
    session: '1;0600-2000:2|0600-2000:3|0600-2000:4|0600-2000:5|0600-2000:6',
    decimals: 2,
    qty: {
      min: 0.1,
      max: 1000000,
      step: 0.1,
      default: 0.1,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NL.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NACUSD.c': {
    symbol: 'NACUSD.c',
    path: 'CFD\\CFD.c\\NACUSD.c',
    full_name: 'NACUSD.c',
    description: 'NASDAQ 100 E-mini derived CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'RUSS2000.c': {
    symbol: 'RUSS2000.c',
    path: 'CFD\\CFD.c\\RUSS2000.c',
    full_name: 'RUSS2000.c',
    description: 'Russel 2000 Cash Index',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.1,
      max: 1000000,
      step: 0.1,
      default: 0.1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/russell-2000.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'SPCUSD.c': {
    symbol: 'SPCUSD.c',
    path: 'CFD\\CFD.c\\SPCUSD.c',
    full_name: 'SPCUSD.c',
    description: 'US S&P 500 Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/s-and-p-500.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SWI20: {
    symbol: 'SWI20',
    path: 'CFD\\CFD.c\\SWI20',
    full_name: 'SWI20',
    description: 'Switzerland 20 Cash index',
    type: 'index',
    session: '1;0600-2000:2|0600-2000:3|0600-2000:4|0600-2000:5|0600-2000:6',
    decimals: 2,
    qty: {
      min: 0.1,
      max: 1000000,
      step: 0.1,
      default: 0.1,
    },
    contract_size: 1,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/swiss-20-index.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'UKCGBP.c': {
    symbol: 'UKCGBP.c',
    path: 'CFD\\CFD.c\\UKCGBP.c',
    full_name: 'UKCGBP.c',
    description: 'UK 100 Index CFD',
    type: 'index',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/uk-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
};

export const commodity_symbols = {
  GAUUSD: {
    symbol: 'GAUUSD',
    path: 'METALS\\METALS\\GAUUSD',
    full_name: 'GAUUSD',
    description: 'Gold in Grams / US Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'UKOIL.c': {
    symbol: 'UKOIL.c',
    path: 'CFD\\CFD.c\\UKOIL.c',
    full_name: 'UKOIL.c',
    description: 'Crude Oil Brent Cash',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100:2|0000-2100:3|0000-2100:4|0000-2100:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'USOIL.c': {
    symbol: 'USOIL.c',
    path: 'CFD\\CFD.c\\USOIL.c',
    full_name: 'USOIL.c',
    description: 'West Texas Intermediate Crude Oil Cash',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XAGAUD: {
    symbol: 'XAGAUD',
    path: 'METALS\\METALS\\XAGAUD',
    full_name: 'XAGAUD',
    description: 'Silver vs Australian Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 5000,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  XAGEUR: {
    symbol: 'XAGEUR',
    path: 'METALS\\METALS\\XAGEUR',
    full_name: 'XAGEUR',
    description: 'Silver vs Euro',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 5000,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  XAGUSD: {
    symbol: 'XAGUSD',
    path: 'METALS\\METALS\\XAGUSD',
    full_name: 'XAGUSD',
    description: 'Sliver vs US Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 5000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XALUSD: {
    symbol: 'XALUSD',
    path: 'METALS\\METALS\\XALUSD',
    full_name: 'XALUSD',
    description: 'Aluminium vs US Dollar',
    type: 'commodity',
    session: '1;0000-1800:2|0000-1800:3|0000-1800:4|0000-1800:5|0000-1800:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/aluminum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XAUAUD: {
    symbol: 'XAUAUD',
    path: 'METALS\\METALS\\XAUAUD',
    full_name: 'XAUAUD',
    description: 'Gold vs Australian Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  XAUEUR: {
    symbol: 'XAUEUR',
    path: 'METALS\\METALS\\XAUEUR',
    full_name: 'XAUEUR',
    description: 'Gold vs Euro',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  XAUUSD: {
    symbol: 'XAUUSD',
    path: 'METALS\\METALS\\XAUUSD',
    full_name: 'XAUUSD',
    description: 'Gold vs US Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XNGUSD: {
    symbol: 'XNGUSD',
    path: 'CFD\\CFD.c\\XNGUSD',
    full_name: 'XNGUSD',
    description: 'Natural Gas Cash',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2045:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natural-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XPDUSD: {
    symbol: 'XPDUSD',
    path: 'METALS\\METALS\\XPDUSD',
    full_name: 'XPDUSD',
    description: 'Palladium vs US Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/palladium.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XPTUSD: {
    symbol: 'XPTUSD',
    path: 'METALS\\METALS\\XPTUSD',
    full_name: 'XPTUSD',
    description: 'Platinum vs US Dollar',
    type: 'commodity',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/platinum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
};

export const us_stocks = {
  A: {
    symbol: 'A',
    path: 'STOCKS\\US STOCKS\\A',
    full_name: 'A',
    description: 'Agilent Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/agilent-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AA: {
    symbol: 'AA',
    path: 'STOCKS\\US STOCKS\\AA',
    full_name: 'AA',
    description: 'Alcoa Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alcoa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AAL: {
    symbol: 'AAL',
    path: 'STOCKS\\US STOCKS\\AAL',
    full_name: 'AAL',
    description: 'American Airlines Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-airlines-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AAP: {
    symbol: 'AAP',
    path: 'STOCKS\\US STOCKS\\AAP',
    full_name: 'AAP',
    description: 'Advance Auto Parts Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/advance-auto-parts.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AAPL: {
    symbol: 'AAPL',
    path: 'STOCKS\\US STOCKS\\AAPL',
    full_name: 'AAPL',
    description: 'Apple',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/apple.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ABBV: {
    symbol: 'ABBV',
    path: 'STOCKS\\US STOCKS\\ABBV',
    full_name: 'ABBV',
    description: 'AbbVie Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/abbvie.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ABNB: {
    symbol: 'ABNB',
    path: 'STOCKS\\US STOCKS\\ABNB',
    full_name: 'ABNB',
    description: 'Airbnb Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/airbnb.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ABT: {
    symbol: 'ABT',
    path: 'STOCKS\\US STOCKS\\ABT',
    full_name: 'ABT',
    description: 'Abbott Laboratories',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/abbott.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ACAD: {
    symbol: 'ACAD',
    path: 'STOCKS\\US STOCKS\\ACAD',
    full_name: 'ACAD',
    description: 'ACADIA Pharmaceuticals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/acadia-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ACB: {
    symbol: 'ACB',
    path: 'STOCKS\\US STOCKS\\ACB',
    full_name: 'ACB',
    description: 'Aurora Cannabis Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aurora-cannabis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ACGL: {
    symbol: 'ACGL',
    path: 'STOCKS\\US STOCKS\\ACGL',
    full_name: 'ACGL',
    description: 'Arch Capital Group Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arch-capital.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ACM: {
    symbol: 'ACM',
    path: 'STOCKS\\US STOCKS\\ACM',
    full_name: 'ACM',
    description: 'Aecom',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aecom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ACN: {
    symbol: 'ACN',
    path: 'STOCKS\\US STOCKS\\ACN',
    full_name: 'ACN',
    description: 'Accenture Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/accenture.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADAP: {
    symbol: 'ADAP',
    path: 'STOCKS\\US STOCKS\\ADAP',
    full_name: 'ADAP',
    description: 'Adaptimmune Therapeutics PLC (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adaptimmune-therapeutics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADBE: {
    symbol: 'ADBE',
    path: 'STOCKS\\US STOCKS\\ADBE',
    full_name: 'ADBE',
    description: 'Adobe Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adobe.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADI: {
    symbol: 'ADI',
    path: 'STOCKS\\US STOCKS\\ADI',
    full_name: 'ADI',
    description: 'Analog Devices Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/analog-devices.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADM: {
    symbol: 'ADM',
    path: 'STOCKS\\US STOCKS\\ADM',
    full_name: 'ADM',
    description: 'Archer-Daniels-Midland Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/archer-daniels-midland.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADP: {
    symbol: 'ADP',
    path: 'STOCKS\\US STOCKS\\ADP',
    full_name: 'ADP',
    description: 'Automatic Data Processing Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/automatic-data-processing.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADSK: {
    symbol: 'ADSK',
    path: 'STOCKS\\US STOCKS\\ADSK',
    full_name: 'ADSK',
    description: 'Autodesk Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/autodesk.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ADT: {
    symbol: 'ADT',
    path: 'STOCKS\\US STOCKS\\ADT',
    full_name: 'ADT',
    description: 'ADT Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AEE: {
    symbol: 'AEE',
    path: 'STOCKS\\US STOCKS\\AEE',
    full_name: 'AEE',
    description: 'Ameren Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ameren.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AEM: {
    symbol: 'AEM',
    path: 'STOCKS\\US STOCKS\\AEM',
    full_name: 'AEM',
    description: 'Agnico Eagle Mines Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/agnico-eagle-mines.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AEO: {
    symbol: 'AEO',
    path: 'STOCKS\\US STOCKS\\AEO',
    full_name: 'AEO',
    description: 'American Eagle Outfitters Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-eagle-outfitters.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AEP: {
    symbol: 'AEP',
    path: 'STOCKS\\US STOCKS\\AEP',
    full_name: 'AEP',
    description: 'American Electri Power',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-electric-power.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AER: {
    symbol: 'AER',
    path: 'STOCKS\\US STOCKS\\AER',
    full_name: 'AER',
    description: 'AerCap Holdings NV',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aercap.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AES: {
    symbol: 'AES',
    path: 'STOCKS\\US STOCKS\\AES',
    full_name: 'AES',
    description: 'AES Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aes.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AFG: {
    symbol: 'AFG',
    path: 'STOCKS\\US STOCKS\\AFG',
    full_name: 'AFG',
    description: 'American Financial Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-financial-group-ohio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AFL: {
    symbol: 'AFL',
    path: 'STOCKS\\US STOCKS\\AFL',
    full_name: 'AFL',
    description: 'Aflac Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aflac.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AFRM: {
    symbol: 'AFRM',
    path: 'STOCKS\\US STOCKS\\AFRM',
    full_name: 'AFRM',
    description: 'Affirm Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/affirm.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AG: {
    symbol: 'AG',
    path: 'STOCKS\\US STOCKS\\AG',
    full_name: 'AG',
    description: 'First Majestic Silver Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/first-majestic-silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AGCO: {
    symbol: 'AGCO',
    path: 'STOCKS\\US STOCKS\\AGCO',
    full_name: 'AGCO',
    description: 'AGCO Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/agco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AGNC: {
    symbol: 'AGNC',
    path: 'STOCKS\\US STOCKS\\AGNC',
    full_name: 'AGNC',
    description: 'Agnc Investment Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/agnc-investment.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AGO: {
    symbol: 'AGO',
    path: 'STOCKS\\US STOCKS\\AGO',
    full_name: 'AGO',
    description: 'Assured Guaranty Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/assured-guaranty.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AIG: {
    symbol: 'AIG',
    path: 'STOCKS\\US STOCKS\\AIG',
    full_name: 'AIG',
    description: 'American International Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-international-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AIZ: {
    symbol: 'AIZ',
    path: 'STOCKS\\US STOCKS\\AIZ',
    full_name: 'AIZ',
    description: 'Assurant Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/assurant.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AJG: {
    symbol: 'AJG',
    path: 'STOCKS\\US STOCKS\\AJG',
    full_name: 'AJG',
    description: 'Arthur J Gallagher & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gallagher.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AKAM: {
    symbol: 'AKAM',
    path: 'STOCKS\\US STOCKS\\AKAM',
    full_name: 'AKAM',
    description: 'Akamai Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/akamai.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALB: {
    symbol: 'ALB',
    path: 'STOCKS\\US STOCKS\\ALB',
    full_name: 'ALB',
    description: 'Albemarle Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/albemarle.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALGN: {
    symbol: 'ALGN',
    path: 'STOCKS\\US STOCKS\\ALGN',
    full_name: 'ALGN',
    description: 'Align Technology Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/align-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALK: {
    symbol: 'ALK',
    path: 'STOCKS\\US STOCKS\\ALK',
    full_name: 'ALK',
    description: 'Alaska Air Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alaska-air.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALKS: {
    symbol: 'ALKS',
    path: 'STOCKS\\US STOCKS\\ALKS',
    full_name: 'ALKS',
    description: 'Alkermes PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alkermes.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALL: {
    symbol: 'ALL',
    path: 'STOCKS\\US STOCKS\\ALL',
    full_name: 'ALL',
    description: 'Allstate Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allstate.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALLE: {
    symbol: 'ALLE',
    path: 'STOCKS\\US STOCKS\\ALLE',
    full_name: 'ALLE',
    description: 'Allegion PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allegion-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALLY: {
    symbol: 'ALLY',
    path: 'STOCKS\\US STOCKS\\ALLY',
    full_name: 'ALLY',
    description: 'Ally Financial Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ally.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALNY: {
    symbol: 'ALNY',
    path: 'STOCKS\\US STOCKS\\ALNY',
    full_name: 'ALNY',
    description: 'Alnylam Pharmaceuticals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alnylam-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALRM: {
    symbol: 'ALRM',
    path: 'STOCKS\\US STOCKS\\ALRM',
    full_name: 'ALRM',
    description: 'Alarm.com Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alarm-com-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ALSN: {
    symbol: 'ALSN',
    path: 'STOCKS\\US STOCKS\\ALSN',
    full_name: 'ALSN',
    description: 'Allison Transmission Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allison-transmission.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMAT: {
    symbol: 'AMAT',
    path: 'STOCKS\\US STOCKS\\AMAT',
    full_name: 'AMAT',
    description: 'Applied Materials Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/applied-materials.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMBA: {
    symbol: 'AMBA',
    path: 'STOCKS\\US STOCKS\\AMBA',
    full_name: 'AMBA',
    description: 'Ambarella Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ambarella.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMCR: {
    symbol: 'AMCR',
    path: 'STOCKS\\US STOCKS\\AMCR',
    full_name: 'AMCR',
    description: 'Amcor PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amcor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMCX: {
    symbol: 'AMCX',
    path: 'STOCKS\\US STOCKS\\AMCX',
    full_name: 'AMCX',
    description: 'AMC Networks Inc Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amc-networks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMD: {
    symbol: 'AMD',
    path: 'STOCKS\\US STOCKS\\AMD',
    full_name: 'AMD',
    description: 'Advanced Micro Devices / AMD',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/advanced-micro-devices.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AME: {
    symbol: 'AME',
    path: 'STOCKS\\US STOCKS\\AME',
    full_name: 'AME',
    description: 'AMETEK Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ametek.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMG: {
    symbol: 'AMG',
    path: 'STOCKS\\US STOCKS\\AMG',
    full_name: 'AMG',
    description: 'Affiliated Managers Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/affiliated-managers.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMGN: {
    symbol: 'AMGN',
    path: 'STOCKS\\US STOCKS\\AMGN',
    full_name: 'AMGN',
    description: 'Amgen Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amgen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMH: {
    symbol: 'AMH',
    path: 'STOCKS\\US STOCKS\\AMH',
    full_name: 'AMH',
    description: 'American Homes 4 Rent',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-homes-4-rent.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMP: {
    symbol: 'AMP',
    path: 'STOCKS\\US STOCKS\\AMP',
    full_name: 'AMP',
    description: 'ameriprise Financial Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ameriprise-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMRN: {
    symbol: 'AMRN',
    path: 'STOCKS\\US STOCKS\\AMRN',
    full_name: 'AMRN',
    description: 'Amarin Corp PLC (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amarin.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMT: {
    symbol: 'AMT',
    path: 'STOCKS\\US STOCKS\\AMT',
    full_name: 'AMT',
    description: 'American Tower Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-tower.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMWL: {
    symbol: 'AMWL',
    path: 'STOCKS\\US STOCKS\\AMWL',
    full_name: 'AMWL',
    description: 'American Well Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amwell.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMX: {
    symbol: 'AMX',
    path: 'STOCKS\\US STOCKS\\AMX',
    full_name: 'AMX',
    description: 'America Movil - Class L (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/america-movil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AMZN: {
    symbol: 'AMZN',
    path: 'STOCKS\\US STOCKS\\AMZN',
    full_name: 'AMZN',
    description: 'Amazon Com',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amazon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ANET: {
    symbol: 'ANET',
    path: 'STOCKS\\US STOCKS\\ANET',
    full_name: 'ANET',
    description: 'Arista Networks Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arista-networks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ANF: {
    symbol: 'ANF',
    path: 'STOCKS\\US STOCKS\\ANF',
    full_name: 'ANF',
    description: 'Abercrombie & Fitch Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/abercrombie-and-fitch.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ANSS: {
    symbol: 'ANSS',
    path: 'STOCKS\\US STOCKS\\ANSS',
    full_name: 'ANSS',
    description: 'Ansys Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ansys.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ANTM: {
    symbol: 'ANTM',
    path: 'STOCKS\\US STOCKS\\ANTM',
    full_name: 'ANTM',
    description: 'Anthem Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/antam.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AON: {
    symbol: 'AON',
    path: 'STOCKS\\US STOCKS\\AON',
    full_name: 'AON',
    description: 'Aon Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AOS: {
    symbol: 'AOS',
    path: 'STOCKS\\US STOCKS\\AOS',
    full_name: 'AOS',
    description: 'A.O. Smith Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smith-a-o.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APA: {
    symbol: 'APA',
    path: 'STOCKS\\US STOCKS\\APA',
    full_name: 'APA',
    description: 'Apache Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/apa-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APAM: {
    symbol: 'APAM',
    path: 'STOCKS\\US STOCKS\\APAM',
    full_name: 'APAM',
    description: 'Artisan Partners Asset Management Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/artisan-partners-asset-mgmt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APD: {
    symbol: 'APD',
    path: 'STOCKS\\US STOCKS\\APD',
    full_name: 'APD',
    description: 'Air Products & Chemicals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/air-products-and-chemicals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APH: {
    symbol: 'APH',
    path: 'STOCKS\\US STOCKS\\APH',
    full_name: 'APH',
    description: 'Amphenol Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amphenol.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APPS: {
    symbol: 'APPS',
    path: 'STOCKS\\US STOCKS\\APPS',
    full_name: 'APPS',
    description: 'Digital Turbine Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/digital-turbine.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  APTV: {
    symbol: 'APTV',
    path: 'STOCKS\\US STOCKS\\APTV',
    full_name: 'APTV',
    description: 'Aptiv PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aptiv.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AR: {
    symbol: 'AR',
    path: 'STOCKS\\US STOCKS\\AR',
    full_name: 'AR',
    description: 'Antero Resources Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/antero-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ARM: {
    symbol: 'ARM',
    path: 'STOCKS\\US STOCKS\\ARM',
    full_name: 'ARM',
    description: 'Arm Holdings American Depositary Shares',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arm.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ARW: {
    symbol: 'ARW',
    path: 'STOCKS\\US STOCKS\\ARW',
    full_name: 'ARW',
    description: 'Arrow Electronics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arrow-electronics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ASAN: {
    symbol: 'ASAN',
    path: 'STOCKS\\US STOCKS\\ASAN',
    full_name: 'ASAN',
    description: 'Asana Inc - Cl A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/asana.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ASML: {
    symbol: 'ASML',
    path: 'STOCKS\\US STOCKS\\ASML',
    full_name: 'ASML',
    description: 'ASML Holding NV (Nasdaq)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/asml.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ASX: {
    symbol: 'ASX',
    path: 'STOCKS\\US STOCKS\\ASX',
    full_name: 'ASX',
    description: 'Ase Technology Holding (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/asx.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATHM: {
    symbol: 'ATHM',
    path: 'STOCKS\\US STOCKS\\ATHM',
    full_name: 'ATHM',
    description: 'Autohome Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/autohome.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATI: {
    symbol: 'ATI',
    path: 'STOCKS\\US STOCKS\\ATI',
    full_name: 'ATI',
    description: 'Allegheny Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allegheny-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATO: {
    symbol: 'ATO',
    path: 'STOCKS\\US STOCKS\\ATO',
    full_name: 'ATO',
    description: 'Atmos Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/atos.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATR: {
    symbol: 'ATR',
    path: 'STOCKS\\US STOCKS\\ATR',
    full_name: 'ATR',
    description: 'AptarGroup Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aptargroup.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATRA: {
    symbol: 'ATRA',
    path: 'STOCKS\\US STOCKS\\ATRA',
    full_name: 'ATRA',
    description: 'Atara Biotherapeutics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/atara-biotherapeutics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ATUS: {
    symbol: 'ATUS',
    path: 'STOCKS\\US STOCKS\\ATUS',
    full_name: 'ATUS',
    description: 'Altice USA Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/altice-usa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AUPH: {
    symbol: 'AUPH',
    path: 'STOCKS\\US STOCKS\\AUPH',
    full_name: 'AUPH',
    description: 'Aurinia Pharmaceuticals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aurinia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AUR: {
    symbol: 'AUR',
    path: 'STOCKS\\US STOCKS\\AUR',
    full_name: 'AUR',
    description: 'Aurora Innovation Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aurora-innovation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AVB: {
    symbol: 'AVB',
    path: 'STOCKS\\US STOCKS\\AVB',
    full_name: 'AVB',
    description: 'AvalonBay Communities Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/avalonbay-communities.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AVGO: {
    symbol: 'AVGO',
    path: 'STOCKS\\US STOCKS\\AVGO',
    full_name: 'AVGO',
    description: 'Broadcom Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/broadcom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AVT: {
    symbol: 'AVT',
    path: 'STOCKS\\US STOCKS\\AVT',
    full_name: 'AVT',
    description: 'Avnet Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/avnet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AVTR: {
    symbol: 'AVTR',
    path: 'STOCKS\\US STOCKS\\AVTR',
    full_name: 'AVTR',
    description: 'Avantor Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/avantor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AWK: {
    symbol: 'AWK',
    path: 'STOCKS\\US STOCKS\\AWK',
    full_name: 'AWK',
    description: 'American Water Works Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-water-works.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AXP: {
    symbol: 'AXP',
    path: 'STOCKS\\US STOCKS\\AXP',
    full_name: 'AXP',
    description: 'American Express',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-express.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AXTI: {
    symbol: 'AXTI',
    path: 'STOCKS\\US STOCKS\\AXTI',
    full_name: 'AXTI',
    description: 'AXT Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/axt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AYI: {
    symbol: 'AYI',
    path: 'STOCKS\\US STOCKS\\AYI',
    full_name: 'AYI',
    description: 'Acuity Brands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/acuity-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AZN: {
    symbol: 'AZN',
    path: 'STOCKS\\US STOCKS\\AZN',
    full_name: 'AZN',
    description: 'AstraZeneca PLC (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/astrazeneca.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AZO: {
    symbol: 'AZO',
    path: 'STOCKS\\US STOCKS\\AZO',
    full_name: 'AZO',
    description: 'Autozone Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/autozone.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  AZPN: {
    symbol: 'AZPN',
    path: 'STOCKS\\US STOCKS\\AZPN',
    full_name: 'AZPN',
    description: 'Aspen Technology Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aspen-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BA: {
    symbol: 'BA',
    path: 'STOCKS\\US STOCKS\\BA',
    full_name: 'BA',
    description: 'Boeing',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/boeing.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BABA: {
    symbol: 'BABA',
    path: 'STOCKS\\US STOCKS\\BABA',
    full_name: 'BABA',
    description: 'Alibaba',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alibaba.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BAC: {
    symbol: 'BAC',
    path: 'STOCKS\\US STOCKS\\BAC',
    full_name: 'BAC',
    description: 'Bank Of America',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-america.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BAH: {
    symbol: 'BAH',
    path: 'STOCKS\\US STOCKS\\BAH',
    full_name: 'BAH',
    description: 'Booz Allen Hamilton Holding Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/booz-allen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BAM: {
    symbol: 'BAM',
    path: 'STOCKS\\US STOCKS\\BAM',
    full_name: 'BAM',
    description: 'Brookfield Asset Management Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bangkok-commercial-asset-management.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BAP: {
    symbol: 'BAP',
    path: 'STOCKS\\US STOCKS\\BAP',
    full_name: 'BAP',
    description: 'Credicorp Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/credicorp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BAX: {
    symbol: 'BAX',
    path: 'STOCKS\\US STOCKS\\BAX',
    full_name: 'BAX',
    description: 'Baxter International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/baxter.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BB: {
    symbol: 'BB',
    path: 'STOCKS\\US STOCKS\\BB',
    full_name: 'BB',
    description: 'Blackberry Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackberry.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BBD: {
    symbol: 'BBD',
    path: 'STOCKS\\US STOCKS\\BBD',
    full_name: 'BBD',
    description: 'Banco Bradesco (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bradesco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BBWI: {
    symbol: 'BBWI',
    path: 'STOCKS\\US STOCKS\\BBWI',
    full_name: 'BBWI',
    description: 'Bath & Body Works Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bath-and-body-works.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BBY: {
    symbol: 'BBY',
    path: 'STOCKS\\US STOCKS\\BBY',
    full_name: 'BBY',
    description: 'Best Buy Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/best-buy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BC: {
    symbol: 'BC',
    path: 'STOCKS\\US STOCKS\\BC',
    full_name: 'BC',
    description: 'Brunswick Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brunswick.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BCE: {
    symbol: 'BCE',
    path: 'STOCKS\\US STOCKS\\BCE',
    full_name: 'BCE',
    description: 'BCE Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bce.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BDX: {
    symbol: 'BDX',
    path: 'STOCKS\\US STOCKS\\BDX',
    full_name: 'BDX',
    description: 'Becton Dickinson & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/becton-dickinson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BE: {
    symbol: 'BE',
    path: 'STOCKS\\US STOCKS\\BE',
    full_name: 'BE',
    description: 'Bloom Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bloom-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BEN: {
    symbol: 'BEN',
    path: 'STOCKS\\US STOCKS\\BEN',
    full_name: 'BEN',
    description: 'Franklin Resouces Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/franklin-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BFB: {
    symbol: 'BFB',
    path: 'STOCKS\\US STOCKS\\BFB',
    full_name: 'BFB',
    description: 'Brown-Forman Corp - Class B',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brown-forman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BG: {
    symbol: 'BG',
    path: 'STOCKS\\US STOCKS\\BG',
    full_name: 'BG',
    description: 'Bunge Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bunge.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BGNE: {
    symbol: 'BGNE',
    path: 'STOCKS\\US STOCKS\\BGNE',
    full_name: 'BGNE',
    description: 'Beigene Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/beigene-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BHC: {
    symbol: 'BHC',
    path: 'STOCKS\\US STOCKS\\BHC',
    full_name: 'BHC',
    description: 'Bausch Health Cos Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bausch-health-companies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BIDU: {
    symbol: 'BIDU',
    path: 'STOCKS\\US STOCKS\\BIDU',
    full_name: 'BIDU',
    description: 'Baidu Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/baidu.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BIIB: {
    symbol: 'BIIB',
    path: 'STOCKS\\US STOCKS\\BIIB',
    full_name: 'BIIB',
    description: 'Biogen Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/biogen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BILI: {
    symbol: 'BILI',
    path: 'STOCKS\\US STOCKS\\BILI',
    full_name: 'BILI',
    description: 'Bilibili Inc-Sponsored (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bilibili.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BIO: {
    symbol: 'BIO',
    path: 'STOCKS\\US STOCKS\\BIO',
    full_name: 'BIO',
    description: 'Bio-Rad Laboratories - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bio-rad-laboratories.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BIRK: {
    symbol: 'BIRK',
    path: 'STOCKS\\US STOCKS\\BIRK',
    full_name: 'BIRK',
    description: 'Birkenstock Holding PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/birkenstock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BK: {
    symbol: 'BK',
    path: 'STOCKS\\US STOCKS\\BK',
    full_name: 'BK',
    description: 'Bank of New York Mellon Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-new-york-mellon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BKD: {
    symbol: 'BKD',
    path: 'STOCKS\\US STOCKS\\BKD',
    full_name: 'BKD',
    description: 'Brookdale Senior Living Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brookdale-senior-living.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BKKT: {
    symbol: 'BKKT',
    path: 'STOCKS\\US STOCKS\\BKKT',
    full_name: 'BKKT',
    description: 'Bakkt Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bakkt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BKNG: {
    symbol: 'BKNG',
    path: 'STOCKS\\US STOCKS\\BKNG',
    full_name: 'BKNG',
    description: 'Booking Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/booking.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BKR: {
    symbol: 'BKR',
    path: 'STOCKS\\US STOCKS\\BKR',
    full_name: 'BKR',
    description: 'Baker Hughes Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/baker-hughes.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BKU: {
    symbol: 'BKU',
    path: 'STOCKS\\US STOCKS\\BKU',
    full_name: 'BKU',
    description: 'BankUnited Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bankunited.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BLDP: {
    symbol: 'BLDP',
    path: 'STOCKS\\US STOCKS\\BLDP',
    full_name: 'BLDP',
    description: 'Ballard Power Systems Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ballard-power-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BLK: {
    symbol: 'BLK',
    path: 'STOCKS\\US STOCKS\\BLK',
    full_name: 'BLK',
    description: 'Blackrock Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackrock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BLMN: {
    symbol: 'BLMN',
    path: 'STOCKS\\US STOCKS\\BLMN',
    full_name: 'BLMN',
    description: "Bloomin' Brands Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bloomin-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BLNK: {
    symbol: 'BLNK',
    path: 'STOCKS\\US STOCKS\\BLNK',
    full_name: 'BLNK',
    description: 'Blink Charging Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blink-charging.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BLUE: {
    symbol: 'BLUE',
    path: 'STOCKS\\US STOCKS\\BLUE',
    full_name: 'BLUE',
    description: 'bluebird bio Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bluebird-bio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BMBL: {
    symbol: 'BMBL',
    path: 'STOCKS\\US STOCKS\\BMBL',
    full_name: 'BMBL',
    description: 'Bumble Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bumble.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BMO: {
    symbol: 'BMO',
    path: 'STOCKS\\US STOCKS\\BMO',
    full_name: 'BMO',
    description: 'Bank of Montreal',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-montreal.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BMRN: {
    symbol: 'BMRN',
    path: 'STOCKS\\US STOCKS\\BMRN',
    full_name: 'BMRN',
    description: 'BioMarin Pharmaceutical Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/biomarin-pharmaceutical.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BMY: {
    symbol: 'BMY',
    path: 'STOCKS\\US STOCKS\\BMY',
    full_name: 'BMY',
    description: 'Bristol-Myers Squibb Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bristol-myers-squibb.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BNGO: {
    symbol: 'BNGO',
    path: 'STOCKS\\US STOCKS\\BNGO',
    full_name: 'BNGO',
    description: 'Bionano Genomics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bionano-genomics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BNS: {
    symbol: 'BNS',
    path: 'STOCKS\\US STOCKS\\BNS',
    full_name: 'BNS',
    description: 'Bank of Nova Scotia',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-nova-scotia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BNTX: {
    symbol: 'BNTX',
    path: 'STOCKS\\US STOCKS\\BNTX',
    full_name: 'BNTX',
    description: 'BioNTech SE',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/biontech.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BOX: {
    symbol: 'BOX',
    path: 'STOCKS\\US STOCKS\\BOX',
    full_name: 'BOX',
    description: 'Box Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/box.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BP: {
    symbol: 'BP',
    path: 'STOCKS\\US STOCKS\\BP',
    full_name: 'BP',
    description: 'BP PLC ADR ',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BR: {
    symbol: 'BR',
    path: 'STOCKS\\US STOCKS\\BR',
    full_name: 'BR',
    description: 'Broadridge Financial Solutions',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BRFS: {
    symbol: 'BRFS',
    path: 'STOCKS\\US STOCKS\\BRFS',
    full_name: 'BRFS',
    description: 'BRF SA (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brf-sa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BRKB: {
    symbol: 'BRKB',
    path: 'STOCKS\\US STOCKS\\BRKB',
    full_name: 'BRKB',
    description: 'Berkshire Hathaway Inc - Class B',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/berkshire-hathaway.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BRO: {
    symbol: 'BRO',
    path: 'STOCKS\\US STOCKS\\BRO',
    full_name: 'BRO',
    description: 'Brown & Brown Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brown-and-brown.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BSAC: {
    symbol: 'BSAC',
    path: 'STOCKS\\US STOCKS\\BSAC',
    full_name: 'BSAC',
    description: 'Banco Santander-Chile-Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/santander.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BSX: {
    symbol: 'BSX',
    path: 'STOCKS\\US STOCKS\\BSX',
    full_name: 'BSX',
    description: 'Boston Scientific Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/boston-scientific.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BUD: {
    symbol: 'BUD',
    path: 'STOCKS\\US STOCKS\\BUD',
    full_name: 'BUD',
    description: 'Anheuser-Busch InBev (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/anheuser-busch-inbev.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BUSE: {
    symbol: 'BUSE',
    path: 'STOCKS\\US STOCKS\\BUSE',
    full_name: 'BUSE',
    description: 'First Busey Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/first-busey.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BWA: {
    symbol: 'BWA',
    path: 'STOCKS\\US STOCKS\\BWA',
    full_name: 'BWA',
    description: 'BorgWarner Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/borg-warner.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BX: {
    symbol: 'BX',
    path: 'STOCKS\\US STOCKS\\BX',
    full_name: 'BX',
    description: 'Blackstone Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackstone.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BXMT: {
    symbol: 'BXMT',
    path: 'STOCKS\\US STOCKS\\BXMT',
    full_name: 'BXMT',
    description: 'Blackstone Mortgage Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackstone.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BXP: {
    symbol: 'BXP',
    path: 'STOCKS\\US STOCKS\\BXP',
    full_name: 'BXP',
    description: 'Boston Properties Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/boston-prop-drn.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BYND: {
    symbol: 'BYND',
    path: 'STOCKS\\US STOCKS\\BYND',
    full_name: 'BYND',
    description: 'Beyond Meat Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/beyond-meat.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BYON: {
    symbol: 'BYON',
    path: 'STOCKS\\US STOCKS\\BYON',
    full_name: 'BYON',
    description: 'Beyond Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/overstock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  BZUN: {
    symbol: 'BZUN',
    path: 'STOCKS\\US STOCKS\\BZUN',
    full_name: 'BZUN',
    description: 'Baozun Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/baozun.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  C: {
    symbol: 'C',
    path: 'STOCKS\\US STOCKS\\C',
    full_name: 'C',
    description: 'Citigroup',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/citigroup.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CAG: {
    symbol: 'CAG',
    path: 'STOCKS\\US STOCKS\\CAG',
    full_name: 'CAG',
    description: 'Conagra Brands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/conagra-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CAH: {
    symbol: 'CAH',
    path: 'STOCKS\\US STOCKS\\CAH',
    full_name: 'CAH',
    description: 'Cardinal Health Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cardinal-health.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CAKE: {
    symbol: 'CAKE',
    path: 'STOCKS\\US STOCKS\\CAKE',
    full_name: 'CAKE',
    description: 'Cheesecake Factory Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cheesecake-factory.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CAR: {
    symbol: 'CAR',
    path: 'STOCKS\\US STOCKS\\CAR',
    full_name: 'CAR',
    description: 'Avis Budget Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/avis-budget-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CARA: {
    symbol: 'CARA',
    path: 'STOCKS\\US STOCKS\\CARA',
    full_name: 'CARA',
    description: 'Cara Therapeutics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cara-therapeutics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CAT: {
    symbol: 'CAT',
    path: 'STOCKS\\US STOCKS\\CAT',
    full_name: 'CAT',
    description: 'Caterpillar Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/caterpillar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CB: {
    symbol: 'CB',
    path: 'STOCKS\\US STOCKS\\CB',
    full_name: 'CB',
    description: 'Chubb Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chubb.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CBOE: {
    symbol: 'CBOE',
    path: 'STOCKS\\US STOCKS\\CBOE',
    full_name: 'CBOE',
    description: 'Cboe Global Markets Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cboe-global-markets.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CCI: {
    symbol: 'CCI',
    path: 'STOCKS\\US STOCKS\\CCI',
    full_name: 'CCI',
    description: 'Crown Castle Intl Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crown-castle-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CCJ: {
    symbol: 'CCJ',
    path: 'STOCKS\\US STOCKS\\CCJ',
    full_name: 'CCJ',
    description: 'Cameco Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cameco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CCK: {
    symbol: 'CCK',
    path: 'STOCKS\\US STOCKS\\CCK',
    full_name: 'CCK',
    description: 'Crown Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crown-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CCL: {
    symbol: 'CCL',
    path: 'STOCKS\\US STOCKS\\CCL',
    full_name: 'CCL',
    description: 'Carnival Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carnival.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CDE: {
    symbol: 'CDE',
    path: 'STOCKS\\US STOCKS\\CDE',
    full_name: 'CDE',
    description: 'Coeur Mining Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coeur-mining.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CDNS: {
    symbol: 'CDNS',
    path: 'STOCKS\\US STOCKS\\CDNS',
    full_name: 'CDNS',
    description: 'Cadence Design Systems Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cadence-design-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CDW: {
    symbol: 'CDW',
    path: 'STOCKS\\US STOCKS\\CDW',
    full_name: 'CDW',
    description: 'CDW Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cdw.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CE: {
    symbol: 'CE',
    path: 'STOCKS\\US STOCKS\\CE',
    full_name: 'CE',
    description: 'Celanese Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/celanese.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CFG: {
    symbol: 'CFG',
    path: 'STOCKS\\US STOCKS\\CFG',
    full_name: 'CFG',
    description: 'Citizens Financial Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/citizens-financial-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CGC: {
    symbol: 'CGC',
    path: 'STOCKS\\US STOCKS\\CGC',
    full_name: 'CGC',
    description: 'Canopy Growth Corporation',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/canopy-growth.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CGNX: {
    symbol: 'CGNX',
    path: 'STOCKS\\US STOCKS\\CGNX',
    full_name: 'CGNX',
    description: 'Cognex Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cognex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHKP: {
    symbol: 'CHKP',
    path: 'STOCKS\\US STOCKS\\CHKP',
    full_name: 'CHKP',
    description: 'Check Point Software Technologies Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/check-point-software-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHPT: {
    symbol: 'CHPT',
    path: 'STOCKS\\US STOCKS\\CHPT',
    full_name: 'CHPT',
    description: 'Chargepoint Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chargepoint.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHRW: {
    symbol: 'CHRW',
    path: 'STOCKS\\US STOCKS\\CHRW',
    full_name: 'CHRW',
    description: 'C.H. Robinson Worldwide Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ch-robinson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHT: {
    symbol: 'CHT',
    path: 'STOCKS\\US STOCKS\\CHT',
    full_name: 'CHT',
    description: 'Chunghwa Telecom Co Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chunghwa-telecom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHTR: {
    symbol: 'CHTR',
    path: 'STOCKS\\US STOCKS\\CHTR',
    full_name: 'CHTR',
    description: 'Charter Communications Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/charter.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CHWY: {
    symbol: 'CHWY',
    path: 'STOCKS\\US STOCKS\\CHWY',
    full_name: 'CHWY',
    description: 'Chewy Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chewy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CI: {
    symbol: 'CI',
    path: 'STOCKS\\US STOCKS\\CI',
    full_name: 'CI',
    description: 'Cigna Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cigna.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CIB: {
    symbol: 'CIB',
    path: 'STOCKS\\US STOCKS\\CIB',
    full_name: 'CIB',
    description: 'Bancolombia S.A.-Spons (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bancolombia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CIEN: {
    symbol: 'CIEN',
    path: 'STOCKS\\US STOCKS\\CIEN',
    full_name: 'CIEN',
    description: 'Ciena Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ciena.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CIM: {
    symbol: 'CIM',
    path: 'STOCKS\\US STOCKS\\CIM',
    full_name: 'CIM',
    description: 'Chimera Investment Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chimera-investment-800-series-b-fixed-to-floating-rate-cumulative-redeemable-preferred-stock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CINF: {
    symbol: 'CINF',
    path: 'STOCKS\\US STOCKS\\CINF',
    full_name: 'CINF',
    description: 'Cincinnati Financial Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cincinnati-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CL: {
    symbol: 'CL',
    path: 'STOCKS\\US STOCKS\\CL',
    full_name: 'CL',
    description: 'Colgate-Palmolive Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CLB: {
    symbol: 'CLB',
    path: 'STOCKS\\US STOCKS\\CLB',
    full_name: 'CLB',
    description: 'Core Laboratories NV',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/core-laboratories.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CLF: {
    symbol: 'CLF',
    path: 'STOCKS\\US STOCKS\\CLF',
    full_name: 'CLF',
    description: 'Cleveland-Cliffs Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cleveland-cliffs.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CLH: {
    symbol: 'CLH',
    path: 'STOCKS\\US STOCKS\\CLH',
    full_name: 'CLH',
    description: 'Clean Harbors Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/clean-harbors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CLX: {
    symbol: 'CLX',
    path: 'STOCKS\\US STOCKS\\CLX',
    full_name: 'CLX',
    description: 'Clorox Company',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/clorox.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CM: {
    symbol: 'CM',
    path: 'STOCKS\\US STOCKS\\CM',
    full_name: 'CM',
    description: 'Canadian Imperial Bank of Commerce',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/canadian-imperial-bank-of-commerce.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CMA: {
    symbol: 'CMA',
    path: 'STOCKS\\US STOCKS\\CMA',
    full_name: 'CMA',
    description: 'Comerica Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/comerica.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CMCSA: {
    symbol: 'CMCSA',
    path: 'STOCKS\\US STOCKS\\CMCSA',
    full_name: 'CMCSA',
    description: 'Comcast Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/comcast.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CME: {
    symbol: 'CME',
    path: 'STOCKS\\US STOCKS\\CME',
    full_name: 'CME',
    description: 'CME Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cme.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CMG: {
    symbol: 'CMG',
    path: 'STOCKS\\US STOCKS\\CMG',
    full_name: 'CMG',
    description: 'Chipotle Mexican Grill Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chipotle-mexican-grill.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CMI: {
    symbol: 'CMI',
    path: 'STOCKS\\US STOCKS\\CMI',
    full_name: 'CMI',
    description: 'Cummins Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cummins.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CMS: {
    symbol: 'CMS',
    path: 'STOCKS\\US STOCKS\\CMS',
    full_name: 'CMS',
    description: 'CMS Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cms-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNC: {
    symbol: 'CNC',
    path: 'STOCKS\\US STOCKS\\CNC',
    full_name: 'CNC',
    description: 'Centene Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/centene.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNK: {
    symbol: 'CNK',
    path: 'STOCKS\\US STOCKS\\CNK',
    full_name: 'CNK',
    description: 'Cinemark Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cinemark.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNO: {
    symbol: 'CNO',
    path: 'STOCKS\\US STOCKS\\CNO',
    full_name: 'CNO',
    description: 'CNO Financial Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cno-financial-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNP: {
    symbol: 'CNP',
    path: 'STOCKS\\US STOCKS\\CNP',
    full_name: 'CNP',
    description: 'Centerpoint Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/centerpoint-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNQ: {
    symbol: 'CNQ',
    path: 'STOCKS\\US STOCKS\\CNQ',
    full_name: 'CNQ',
    description: 'Canadian Natural Resources Ltd / CNRL',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/canadian-natural-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CNX: {
    symbol: 'CNX',
    path: 'STOCKS\\US STOCKS\\CNX',
    full_name: 'CNX',
    description: 'CNX Resources Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cnx-resources-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COF: {
    symbol: 'COF',
    path: 'STOCKS\\US STOCKS\\COF',
    full_name: 'COF',
    description: 'Capital One Financial Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/capital-one.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COIN: {
    symbol: 'COIN',
    path: 'STOCKS\\US STOCKS\\COIN',
    full_name: 'COIN',
    description: 'Coinbase Global Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coinbase.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COLB: {
    symbol: 'COLB',
    path: 'STOCKS\\US STOCKS\\COLB',
    full_name: 'COLB',
    description: 'Columbia Banking System Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/columbia-banking-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COMM: {
    symbol: 'COMM',
    path: 'STOCKS\\US STOCKS\\COMM',
    full_name: 'COMM',
    description: 'CommScope Holding Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commscope.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COO: {
    symbol: 'COO',
    path: 'STOCKS\\US STOCKS\\COO',
    full_name: 'COO',
    description: 'Cooper Cos Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cooper-cos.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COP: {
    symbol: 'COP',
    path: 'STOCKS\\US STOCKS\\COP',
    full_name: 'COP',
    description: 'ConocoPhillips',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/conocophillips.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COR: {
    symbol: 'COR',
    path: 'STOCKS\\US STOCKS\\COR',
    full_name: 'COR',
    description: 'Cencora Inc ',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amerisourcebergen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COST: {
    symbol: 'COST',
    path: 'STOCKS\\US STOCKS\\COST',
    full_name: 'COST',
    description: 'Costco Wholesale Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/costco-wholesale.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COTY: {
    symbol: 'COTY',
    path: 'STOCKS\\US STOCKS\\COTY',
    full_name: 'COTY',
    description: 'Coty Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coty.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPA: {
    symbol: 'CPA',
    path: 'STOCKS\\US STOCKS\\CPA',
    full_name: 'CPA',
    description: 'Copa Holdings SA - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/copa-airlines.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPAY: {
    symbol: 'CPAY',
    path: 'STOCKS\\US STOCKS\\CPAY',
    full_name: 'CPAY',
    description: 'Corpay',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPB: {
    symbol: 'CPB',
    path: 'STOCKS\\US STOCKS\\CPB',
    full_name: 'CPB',
    description: 'Campbell Soup Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/campbell-soup.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPNG: {
    symbol: 'CPNG',
    path: 'STOCKS\\US STOCKS\\CPNG',
    full_name: 'CPNG',
    description: 'Coupang LLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coupang.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPRI: {
    symbol: 'CPRI',
    path: 'STOCKS\\US STOCKS\\CPRI',
    full_name: 'CPRI',
    description: 'Capri Holdings Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/capri-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPRT: {
    symbol: 'CPRT',
    path: 'STOCKS\\US STOCKS\\CPRT',
    full_name: 'CPRT',
    description: 'Copart Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/copart.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CPT: {
    symbol: 'CPT',
    path: 'STOCKS\\US STOCKS\\CPT',
    full_name: 'CPT',
    description: 'Camden Property Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/camden-property-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CR: {
    symbol: 'CR',
    path: 'STOCKS\\US STOCKS\\CR',
    full_name: 'CR',
    description: 'Crane Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crane.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRL: {
    symbol: 'CRL',
    path: 'STOCKS\\US STOCKS\\CRL',
    full_name: 'CRL',
    description: 'Charles River Laboratories Intl Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/charles-river-laboratories.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRM: {
    symbol: 'CRM',
    path: 'STOCKS\\US STOCKS\\CRM',
    full_name: 'CRM',
    description: 'Salesforce.com Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/salesforce.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRON: {
    symbol: 'CRON',
    path: 'STOCKS\\US STOCKS\\CRON',
    full_name: 'CRON',
    description: 'Cronos Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cronos-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRSP: {
    symbol: 'CRSP',
    path: 'STOCKS\\US STOCKS\\CRSP',
    full_name: 'CRSP',
    description: 'CRISPR Therapeutics AG',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crispr-therapeutics-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRTO: {
    symbol: 'CRTO',
    path: 'STOCKS\\US STOCKS\\CRTO',
    full_name: 'CRTO',
    description: 'Criteo SA (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/criteo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRUS: {
    symbol: 'CRUS',
    path: 'STOCKS\\US STOCKS\\CRUS',
    full_name: 'CRUS',
    description: 'Cirrus Logic Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cirrus-logic.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CRWD: {
    symbol: 'CRWD',
    path: 'STOCKS\\US STOCKS\\CRWD',
    full_name: 'CRWD',
    description: 'Crowdstrike Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crowdstrike.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CSCO: {
    symbol: 'CSCO',
    path: 'STOCKS\\US STOCKS\\CSCO',
    full_name: 'CSCO',
    description: 'Cisco Systems',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cisco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CSIQ: {
    symbol: 'CSIQ',
    path: 'STOCKS\\US STOCKS\\CSIQ',
    full_name: 'CSIQ',
    description: 'Canadian Solar Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/canadian-solar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CSX: {
    symbol: 'CSX',
    path: 'STOCKS\\US STOCKS\\CSX',
    full_name: 'CSX',
    description: 'CSX Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/csx.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CTAS: {
    symbol: 'CTAS',
    path: 'STOCKS\\US STOCKS\\CTAS',
    full_name: 'CTAS',
    description: 'Cintas Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cintas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CTLT: {
    symbol: 'CTLT',
    path: 'STOCKS\\US STOCKS\\CTLT',
    full_name: 'CTLT',
    description: 'Catalent Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/catalent.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CTRA: {
    symbol: 'CTRA',
    path: 'STOCKS\\US STOCKS\\CTRA',
    full_name: 'CTRA',
    description: 'Coterra Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alpha-metallurgical-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CTSH: {
    symbol: 'CTSH',
    path: 'STOCKS\\US STOCKS\\CTSH',
    full_name: 'CTSH',
    description: 'Cognizant Tech Solutions - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cognizant.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVAC: {
    symbol: 'CVAC',
    path: 'STOCKS\\US STOCKS\\CVAC',
    full_name: 'CVAC',
    description: 'CureVac NV',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/curevac.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVE: {
    symbol: 'CVE',
    path: 'STOCKS\\US STOCKS\\CVE',
    full_name: 'CVE',
    description: 'Cenovus Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cenovus.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVI: {
    symbol: 'CVI',
    path: 'STOCKS\\US STOCKS\\CVI',
    full_name: 'CVI',
    description: 'CVR Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cvr-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVLT: {
    symbol: 'CVLT',
    path: 'STOCKS\\US STOCKS\\CVLT',
    full_name: 'CVLT',
    description: 'Commvault Systems Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commvault-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVNA: {
    symbol: 'CVNA',
    path: 'STOCKS\\US STOCKS\\CVNA',
    full_name: 'CVNA',
    description: 'Carvana Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carvana.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVS: {
    symbol: 'CVS',
    path: 'STOCKS\\US STOCKS\\CVS',
    full_name: 'CVS',
    description: 'CVS Health Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cvs-health.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CVX: {
    symbol: 'CVX',
    path: 'STOCKS\\US STOCKS\\CVX',
    full_name: 'CVX',
    description: 'Chevron',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chevron.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CX: {
    symbol: 'CX',
    path: 'STOCKS\\US STOCKS\\CX',
    full_name: 'CX',
    description: 'Cemex Sab-Spons Adr Part Cer',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cemex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CYBR: {
    symbol: 'CYBR',
    path: 'STOCKS\\US STOCKS\\CYBR',
    full_name: 'CYBR',
    description: 'CyberArk Software Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cyber-ark-software.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CYH: {
    symbol: 'CYH',
    path: 'STOCKS\\US STOCKS\\CYH',
    full_name: 'CYH',
    description: 'Community Health Systems Inc / CHS',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/community-health-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  CZR: {
    symbol: 'CZR',
    path: 'STOCKS\\US STOCKS\\CZR',
    full_name: 'CZR',
    description: 'Caesars Entertainment Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/caesars.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  D: {
    symbol: 'D',
    path: 'STOCKS\\US STOCKS\\D',
    full_name: 'D',
    description: 'Dominion Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dominion-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DAL: {
    symbol: 'DAL',
    path: 'STOCKS\\US STOCKS\\DAL',
    full_name: 'DAL',
    description: 'Delta Air Lines Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/delta-air-lines.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DAN: {
    symbol: 'DAN',
    path: 'STOCKS\\US STOCKS\\DAN',
    full_name: 'DAN',
    description: 'Dana Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dana-incorporated.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DASH: {
    symbol: 'DASH',
    path: 'STOCKS\\US STOCKS\\DASH',
    full_name: 'DASH',
    description: 'DoorDash Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/doordash.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DBI: {
    symbol: 'DBI',
    path: 'STOCKS\\US STOCKS\\DBI',
    full_name: 'DBI',
    description: 'Designer Brands Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DBX: {
    symbol: 'DBX',
    path: 'STOCKS\\US STOCKS\\DBX',
    full_name: 'DBX',
    description: 'Dropbox Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dropbox.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DD: {
    symbol: 'DD',
    path: 'STOCKS\\US STOCKS\\DD',
    full_name: 'DD',
    description: 'DuPont de Nemours Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dupont-de-nemours.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DDD: {
    symbol: 'DDD',
    path: 'STOCKS\\US STOCKS\\DDD',
    full_name: 'DDD',
    description: '3D Systems Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/3-d-systems.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DDOG: {
    symbol: 'DDOG',
    path: 'STOCKS\\US STOCKS\\DDOG',
    full_name: 'DDOG',
    description: 'Datadog Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/datadog.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DDS: {
    symbol: 'DDS',
    path: 'STOCKS\\US STOCKS\\DDS',
    full_name: 'DDS',
    description: "Dillard's Inc - Class A",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dillards.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DE: {
    symbol: 'DE',
    path: 'STOCKS\\US STOCKS\\DE',
    full_name: 'DE',
    description: 'Deere & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deere.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DEI: {
    symbol: 'DEI',
    path: 'STOCKS\\US STOCKS\\DEI',
    full_name: 'DEI',
    description: 'Douglas Emmett Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/douglas-emmett.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DELL: {
    symbol: 'DELL',
    path: 'STOCKS\\US STOCKS\\DELL',
    full_name: 'DELL',
    description: 'Dell Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dell.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DFS: {
    symbol: 'DFS',
    path: 'STOCKS\\US STOCKS\\DFS',
    full_name: 'DFS',
    description: 'Discover Financial Services',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/discover-financial-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DG: {
    symbol: 'DG',
    path: 'STOCKS\\US STOCKS\\DG',
    full_name: 'DG',
    description: 'Dollar General Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dollar-general.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DGX: {
    symbol: 'DGX',
    path: 'STOCKS\\US STOCKS\\DGX',
    full_name: 'DGX',
    description: 'Quest Diagnostics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/quest-diagnostics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DHC: {
    symbol: 'DHC',
    path: 'STOCKS\\US STOCKS\\DHC',
    full_name: 'DHC',
    description: 'Diversified Healthcare Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diversified-healthcare-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DHI: {
    symbol: 'DHI',
    path: 'STOCKS\\US STOCKS\\DHI',
    full_name: 'DHI',
    description: 'Dr Horton Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dr-horton.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DHR: {
    symbol: 'DHR',
    path: 'STOCKS\\US STOCKS\\DHR',
    full_name: 'DHR',
    description: 'Danaher Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/danaher.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DIS: {
    symbol: 'DIS',
    path: 'STOCKS\\US STOCKS\\DIS',
    full_name: 'DIS',
    description: 'The Walt Disney Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/walt-disney.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DKNG: {
    symbol: 'DKNG',
    path: 'STOCKS\\US STOCKS\\DKNG',
    full_name: 'DKNG',
    description: 'DraftKings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/draftkings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DKS: {
    symbol: 'DKS',
    path: 'STOCKS\\US STOCKS\\DKS',
    full_name: 'DKS',
    description: "Dick's Sporting Goods Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dicks-sporting-goods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DLB: {
    symbol: 'DLB',
    path: 'STOCKS\\US STOCKS\\DLB',
    full_name: 'DLB',
    description: 'Dolby Laboratories Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dolby-laboratories.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DLR: {
    symbol: 'DLR',
    path: 'STOCKS\\US STOCKS\\DLR',
    full_name: 'DLR',
    description: 'Digital Realty Trust Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/digital-realty-trust-5850-series-k-cumulative-redeemable-preferred-stock-par-value-001-per-share.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DLTR: {
    symbol: 'DLTR',
    path: 'STOCKS\\US STOCKS\\DLTR',
    full_name: 'DLTR',
    description: 'Dollar Tree Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dollar-tree.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DOCU: {
    symbol: 'DOCU',
    path: 'STOCKS\\US STOCKS\\DOCU',
    full_name: 'DOCU',
    description: 'DocuSign Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/docusign.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DOV: {
    symbol: 'DOV',
    path: 'STOCKS\\US STOCKS\\DOV',
    full_name: 'DOV',
    description: 'Dover Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dover.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DOW: {
    symbol: 'DOW',
    path: 'STOCKS\\US STOCKS\\DOW',
    full_name: 'DOW',
    description: 'Dow Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dow.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DPZ: {
    symbol: 'DPZ',
    path: 'STOCKS\\US STOCKS\\DPZ',
    full_name: 'DPZ',
    description: 'Dominos Pizza',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dominos-pizza.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DRRX: {
    symbol: 'DRRX',
    path: 'STOCKS\\US STOCKS\\DRRX',
    full_name: 'DRRX',
    description: 'DURECT Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/durect.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DTE: {
    symbol: 'DTE',
    path: 'STOCKS\\US STOCKS\\DTE',
    full_name: 'DTE',
    description: 'Dte Energy Company',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-telekom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DUK: {
    symbol: 'DUK',
    path: 'STOCKS\\US STOCKS\\DUK',
    full_name: 'DUK',
    description: 'Duke Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/duke-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DVA: {
    symbol: 'DVA',
    path: 'STOCKS\\US STOCKS\\DVA',
    full_name: 'DVA',
    description: 'DaVita Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/davita.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DVN: {
    symbol: 'DVN',
    path: 'STOCKS\\US STOCKS\\DVN',
    full_name: 'DVN',
    description: 'Devon Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/devon-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DXC: {
    symbol: 'DXC',
    path: 'STOCKS\\US STOCKS\\DXC',
    full_name: 'DXC',
    description: 'DXC Technology Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dxc-technology-company.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DXCM: {
    symbol: 'DXCM',
    path: 'STOCKS\\US STOCKS\\DXCM',
    full_name: 'DXCM',
    description: 'DexCom Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dexcom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EA: {
    symbol: 'EA',
    path: 'STOCKS\\US STOCKS\\EA',
    full_name: 'EA',
    description: 'Electronic Arts Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/electronic-arts.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EAT: {
    symbol: 'EAT',
    path: 'STOCKS\\US STOCKS\\EAT',
    full_name: 'EAT',
    description: 'Brinker International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brinker-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EBAY: {
    symbol: 'EBAY',
    path: 'STOCKS\\US STOCKS\\EBAY',
    full_name: 'EBAY',
    description: 'eBay Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ebay.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EC: {
    symbol: 'EC',
    path: 'STOCKS\\US STOCKS\\EC',
    full_name: 'EC',
    description: 'Ecopetrol Sa-Sponsored (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ecopetrol.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ECL: {
    symbol: 'ECL',
    path: 'STOCKS\\US STOCKS\\ECL',
    full_name: 'ECL',
    description: 'Ecolab Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ecolab.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ED: {
    symbol: 'ED',
    path: 'STOCKS\\US STOCKS\\ED',
    full_name: 'ED',
    description: 'Consolidated Edison Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/consolidated-edison.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EDU: {
    symbol: 'EDU',
    path: 'STOCKS\\US STOCKS\\EDU',
    full_name: 'EDU',
    description: 'New Oriental Education (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/new-oriental.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EGO: {
    symbol: 'EGO',
    path: 'STOCKS\\US STOCKS\\EGO',
    full_name: 'EGO',
    description: 'Eldorado Gold Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eldorado-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EHTH: {
    symbol: 'EHTH',
    path: 'STOCKS\\US STOCKS\\EHTH',
    full_name: 'EHTH',
    description: 'eHealth Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ehealth.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EIX: {
    symbol: 'EIX',
    path: 'STOCKS\\US STOCKS\\EIX',
    full_name: 'EIX',
    description: 'Edison International',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/edison-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EL: {
    symbol: 'EL',
    path: 'STOCKS\\US STOCKS\\EL',
    full_name: 'EL',
    description: 'Estée Lauder Companies',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/estee-lauder.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EMN: {
    symbol: 'EMN',
    path: 'STOCKS\\US STOCKS\\EMN',
    full_name: 'EMN',
    description: 'Eastman Chemical Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eastman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EMR: {
    symbol: 'EMR',
    path: 'STOCKS\\US STOCKS\\EMR',
    full_name: 'EMR',
    description: 'Emerson Electric Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/emerson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ENB: {
    symbol: 'ENB',
    path: 'STOCKS\\US STOCKS\\ENB',
    full_name: 'ENB',
    description: 'Enbridge Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enbridge.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ENIC: {
    symbol: 'ENIC',
    path: 'STOCKS\\US STOCKS\\ENIC',
    full_name: 'ENIC',
    description: 'Enel Chile Sa-Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ENPH: {
    symbol: 'ENPH',
    path: 'STOCKS\\US STOCKS\\ENPH',
    full_name: 'ENPH',
    description: 'Enphase Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enphase-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ENR: {
    symbol: 'ENR',
    path: 'STOCKS\\US STOCKS\\ENR',
    full_name: 'ENR',
    description: 'Energizer Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/siemens-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ENS: {
    symbol: 'ENS',
    path: 'STOCKS\\US STOCKS\\ENS',
    full_name: 'ENS',
    description: 'EnerSys Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enersys.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EOG: {
    symbol: 'EOG',
    path: 'STOCKS\\US STOCKS\\EOG',
    full_name: 'EOG',
    description: 'EOG Resources Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eog.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EPD: {
    symbol: 'EPD',
    path: 'STOCKS\\US STOCKS\\EPD',
    full_name: 'EPD',
    description: 'Enterprise Products Partners LP',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enterprise-prods-partners.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EQH: {
    symbol: 'EQH',
    path: 'STOCKS\\US STOCKS\\EQH',
    full_name: 'EQH',
    description: 'Equitable Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/axa-equitable-hldgs.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EQIX: {
    symbol: 'EQIX',
    path: 'STOCKS\\US STOCKS\\EQIX',
    full_name: 'EQIX',
    description: 'Equinix Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/equinix.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EQR: {
    symbol: 'EQR',
    path: 'STOCKS\\US STOCKS\\EQR',
    full_name: 'EQR',
    description: 'Equity Residential',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/equity-residential.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EQT: {
    symbol: 'EQT',
    path: 'STOCKS\\US STOCKS\\EQT',
    full_name: 'EQT',
    description: 'EQT Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eqt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ERIC: {
    symbol: 'ERIC',
    path: 'STOCKS\\US STOCKS\\ERIC',
    full_name: 'ERIC',
    description: 'Ericsson LM Tel (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ericsson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ES: {
    symbol: 'ES',
    path: 'STOCKS\\US STOCKS\\ES',
    full_name: 'ES',
    description: 'Eversource Energy',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/s-and-p-500.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ESNT: {
    symbol: 'ESNT',
    path: 'STOCKS\\US STOCKS\\ESNT',
    full_name: 'ESNT',
    description: 'Essent Group Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/essent.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ESS: {
    symbol: 'ESS',
    path: 'STOCKS\\US STOCKS\\ESS',
    full_name: 'ESS',
    description: 'Essex Property Trust Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/essex-property-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ETD: {
    symbol: 'ETD',
    path: 'STOCKS\\US STOCKS\\ETD',
    full_name: 'ETD',
    description: 'Ethan Allen Interiors Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ethan-allen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ETN: {
    symbol: 'ETN',
    path: 'STOCKS\\US STOCKS\\ETN',
    full_name: 'ETN',
    description: 'Eaton Corp Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eaton.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ETR: {
    symbol: 'ETR',
    path: 'STOCKS\\US STOCKS\\ETR',
    full_name: 'ETR',
    description: 'Entergy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/entergy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ETSY: {
    symbol: 'ETSY',
    path: 'STOCKS\\US STOCKS\\ETSY',
    full_name: 'ETSY',
    description: 'Etsy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/etsy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EVTC: {
    symbol: 'EVTC',
    path: 'STOCKS\\US STOCKS\\EVTC',
    full_name: 'EVTC',
    description: 'Evertec Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/evertec-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EW: {
    symbol: 'EW',
    path: 'STOCKS\\US STOCKS\\EW',
    full_name: 'EW',
    description: 'Edwards Lifesciences Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/edwards.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXC: {
    symbol: 'EXC',
    path: 'STOCKS\\US STOCKS\\EXC',
    full_name: 'EXC',
    description: 'Exelon Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/exelon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXEL: {
    symbol: 'EXEL',
    path: 'STOCKS\\US STOCKS\\EXEL',
    full_name: 'EXEL',
    description: 'Exelixis Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/exelixis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXP: {
    symbol: 'EXP',
    path: 'STOCKS\\US STOCKS\\EXP',
    full_name: 'EXP',
    description: 'Eagle Materials Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eagle-materials.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXPD: {
    symbol: 'EXPD',
    path: 'STOCKS\\US STOCKS\\EXPD',
    full_name: 'EXPD',
    description: 'Expeditors Intl of Washington Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/expeditors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXPE: {
    symbol: 'EXPE',
    path: 'STOCKS\\US STOCKS\\EXPE',
    full_name: 'EXPE',
    description: 'Expedia Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/expedia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  EXR: {
    symbol: 'EXR',
    path: 'STOCKS\\US STOCKS\\EXR',
    full_name: 'EXR',
    description: 'Extra Space Storage Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/extra-space-storage.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  F: {
    symbol: 'F',
    path: 'STOCKS\\US STOCKS\\F',
    full_name: 'F',
    description: 'Ford Motor',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ford.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FANG: {
    symbol: 'FANG',
    path: 'STOCKS\\US STOCKS\\FANG',
    full_name: 'FANG',
    description: 'Diamondback Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diamondback.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FAST: {
    symbol: 'FAST',
    path: 'STOCKS\\US STOCKS\\FAST',
    full_name: 'FAST',
    description: 'Fastenal Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fastenal.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FCEL: {
    symbol: 'FCEL',
    path: 'STOCKS\\US STOCKS\\FCEL',
    full_name: 'FCEL',
    description: 'FuelCell Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fuelcell-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FCX: {
    symbol: 'FCX',
    path: 'STOCKS\\US STOCKS\\FCX',
    full_name: 'FCX',
    description: 'Freeport-McMoRan Inc / FCX',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/freeport-mcmoran.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FDX: {
    symbol: 'FDX',
    path: 'STOCKS\\US STOCKS\\FDX',
    full_name: 'FDX',
    description: 'Fedex',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fedex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FE: {
    symbol: 'FE',
    path: 'STOCKS\\US STOCKS\\FE',
    full_name: 'FE',
    description: 'FirstEnergy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/firstenergy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FFIV: {
    symbol: 'FFIV',
    path: 'STOCKS\\US STOCKS\\FFIV',
    full_name: 'FFIV',
    description: 'F5 Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/f5-network.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FHN: {
    symbol: 'FHN',
    path: 'STOCKS\\US STOCKS\\FHN',
    full_name: 'FHN',
    description: 'First Horizon Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/first-horizon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FI: {
    symbol: 'FI',
    path: 'STOCKS\\US STOCKS\\FI',
    full_name: 'FI',
    description: 'Fiserv In',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fiserv.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FIS: {
    symbol: 'FIS',
    path: 'STOCKS\\US STOCKS\\FIS',
    full_name: 'FIS',
    description: 'Fidelity National Info Services Inc / FIS',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fidelity-information-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FITB: {
    symbol: 'FITB',
    path: 'STOCKS\\US STOCKS\\FITB',
    full_name: 'FITB',
    description: 'Fifth Third Bancorp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fifth-third-bancorp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FIVE: {
    symbol: 'FIVE',
    path: 'STOCKS\\US STOCKS\\FIVE',
    full_name: 'FIVE',
    description: 'Five Below Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/x5-retail-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FL: {
    symbol: 'FL',
    path: 'STOCKS\\US STOCKS\\FL',
    full_name: 'FL',
    description: 'Foot Locker Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/foot-locker.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FLEX: {
    symbol: 'FLEX',
    path: 'STOCKS\\US STOCKS\\FLEX',
    full_name: 'FLEX',
    description: 'Flex Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FLO: {
    symbol: 'FLO',
    path: 'STOCKS\\US STOCKS\\FLO',
    full_name: 'FLO',
    description: 'Flowers Foods Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flowers-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FLR: {
    symbol: 'FLR',
    path: 'STOCKS\\US STOCKS\\FLR',
    full_name: 'FLR',
    description: 'Fluor Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fluor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FLS: {
    symbol: 'FLS',
    path: 'STOCKS\\US STOCKS\\FLS',
    full_name: 'FLS',
    description: 'Flowserve Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flowserve.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FLUT: {
    symbol: 'FLUT',
    path: 'STOCKS\\US STOCKS\\FLUT',
    full_name: 'FLUT',
    description: 'Flutter Entertainment',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flutter-entertainment.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FMX: {
    symbol: 'FMX',
    path: 'STOCKS\\US STOCKS\\FMX',
    full_name: 'FMX',
    description: 'Fomento Economico Mex-Sp Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/femsa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FNB: {
    symbol: 'FNB',
    path: 'STOCKS\\US STOCKS\\FNB',
    full_name: 'FNB',
    description: 'FNB Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fnb-pa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FNF: {
    symbol: 'FNF',
    path: 'STOCKS\\US STOCKS\\FNF',
    full_name: 'FNF',
    description: 'Fidelity National Financial',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fidelity.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FOSL: {
    symbol: 'FOSL',
    path: 'STOCKS\\US STOCKS\\FOSL',
    full_name: 'FOSL',
    description: 'Fossil Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fossil-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FOX: {
    symbol: 'FOX',
    path: 'STOCKS\\US STOCKS\\FOX',
    full_name: 'FOX',
    description: 'Fox Corp - Class B',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fox-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FOXA: {
    symbol: 'FOXA',
    path: 'STOCKS\\US STOCKS\\FOXA',
    full_name: 'FOXA',
    description: 'Fox Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fox-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FRT: {
    symbol: 'FRT',
    path: 'STOCKS\\US STOCKS\\FRT',
    full_name: 'FRT',
    description: 'Federal Realty Invs Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/federal-realty-investment-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FSLR: {
    symbol: 'FSLR',
    path: 'STOCKS\\US STOCKS\\FSLR',
    full_name: 'FSLR',
    description: 'First Solar Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/first-solar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FTI: {
    symbol: 'FTI',
    path: 'STOCKS\\US STOCKS\\FTI',
    full_name: 'FTI',
    description: 'Technipfmc Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/technipfmc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FTNT: {
    symbol: 'FTNT',
    path: 'STOCKS\\US STOCKS\\FTNT',
    full_name: 'FTNT',
    description: 'Fortinet Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fortinet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FTV: {
    symbol: 'FTV',
    path: 'STOCKS\\US STOCKS\\FTV',
    full_name: 'FTV',
    description: 'Fortive Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fortive.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FUTU: {
    symbol: 'FUTU',
    path: 'STOCKS\\US STOCKS\\FUTU',
    full_name: 'FUTU',
    description: 'Futu Holdings Ltd-Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/futu-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GATX: {
    symbol: 'GATX',
    path: 'STOCKS\\US STOCKS\\GATX',
    full_name: 'GATX',
    description: 'GATX Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gatx.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GCI: {
    symbol: 'GCI',
    path: 'STOCKS\\US STOCKS\\GCI',
    full_name: 'GCI',
    description: 'Gannett Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gannett.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GD: {
    symbol: 'GD',
    path: 'STOCKS\\US STOCKS\\GD',
    full_name: 'GD',
    description: 'General Dynamics Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GDDY: {
    symbol: 'GDDY',
    path: 'STOCKS\\US STOCKS\\GDDY',
    full_name: 'GDDY',
    description: 'GoDaddy Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/godaddy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GE: {
    symbol: 'GE',
    path: 'STOCKS\\US STOCKS\\GE',
    full_name: 'GE',
    description: 'General Electric Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/general-electric.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GEN: {
    symbol: 'GEN',
    path: 'STOCKS\\US STOCKS\\GEN',
    full_name: 'GEN',
    description: 'Gen Digital Inc.',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nortonlifelock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GES: {
    symbol: 'GES',
    path: 'STOCKS\\US STOCKS\\GES',
    full_name: 'GES',
    description: 'Guess? Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/guess.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GEVO: {
    symbol: 'GEVO',
    path: 'STOCKS\\US STOCKS\\GEVO',
    full_name: 'GEVO',
    description: 'GEVO.xnas',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gevo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GFI: {
    symbol: 'GFI',
    path: 'STOCKS\\US STOCKS\\GFI',
    full_name: 'GFI',
    description: 'Gold Fields Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gold-fields.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GFS: {
    symbol: 'GFS',
    path: 'STOCKS\\US STOCKS\\GFS',
    full_name: 'GFS',
    description: 'GlobalFoundries Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/globalfoundries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GGG: {
    symbol: 'GGG',
    path: 'STOCKS\\US STOCKS\\GGG',
    full_name: 'GGG',
    description: 'Graco Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/graco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GIL: {
    symbol: 'GIL',
    path: 'STOCKS\\US STOCKS\\GIL',
    full_name: 'GIL',
    description: 'Gildan Activewear Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gildan-activewear.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GILD: {
    symbol: 'GILD',
    path: 'STOCKS\\US STOCKS\\GILD',
    full_name: 'GILD',
    description: 'Gilead Sciences Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gilead.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GIS: {
    symbol: 'GIS',
    path: 'STOCKS\\US STOCKS\\GIS',
    full_name: 'GIS',
    description: 'General Mills Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/general-mills.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GLNG: {
    symbol: 'GLNG',
    path: 'STOCKS\\US STOCKS\\GLNG',
    full_name: 'GLNG',
    description: 'Golar LNG Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/golar-lng-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GLOB: {
    symbol: 'GLOB',
    path: 'STOCKS\\US STOCKS\\GLOB',
    full_name: 'GLOB',
    description: 'Globant SA',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/globant.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GLPI: {
    symbol: 'GLPI',
    path: 'STOCKS\\US STOCKS\\GLPI',
    full_name: 'GLPI',
    description: 'Gaming And Leisure Properties Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gaming-and-leisure-properties.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GLW: {
    symbol: 'GLW',
    path: 'STOCKS\\US STOCKS\\GLW',
    full_name: 'GLW',
    description: 'Corning Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/corning.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GM: {
    symbol: 'GM',
    path: 'STOCKS\\US STOCKS\\GM',
    full_name: 'GM',
    description: 'General Motors Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/general-motors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GNRC: {
    symbol: 'GNRC',
    path: 'STOCKS\\US STOCKS\\GNRC',
    full_name: 'GNRC',
    description: 'Generac Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/generac.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GNTX: {
    symbol: 'GNTX',
    path: 'STOCKS\\US STOCKS\\GNTX',
    full_name: 'GNTX',
    description: 'Gentex Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gentex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GOCO: {
    symbol: 'GOCO',
    path: 'STOCKS\\US STOCKS\\GOCO',
    full_name: 'GOCO',
    description: 'GoHealth Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gohealth.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GOLD: {
    symbol: 'GOLD',
    path: 'STOCKS\\US STOCKS\\GOLD',
    full_name: 'GOLD',
    description: 'Barrick Gold Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GOOG: {
    symbol: 'GOOG',
    path: 'STOCKS\\US STOCKS\\GOOG',
    full_name: 'GOOG',
    description: 'Alphabet Inc Class C',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alphabet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GOOGL: {
    symbol: 'GOOGL',
    path: 'STOCKS\\US STOCKS\\GOOGL',
    full_name: 'GOOGL',
    description: 'Alphabet Inc - A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alphabet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GPC: {
    symbol: 'GPC',
    path: 'STOCKS\\US STOCKS\\GPC',
    full_name: 'GPC',
    description: 'Genuine Parts Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gpc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GPN: {
    symbol: 'GPN',
    path: 'STOCKS\\US STOCKS\\GPN',
    full_name: 'GPN',
    description: 'Global Payments Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/global-payments.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GPRO: {
    symbol: 'GPRO',
    path: 'STOCKS\\US STOCKS\\GPRO',
    full_name: 'GPRO',
    description: 'GoPro Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gopro.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GPS: {
    symbol: 'GPS',
    path: 'STOCKS\\US STOCKS\\GPS',
    full_name: 'GPS',
    description: 'Gap Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gap.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GRMN: {
    symbol: 'GRMN',
    path: 'STOCKS\\US STOCKS\\GRMN',
    full_name: 'GRMN',
    description: 'Garmin Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/garmin-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GRVY: {
    symbol: 'GRVY',
    path: 'STOCKS\\US STOCKS\\GRVY',
    full_name: 'GRVY',
    description: 'Gravity Co Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gravity.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GS: {
    symbol: 'GS',
    path: 'STOCKS\\US STOCKS\\GS',
    full_name: 'GS',
    description: 'Goldman Sachs Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/golden-sachs-etf-trust-goldman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GSM: {
    symbol: 'GSM',
    path: 'STOCKS\\US STOCKS\\GSM',
    full_name: 'GSM',
    description: 'Ferroglobe PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ferroglobe.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GT: {
    symbol: 'GT',
    path: 'STOCKS\\US STOCKS\\GT',
    full_name: 'GT',
    description: 'Goodyear Tire & Rubber Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/goodyear-tire-and-rubber.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  GWW: {
    symbol: 'GWW',
    path: 'STOCKS\\US STOCKS\\GWW',
    full_name: 'GWW',
    description: 'WW Grainger Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/grainger.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  H: {
    symbol: 'H',
    path: 'STOCKS\\US STOCKS\\H',
    full_name: 'H',
    description: 'Hyatt Hotels Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hyatt-hotels.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HAL: {
    symbol: 'HAL',
    path: 'STOCKS\\US STOCKS\\HAL',
    full_name: 'HAL',
    description: 'Halliburton Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/halliburton.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HAS: {
    symbol: 'HAS',
    path: 'STOCKS\\US STOCKS\\HAS',
    full_name: 'HAS',
    description: 'Hasbro Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hasbro.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HBAN: {
    symbol: 'HBAN',
    path: 'STOCKS\\US STOCKS\\HBAN',
    full_name: 'HBAN',
    description: 'Huntington Bancshares Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huntington-bancshares.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HBI: {
    symbol: 'HBI',
    path: 'STOCKS\\US STOCKS\\HBI',
    full_name: 'HBI',
    description: 'Hanesbrands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hanesbrands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HCA: {
    symbol: 'HCA',
    path: 'STOCKS\\US STOCKS\\HCA',
    full_name: 'HCA',
    description: 'HCA Healthcare Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hca-healthcare.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HD: {
    symbol: 'HD',
    path: 'STOCKS\\US STOCKS\\HD',
    full_name: 'HD',
    description: 'Home Depot Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/home-depot.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HDB: {
    symbol: 'HDB',
    path: 'STOCKS\\US STOCKS\\HDB',
    full_name: 'HDB',
    description: 'HDFC Bank Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hdfc-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HES: {
    symbol: 'HES',
    path: 'STOCKS\\US STOCKS\\HES',
    full_name: 'HES',
    description: 'Hess Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hess.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HHH: {
    symbol: 'HHH',
    path: 'STOCKS\\US STOCKS\\HHH',
    full_name: 'HHH',
    description: 'Howard Hughes Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/howard-hughes.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HIG: {
    symbol: 'HIG',
    path: 'STOCKS\\US STOCKS\\HIG',
    full_name: 'HIG',
    description: 'Hartford Financial Services Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hartford-financial-services-grp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HII: {
    symbol: 'HII',
    path: 'STOCKS\\US STOCKS\\HII',
    full_name: 'HII',
    description: 'Huntington Ingalls Industries Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huntington-ingalls-industries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HIMX: {
    symbol: 'HIMX',
    path: 'STOCKS\\US STOCKS\\HIMX',
    full_name: 'HIMX',
    description: 'Himax Technologies Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/himax-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HLF: {
    symbol: 'HLF',
    path: 'STOCKS\\US STOCKS\\HLF',
    full_name: 'HLF',
    description: 'Herbalife Nutrition Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/herbalife-nutrition.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HLT: {
    symbol: 'HLT',
    path: 'STOCKS\\US STOCKS\\HLT',
    full_name: 'HLT',
    description: 'Hilton Worldwide',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hilton-worldwide.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HMC: {
    symbol: 'HMC',
    path: 'STOCKS\\US STOCKS\\HMC',
    full_name: 'HMC',
    description: 'Honda Motor Co Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/honda.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HMY: {
    symbol: 'HMY',
    path: 'STOCKS\\US STOCKS\\HMY',
    full_name: 'HMY',
    description: 'Harmony Gold Mining Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/harmony-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HOG: {
    symbol: 'HOG',
    path: 'STOCKS\\US STOCKS\\HOG',
    full_name: 'HOG',
    description: 'Harley-Davidson Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/harley-davidson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HOLX: {
    symbol: 'HOLX',
    path: 'STOCKS\\US STOCKS\\HOLX',
    full_name: 'HOLX',
    description: 'Hologic Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hologic.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HON: {
    symbol: 'HON',
    path: 'STOCKS\\US STOCKS\\HON',
    full_name: 'HON',
    description: 'Honeywell International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/honeywell.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HOOD: {
    symbol: 'HOOD',
    path: 'STOCKS\\US STOCKS\\HOOD',
    full_name: 'HOOD',
    description: 'Robinhood Markets Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/robinhood.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HP: {
    symbol: 'HP',
    path: 'STOCKS\\US STOCKS\\HP',
    full_name: 'HP',
    description: 'Helmerich & Payne Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/helmerich-payne.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HPE: {
    symbol: 'HPE',
    path: 'STOCKS\\US STOCKS\\HPE',
    full_name: 'HPE',
    description: 'Hewlett Packard Enterprise Co / HPE',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hewlett-packard-enterprise.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HPQ: {
    symbol: 'HPQ',
    path: 'STOCKS\\US STOCKS\\HPQ',
    full_name: 'HPQ',
    description: 'HP Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HRB: {
    symbol: 'HRB',
    path: 'STOCKS\\US STOCKS\\HRB',
    full_name: 'HRB',
    description: 'H&R Block Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/block-h-and-r.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HRL: {
    symbol: 'HRL',
    path: 'STOCKS\\US STOCKS\\HRL',
    full_name: 'HRL',
    description: 'Hormel Foods Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hormel-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HSIC: {
    symbol: 'HSIC',
    path: 'STOCKS\\US STOCKS\\HSIC',
    full_name: 'HSIC',
    description: 'Henry Schein Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/henry-schein.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HST: {
    symbol: 'HST',
    path: 'STOCKS\\US STOCKS\\HST',
    full_name: 'HST',
    description: 'Host Hotels & Resorts Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/host-hotels.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HSY: {
    symbol: 'HSY',
    path: 'STOCKS\\US STOCKS\\HSY',
    full_name: 'HSY',
    description: 'Hershey Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hershey.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HTHT: {
    symbol: 'HTHT',
    path: 'STOCKS\\US STOCKS\\HTHT',
    full_name: 'HTHT',
    description: 'Huazhu Group Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huazhu.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HUBS: {
    symbol: 'HUBS',
    path: 'STOCKS\\US STOCKS\\HUBS',
    full_name: 'HUBS',
    description: 'HubSpot Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hubspot.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HUM: {
    symbol: 'HUM',
    path: 'STOCKS\\US STOCKS\\HUM',
    full_name: 'HUM',
    description: 'Humana Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/humana.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HUN: {
    symbol: 'HUN',
    path: 'STOCKS\\US STOCKS\\HUN',
    full_name: 'HUN',
    description: 'Huntsman Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huntsman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HUT: {
    symbol: 'HUT',
    path: 'STOCKS\\US STOCKS\\HUT',
    full_name: 'HUT',
    description: 'Hut 8 Mining Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hut-8-mining.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HWM: {
    symbol: 'HWM',
    path: 'STOCKS\\US STOCKS\\HWM',
    full_name: 'HWM',
    description: 'Howmet Aerospace Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/howmet-aerospace.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HXL: {
    symbol: 'HXL',
    path: 'STOCKS\\US STOCKS\\HXL',
    full_name: 'HXL',
    description: 'Hexcel Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hexcel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HYLN: {
    symbol: 'HYLN',
    path: 'STOCKS\\US STOCKS\\HYLN',
    full_name: 'HYLN',
    description: 'Hyliion Holdings Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hyliion.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IAC: {
    symbol: 'IAC',
    path: 'STOCKS\\US STOCKS\\IAC',
    full_name: 'IAC',
    description: 'IAC / InterActive Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iac-interactivecorp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IBM: {
    symbol: 'IBM',
    path: 'STOCKS\\US STOCKS\\IBM',
    full_name: 'IBM',
    description: 'International Business Machines Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/international-bus-mach.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IBN: {
    symbol: 'IBN',
    path: 'STOCKS\\US STOCKS\\IBN',
    full_name: 'IBN',
    description: 'ICICI Bank Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/icici-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ICE: {
    symbol: 'ICE',
    path: 'STOCKS\\US STOCKS\\ICE',
    full_name: 'ICE',
    description: 'Intercontinental Exchange Inc / ICE',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intercontinental-exchange.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ICL: {
    symbol: 'ICL',
    path: 'STOCKS\\US STOCKS\\ICL',
    full_name: 'ICL',
    description: 'ICL Group Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/icl.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IDXX: {
    symbol: 'IDXX',
    path: 'STOCKS\\US STOCKS\\IDXX',
    full_name: 'IDXX',
    description: 'Idexx Laboratories Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/idexx-laboratories.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IEP: {
    symbol: 'IEP',
    path: 'STOCKS\\US STOCKS\\IEP',
    full_name: 'IEP',
    description: 'Icahn Enterprises LP',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/icahn-enterprises.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IFF: {
    symbol: 'IFF',
    path: 'STOCKS\\US STOCKS\\IFF',
    full_name: 'IFF',
    description: 'Intl Flavors & Fragrances Corp / IFF',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/international-flavors-and-fragrances.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IGT: {
    symbol: 'IGT',
    path: 'STOCKS\\US STOCKS\\IGT',
    full_name: 'IGT',
    description: 'International Game Technology PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/international-game-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ILMN: {
    symbol: 'ILMN',
    path: 'STOCKS\\US STOCKS\\ILMN',
    full_name: 'ILMN',
    description: 'Illumina',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/illumina.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IMO: {
    symbol: 'IMO',
    path: 'STOCKS\\US STOCKS\\IMO',
    full_name: 'IMO',
    description: 'Imperial Oil Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/imperial-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INCY: {
    symbol: 'INCY',
    path: 'STOCKS\\US STOCKS\\INCY',
    full_name: 'INCY',
    description: 'Incyte Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/incyte.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INFN: {
    symbol: 'INFN',
    path: 'STOCKS\\US STOCKS\\INFN',
    full_name: 'INFN',
    description: 'Infinera Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/infinera.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INFY: {
    symbol: 'INFY',
    path: 'STOCKS\\US STOCKS\\INFY',
    full_name: 'INFY',
    description: 'Infosys Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/infosys.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INGR: {
    symbol: 'INGR',
    path: 'STOCKS\\US STOCKS\\INGR',
    full_name: 'INGR',
    description: 'Ingredion Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ingredion.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INO: {
    symbol: 'INO',
    path: 'STOCKS\\US STOCKS\\INO',
    full_name: 'INO',
    description: 'Inovio Pharmaceuticals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/inovio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INTC: {
    symbol: 'INTC',
    path: 'STOCKS\\US STOCKS\\INTC',
    full_name: 'INTC',
    description: 'Intel',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  INTU: {
    symbol: 'INTU',
    path: 'STOCKS\\US STOCKS\\INTU',
    full_name: 'INTU',
    description: 'Intuit Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intuit.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IOVA: {
    symbol: 'IOVA',
    path: 'STOCKS\\US STOCKS\\IOVA',
    full_name: 'IOVA',
    description: 'Iovance Biotherapeutics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iovance-biotherapeutics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IP: {
    symbol: 'IP',
    path: 'STOCKS\\US STOCKS\\IP',
    full_name: 'IP',
    description: 'International Paper Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/international-paper.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IPG: {
    symbol: 'IPG',
    path: 'STOCKS\\US STOCKS\\IPG',
    full_name: 'IPG',
    description: 'Interpublic Group of Cos Inc / IPG',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/interpublic-group-cos.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IPGP: {
    symbol: 'IPGP',
    path: 'STOCKS\\US STOCKS\\IPGP',
    full_name: 'IPGP',
    description: 'IPG Photonics Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ipg-photonics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IQ: {
    symbol: 'IQ',
    path: 'STOCKS\\US STOCKS\\IQ',
    full_name: 'IQ',
    description: 'Iqiyi Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iqiyi.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IQV: {
    symbol: 'IQV',
    path: 'STOCKS\\US STOCKS\\IQV',
    full_name: 'IQV',
    description: 'IQVIA Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iqvia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IR: {
    symbol: 'IR',
    path: 'STOCKS\\US STOCKS\\IR',
    full_name: 'IR',
    description: 'Ingersoll Rand Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ingersoll-rand.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IRM: {
    symbol: 'IRM',
    path: 'STOCKS\\US STOCKS\\IRM',
    full_name: 'IRM',
    description: 'Iron Mountain Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iron-mountain-incorporated-delaware.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ISRG: {
    symbol: 'ISRG',
    path: 'STOCKS\\US STOCKS\\ISRG',
    full_name: 'ISRG',
    description: 'Intuitive Surgical Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intuitive-surgical.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IT: {
    symbol: 'IT',
    path: 'STOCKS\\US STOCKS\\IT',
    full_name: 'IT',
    description: 'Gartner Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gartner.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ITRI: {
    symbol: 'ITRI',
    path: 'STOCKS\\US STOCKS\\ITRI',
    full_name: 'ITRI',
    description: 'Itron Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/itron.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ITT: {
    symbol: 'ITT',
    path: 'STOCKS\\US STOCKS\\ITT',
    full_name: 'ITT',
    description: 'ITT Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/itt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ITUB: {
    symbol: 'ITUB',
    path: 'STOCKS\\US STOCKS\\ITUB',
    full_name: 'ITUB',
    description: 'Itau Unibanco Holing SA',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/itau-unibanco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ITW: {
    symbol: 'ITW',
    path: 'STOCKS\\US STOCKS\\ITW',
    full_name: 'ITW',
    description: 'Illinois Tool Works / ITW',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/illinois-tool-works.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IVR: {
    symbol: 'IVR',
    path: 'STOCKS\\US STOCKS\\IVR',
    full_name: 'IVR',
    description: 'Invesco Mortgage Capital Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/invesco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  IVZ: {
    symbol: 'IVZ',
    path: 'STOCKS\\US STOCKS\\IVZ',
    full_name: 'IVZ',
    description: 'Invesco Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/invesco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JAZZ: {
    symbol: 'JAZZ',
    path: 'STOCKS\\US STOCKS\\JAZZ',
    full_name: 'JAZZ',
    description: 'Jazz Pharmaceuticals PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jazz-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JBHT: {
    symbol: 'JBHT',
    path: 'STOCKS\\US STOCKS\\JBHT',
    full_name: 'JBHT',
    description: 'J.B. Hunt Transport Services Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/j-b-hunt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JBL: {
    symbol: 'JBL',
    path: 'STOCKS\\US STOCKS\\JBL',
    full_name: 'JBL',
    description: 'Jabil Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jabil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JBLU: {
    symbol: 'JBLU',
    path: 'STOCKS\\US STOCKS\\JBLU',
    full_name: 'JBLU',
    description: 'JetBlue Airways Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jetblue.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JCI: {
    symbol: 'JCI',
    path: 'STOCKS\\US STOCKS\\JCI',
    full_name: 'JCI',
    description: 'Johnson Controls International Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/johnson-controls.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JD: {
    symbol: 'JD',
    path: 'STOCKS\\US STOCKS\\JD',
    full_name: 'JD',
    description: 'JD.com Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jd-com.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JKS: {
    symbol: 'JKS',
    path: 'STOCKS\\US STOCKS\\JKS',
    full_name: 'JKS',
    description: 'JinkoSolar Holding Co (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jinkosolar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JMIA: {
    symbol: 'JMIA',
    path: 'STOCKS\\US STOCKS\\JMIA',
    full_name: 'JMIA',
    description: 'Jumia Technologies Ag-Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jumia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JNJ: {
    symbol: 'JNJ',
    path: 'STOCKS\\US STOCKS\\JNJ',
    full_name: 'JNJ',
    description: 'Johnson&Johnson',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/johnson-and-johnson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JNPR: {
    symbol: 'JNPR',
    path: 'STOCKS\\US STOCKS\\JNPR',
    full_name: 'JNPR',
    description: 'Juniper Networks Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/juniper-networks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JPM: {
    symbol: 'JPM',
    path: 'STOCKS\\US STOCKS\\JPM',
    full_name: 'JPM',
    description: 'Jp Morgan Chase',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jpmorgan-chase.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  JWN: {
    symbol: 'JWN',
    path: 'STOCKS\\US STOCKS\\JWN',
    full_name: 'JWN',
    description: 'Nordstrom Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nordstrom.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  K: {
    symbol: 'K',
    path: 'STOCKS\\US STOCKS\\K',
    full_name: 'K',
    description: 'Kellogg Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kellogg.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KBR: {
    symbol: 'KBR',
    path: 'STOCKS\\US STOCKS\\KBR',
    full_name: 'KBR',
    description: 'KBR Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kbr.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KDP: {
    symbol: 'KDP',
    path: 'STOCKS\\US STOCKS\\KDP',
    full_name: 'KDP',
    description: 'Keurig Dr Pepper Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/keurig-dr-pepper.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KEY: {
    symbol: 'KEY',
    path: 'STOCKS\\US STOCKS\\KEY',
    full_name: 'KEY',
    description: 'KeyCorp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/keycorp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KGC: {
    symbol: 'KGC',
    path: 'STOCKS\\US STOCKS\\KGC',
    full_name: 'KGC',
    description: 'Kinross Gold Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kinross-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KHC: {
    symbol: 'KHC',
    path: 'STOCKS\\US STOCKS\\KHC',
    full_name: 'KHC',
    description: 'Kraft Heinz Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kraft-heinz.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KIM: {
    symbol: 'KIM',
    path: 'STOCKS\\US STOCKS\\KIM',
    full_name: 'KIM',
    description: 'Kimco Realty Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kimco-realty-hc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KKR: {
    symbol: 'KKR',
    path: 'STOCKS\\US STOCKS\\KKR',
    full_name: 'KKR',
    description: 'KKR & Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kkr.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KLAC: {
    symbol: 'KLAC',
    path: 'STOCKS\\US STOCKS\\KLAC',
    full_name: 'KLAC',
    description: 'KLA Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kla-tencor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KMB: {
    symbol: 'KMB',
    path: 'STOCKS\\US STOCKS\\KMB',
    full_name: 'KMB',
    description: 'Kimberly-Clark Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kimberly-clark.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KMI: {
    symbol: 'KMI',
    path: 'STOCKS\\US STOCKS\\KMI',
    full_name: 'KMI',
    description: 'Kinder Morgan Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kinder-morgan.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KMT: {
    symbol: 'KMT',
    path: 'STOCKS\\US STOCKS\\KMT',
    full_name: 'KMT',
    description: 'Kennametal Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kennametal.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KMX: {
    symbol: 'KMX',
    path: 'STOCKS\\US STOCKS\\KMX',
    full_name: 'KMX',
    description: 'CarMax Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carmax.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KNDI: {
    symbol: 'KNDI',
    path: 'STOCKS\\US STOCKS\\KNDI',
    full_name: 'KNDI',
    description: 'Kandi Technologies Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kandi.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KNX: {
    symbol: 'KNX',
    path: 'STOCKS\\US STOCKS\\KNX',
    full_name: 'KNX',
    description: 'Knight-Swift Transportation Hlds Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/knight-swift.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KO: {
    symbol: 'KO',
    path: 'STOCKS\\US STOCKS\\KO',
    full_name: 'KO',
    description: 'Coca-Cola',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coca-cola.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KODK: {
    symbol: 'KODK',
    path: 'STOCKS\\US STOCKS\\KODK',
    full_name: 'KODK',
    description: 'Eastman Kodak Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eastman-kodak.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KOF: {
    symbol: 'KOF',
    path: 'STOCKS\\US STOCKS\\KOF',
    full_name: 'KOF',
    description: 'Coca-Cola Femsa SAB (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coca-cola.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KR: {
    symbol: 'KR',
    path: 'STOCKS\\US STOCKS\\KR',
    full_name: 'KR',
    description: 'Kroger Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kroger.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KRC: {
    symbol: 'KRC',
    path: 'STOCKS\\US STOCKS\\KRC',
    full_name: 'KRC',
    description: 'Kilroy Realty Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kilroy-realty.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  KSS: {
    symbol: 'KSS',
    path: 'STOCKS\\US STOCKS\\KSS',
    full_name: 'KSS',
    description: "Kohl's Corp",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kohls.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  L: {
    symbol: 'L',
    path: 'STOCKS\\US STOCKS\\L',
    full_name: 'L',
    description: 'Loews Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/loews.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LAC: {
    symbol: 'LAC',
    path: 'STOCKS\\US STOCKS\\LAC',
    full_name: 'LAC',
    description: 'Lithium Americas Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lithium-americas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LAUR: {
    symbol: 'LAUR',
    path: 'STOCKS\\US STOCKS\\LAUR',
    full_name: 'LAUR',
    description: 'Laureate Eduaction Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/laureate-education.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LAZ: {
    symbol: 'LAZ',
    path: 'STOCKS\\US STOCKS\\LAZ',
    full_name: 'LAZ',
    description: 'Lazard Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/la-z-boy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LAZR: {
    symbol: 'LAZR',
    path: 'STOCKS\\US STOCKS\\LAZR',
    full_name: 'LAZR',
    description: 'Luminar Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/luminar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LBTYA: {
    symbol: 'LBTYA',
    path: 'STOCKS\\US STOCKS\\LBTYA',
    full_name: 'LBTYA',
    description: 'Liberty Global PLC - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/liberty-global.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LBTYK: {
    symbol: 'LBTYK',
    path: 'STOCKS\\US STOCKS\\LBTYK',
    full_name: 'LBTYK',
    description: 'Liberty Global Plc - Class C',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/liberty-global.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LC: {
    symbol: 'LC',
    path: 'STOCKS\\US STOCKS\\LC',
    full_name: 'LC',
    description: 'Lendingclub Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lending-club.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LCID: {
    symbol: 'LCID',
    path: 'STOCKS\\US STOCKS\\LCID',
    full_name: 'LCID',
    description: 'Lucid Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lucid-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LECO: {
    symbol: 'LECO',
    path: 'STOCKS\\US STOCKS\\LECO',
    full_name: 'LECO',
    description: 'Lincoln Electric Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lincoln-electric.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LEG: {
    symbol: 'LEG',
    path: 'STOCKS\\US STOCKS\\LEG',
    full_name: 'LEG',
    description: 'Leggett & Platt Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/legget-platt.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LEGN: {
    symbol: 'LEGN',
    path: 'STOCKS\\US STOCKS\\LEGN',
    full_name: 'LEGN',
    description: 'Legend Biotech Corp (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/legend-biotech.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LEN: {
    symbol: 'LEN',
    path: 'STOCKS\\US STOCKS\\LEN',
    full_name: 'LEN',
    description: 'Lennar Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lennar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LEVI: {
    symbol: 'LEVI',
    path: 'STOCKS\\US STOCKS\\LEVI',
    full_name: 'LEVI',
    description: 'Levi Strauss & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/levi-strauss-and-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LHX: {
    symbol: 'LHX',
    path: 'STOCKS\\US STOCKS\\LHX',
    full_name: 'LHX',
    description: 'L3Harris Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/l3-harris.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LI: {
    symbol: 'LI',
    path: 'STOCKS\\US STOCKS\\LI',
    full_name: 'LI',
    description: 'Li Auto Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/li.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LITE: {
    symbol: 'LITE',
    path: 'STOCKS\\US STOCKS\\LITE',
    full_name: 'LITE',
    description: 'Lumentum Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lumentum-hldgs.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LKQ: {
    symbol: 'LKQ',
    path: 'STOCKS\\US STOCKS\\LKQ',
    full_name: 'LKQ',
    description: 'LKQ Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lkq.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LLY: {
    symbol: 'LLY',
    path: 'STOCKS\\US STOCKS\\LLY',
    full_name: 'LLY',
    description: 'Eli Lilly & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eli-lilly.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LMND: {
    symbol: 'LMND',
    path: 'STOCKS\\US STOCKS\\LMND',
    full_name: 'LMND',
    description: 'Lemonade Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lemonade.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LMT: {
    symbol: 'LMT',
    path: 'STOCKS\\US STOCKS\\LMT',
    full_name: 'LMT',
    description: 'Lockheed Martin Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lockheed-martin.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LNC: {
    symbol: 'LNC',
    path: 'STOCKS\\US STOCKS\\LNC',
    full_name: 'LNC',
    description: 'Lincoln National Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lincoln-national.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LNG: {
    symbol: 'LNG',
    path: 'STOCKS\\US STOCKS\\LNG',
    full_name: 'LNG',
    description: 'Cheniere Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cheniere-energy-partners.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LNT: {
    symbol: 'LNT',
    path: 'STOCKS\\US STOCKS\\LNT',
    full_name: 'LNT',
    description: 'Alliant Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alliant-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LOW: {
    symbol: 'LOW',
    path: 'STOCKS\\US STOCKS\\LOW',
    full_name: 'LOW',
    description: "Lowe's Cos Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lowe-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LPLA: {
    symbol: 'LPLA',
    path: 'STOCKS\\US STOCKS\\LPLA',
    full_name: 'LPLA',
    description: 'LPL Financial Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lpl-finl.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LPSN: {
    symbol: 'LPSN',
    path: 'STOCKS\\US STOCKS\\LPSN',
    full_name: 'LPSN',
    description: 'LivePerson Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/liveperson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LPX: {
    symbol: 'LPX',
    path: 'STOCKS\\US STOCKS\\LPX',
    full_name: 'LPX',
    description: 'Louisiana-Pacific Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/louisiana-pacific.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LULU: {
    symbol: 'LULU',
    path: 'STOCKS\\US STOCKS\\LULU',
    full_name: 'LULU',
    description: 'Lululemon Athletica Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lululemon-athletica.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LUMN: {
    symbol: 'LUMN',
    path: 'STOCKS\\US STOCKS\\LUMN',
    full_name: 'LUMN',
    description: 'Lumen Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lumen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LUV: {
    symbol: 'LUV',
    path: 'STOCKS\\US STOCKS\\LUV',
    full_name: 'LUV',
    description: 'Southwest Airlines Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/southwest.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LVS: {
    symbol: 'LVS',
    path: 'STOCKS\\US STOCKS\\LVS',
    full_name: 'LVS',
    description: 'Las Vegas Sands Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/las-vegas-sands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LXP: {
    symbol: 'LXP',
    path: 'STOCKS\\US STOCKS\\LXP',
    full_name: 'LXP',
    description: 'Lexington Realty Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lxp-industrial-trust-65-series-c-cumulative-convertible-preferred-stock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LYB: {
    symbol: 'LYB',
    path: 'STOCKS\\US STOCKS\\LYB',
    full_name: 'LYB',
    description: 'Lyondellbasell Industries - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lyondellbasell.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LYFT: {
    symbol: 'LYFT',
    path: 'STOCKS\\US STOCKS\\LYFT',
    full_name: 'LYFT',
    description: 'Lyft Inc-A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lyft.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  LYV: {
    symbol: 'LYV',
    path: 'STOCKS\\US STOCKS\\LYV',
    full_name: 'LYV',
    description: 'Live Nation Entertainment Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/live-nation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  M: {
    symbol: 'M',
    path: 'STOCKS\\US STOCKS\\M',
    full_name: 'M',
    description: "Macy's Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/macy-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MA: {
    symbol: 'MA',
    path: 'STOCKS\\US STOCKS\\MA',
    full_name: 'MA',
    description: 'Mastercard Cl A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mastercard.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MAC: {
    symbol: 'MAC',
    path: 'STOCKS\\US STOCKS\\MAC',
    full_name: 'MAC',
    description: 'Macerich Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/macerich-company-the.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MAN: {
    symbol: 'MAN',
    path: 'STOCKS\\US STOCKS\\MAN',
    full_name: 'MAN',
    description: 'ManpowerGroup Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/manpowergroup.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MANU: {
    symbol: 'MANU',
    path: 'STOCKS\\US STOCKS\\MANU',
    full_name: 'MANU',
    description: 'Manchester United Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/manchester-united-plc-new.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MAR: {
    symbol: 'MAR',
    path: 'STOCKS\\US STOCKS\\MAR',
    full_name: 'MAR',
    description: 'Marriott International - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marriott.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MARA: {
    symbol: 'MARA',
    path: 'STOCKS\\US STOCKS\\MARA',
    full_name: 'MARA',
    description: 'Marathon Patent Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marathon-digital-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MAS: {
    symbol: 'MAS',
    path: 'STOCKS\\US STOCKS\\MAS',
    full_name: 'MAS',
    description: 'Masco Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/masco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MASI: {
    symbol: 'MASI',
    path: 'STOCKS\\US STOCKS\\MASI',
    full_name: 'MASI',
    description: 'Masimo Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/masimo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MAT: {
    symbol: 'MAT',
    path: 'STOCKS\\US STOCKS\\MAT',
    full_name: 'MAT',
    description: 'Mattel Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mattel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MCD: {
    symbol: 'MCD',
    path: 'STOCKS\\US STOCKS\\MCD',
    full_name: 'MCD',
    description: 'McDonalds',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mcdonalds.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MCHP: {
    symbol: 'MCHP',
    path: 'STOCKS\\US STOCKS\\MCHP',
    full_name: 'MCHP',
    description: 'Microchip Technology Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/microchip-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MCK: {
    symbol: 'MCK',
    path: 'STOCKS\\US STOCKS\\MCK',
    full_name: 'MCK',
    description: 'Mckesson Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mckesson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MCO: {
    symbol: 'MCO',
    path: 'STOCKS\\US STOCKS\\MCO',
    full_name: 'MCO',
    description: "Moody's Corp",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/moodys.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MD: {
    symbol: 'MD',
    path: 'STOCKS\\US STOCKS\\MD',
    full_name: 'MD',
    description: 'MEDNAX Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mednax.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MDC: {
    symbol: 'MDC',
    path: 'STOCKS\\US STOCKS\\MDC',
    full_name: 'MDC',
    description: 'MDC Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/m-d-c-hldgs.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MDLZ: {
    symbol: 'MDLZ',
    path: 'STOCKS\\US STOCKS\\MDLZ',
    full_name: 'MDLZ',
    description: 'Mondelez International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mondelez.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MDRX: {
    symbol: 'MDRX',
    path: 'STOCKS\\US STOCKS\\MDRX',
    full_name: 'MDRX',
    description: 'Allscripts Healthcare Solutions Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allscripts-healthcare-solutions.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MDT: {
    symbol: 'MDT',
    path: 'STOCKS\\US STOCKS\\MDT',
    full_name: 'MDT',
    description: 'Medtronic Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/medtronic.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MDU: {
    symbol: 'MDU',
    path: 'STOCKS\\US STOCKS\\MDU',
    full_name: 'MDU',
    description: 'MDU Resources Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mdu-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MELI: {
    symbol: 'MELI',
    path: 'STOCKS\\US STOCKS\\MELI',
    full_name: 'MELI',
    description: 'Mercadolibre Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mercadolibre.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MEOH: {
    symbol: 'MEOH',
    path: 'STOCKS\\US STOCKS\\MEOH',
    full_name: 'MEOH',
    description: 'Methanex Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/methanex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MET: {
    symbol: 'MET',
    path: 'STOCKS\\US STOCKS\\MET',
    full_name: 'MET',
    description: 'Metlife Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metlife.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  META: {
    symbol: 'META',
    path: 'STOCKS\\US STOCKS\\META',
    full_name: 'META',
    description: 'META',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/meta-platforms.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MFA: {
    symbol: 'MFA',
    path: 'STOCKS\\US STOCKS\\MFA',
    full_name: 'MFA',
    description: 'MFA Financial Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mfa-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MFC: {
    symbol: 'MFC',
    path: 'STOCKS\\US STOCKS\\MFC',
    full_name: 'MFC',
    description: 'Manulife Financial Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/manulife.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MGA: {
    symbol: 'MGA',
    path: 'STOCKS\\US STOCKS\\MGA',
    full_name: 'MGA',
    description: 'Magna International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/magna-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MGM: {
    symbol: 'MGM',
    path: 'STOCKS\\US STOCKS\\MGM',
    full_name: 'MGM',
    description: 'MGM Resorts International',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mgm-resorts.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MKC: {
    symbol: 'MKC',
    path: 'STOCKS\\US STOCKS\\MKC',
    full_name: 'MKC',
    description: 'McCormick & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mccormick.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MKL: {
    symbol: 'MKL',
    path: 'STOCKS\\US STOCKS\\MKL',
    full_name: 'MKL',
    description: 'Markel Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/markel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MKTX: {
    symbol: 'MKTX',
    path: 'STOCKS\\US STOCKS\\MKTX',
    full_name: 'MKTX',
    description: 'MarketAxess Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marketaxess.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MLCO: {
    symbol: 'MLCO',
    path: 'STOCKS\\US STOCKS\\MLCO',
    full_name: 'MLCO',
    description: 'Melco Resorts & Entertainment Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/melco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MLM: {
    symbol: 'MLM',
    path: 'STOCKS\\US STOCKS\\MLM',
    full_name: 'MLM',
    description: 'Martin Marietta Materials Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/martin-marietta.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MMC: {
    symbol: 'MMC',
    path: 'STOCKS\\US STOCKS\\MMC',
    full_name: 'MMC',
    description: 'Marsh & McLennan Cos',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marsh-and-mclennan.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MMM: {
    symbol: 'MMM',
    path: 'STOCKS\\US STOCKS\\MMM',
    full_name: 'MMM',
    description: '3M Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/3m.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MNST: {
    symbol: 'MNST',
    path: 'STOCKS\\US STOCKS\\MNST',
    full_name: 'MNST',
    description: 'Monster Beverage Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/monster-beverage.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MO: {
    symbol: 'MO',
    path: 'STOCKS\\US STOCKS\\MO',
    full_name: 'MO',
    description: 'Altria Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/altria.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MOH: {
    symbol: 'MOH',
    path: 'STOCKS\\US STOCKS\\MOH',
    full_name: 'MOH',
    description: 'Molina Healthcare Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/molina-healthcare.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MOMO: {
    symbol: 'MOMO',
    path: 'STOCKS\\US STOCKS\\MOMO',
    full_name: 'MOMO',
    description: 'Momo Inc-Spon (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/momo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MOS: {
    symbol: 'MOS',
    path: 'STOCKS\\US STOCKS\\MOS',
    full_name: 'MOS',
    description: 'Mosaic Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mosaic.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MPC: {
    symbol: 'MPC',
    path: 'STOCKS\\US STOCKS\\MPC',
    full_name: 'MPC',
    description: 'Marathon Petroleum Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marathon-petroleum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MPLX: {
    symbol: 'MPLX',
    path: 'STOCKS\\US STOCKS\\MPLX',
    full_name: 'MPLX',
    description: 'MPLX LP',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mplx-lp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MPWR: {
    symbol: 'MPWR',
    path: 'STOCKS\\US STOCKS\\MPWR',
    full_name: 'MPWR',
    description: 'Monolithic Power Systems Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/monolithic-power-system.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MRK: {
    symbol: 'MRK',
    path: 'STOCKS\\US STOCKS\\MRK',
    full_name: 'MRK',
    description: 'Merck & Co Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/merck-and-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MRNA: {
    symbol: 'MRNA',
    path: 'STOCKS\\US STOCKS\\MRNA',
    full_name: 'MRNA',
    description: 'Moderna Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/moderna.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MRO: {
    symbol: 'MRO',
    path: 'STOCKS\\US STOCKS\\MRO',
    full_name: 'MRO',
    description: 'Marathon Oil Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marathon-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MRVL: {
    symbol: 'MRVL',
    path: 'STOCKS\\US STOCKS\\MRVL',
    full_name: 'MRVL',
    description: 'Marvell Technology Group Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marvell-tech.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MS: {
    symbol: 'MS',
    path: 'STOCKS\\US STOCKS\\MS',
    full_name: 'MS',
    description: 'Morgan Stanley',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/morgan-stanley.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MSCI: {
    symbol: 'MSCI',
    path: 'STOCKS\\US STOCKS\\MSCI',
    full_name: 'MSCI',
    description: 'MSCI Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/msci.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MSFT: {
    symbol: 'MSFT',
    path: 'STOCKS\\US STOCKS\\MSFT',
    full_name: 'MSFT',
    description: 'Microsoft',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/microsoft.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MSI: {
    symbol: 'MSI',
    path: 'STOCKS\\US STOCKS\\MSI',
    full_name: 'MSI',
    description: 'Motorola Solutions Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/motorola-solutions.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MSM: {
    symbol: 'MSM',
    path: 'STOCKS\\US STOCKS\\MSM',
    full_name: 'MSM',
    description: 'MSC Industrial Direct Co Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/msm-malaysia-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MSTR: {
    symbol: 'MSTR',
    path: 'STOCKS\\US STOCKS\\MSTR',
    full_name: 'MSTR',
    description: 'Microstrategy Inc Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/microstrategy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MTB: {
    symbol: 'MTB',
    path: 'STOCKS\\US STOCKS\\MTB',
    full_name: 'MTB',
    description: 'M & T Bank Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/m-and-t-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MTCH: {
    symbol: 'MTCH',
    path: 'STOCKS\\US STOCKS\\MTCH',
    full_name: 'MTCH',
    description: 'Match Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/match-group-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MTD: {
    symbol: 'MTD',
    path: 'STOCKS\\US STOCKS\\MTD',
    full_name: 'MTD',
    description: 'Mettler Toledo International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mettler-toledo-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MTG: {
    symbol: 'MTG',
    path: 'STOCKS\\US STOCKS\\MTG',
    full_name: 'MTG',
    description: 'MGIC Investment Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mgic-investment.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MU: {
    symbol: 'MU',
    path: 'STOCKS\\US STOCKS\\MU',
    full_name: 'MU',
    description: 'Micron Technology Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/micron-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MUR: {
    symbol: 'MUR',
    path: 'STOCKS\\US STOCKS\\MUR',
    full_name: 'MUR',
    description: 'Murphy Oil Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/murphy-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  MVST: {
    symbol: 'MVST',
    path: 'STOCKS\\US STOCKS\\MVST',
    full_name: 'MVST',
    description: 'Microvast Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/microvast.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NAVI: {
    symbol: 'NAVI',
    path: 'STOCKS\\US STOCKS\\NAVI',
    full_name: 'NAVI',
    description: 'Navient Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/navient.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NBIX: {
    symbol: 'NBIX',
    path: 'STOCKS\\US STOCKS\\NBIX',
    full_name: 'NBIX',
    description: 'Neurocrine Biosciences Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/neurocrine-biosciences.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NBR: {
    symbol: 'NBR',
    path: 'STOCKS\\US STOCKS\\NBR',
    full_name: 'NBR',
    description: 'Nabors Industries Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nabors-industries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NCLH: {
    symbol: 'NCLH',
    path: 'STOCKS\\US STOCKS\\NCLH',
    full_name: 'NCLH',
    description: 'Norwegian Cruise Line Holdings Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/norwegian-cruise-line.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NDAQ: {
    symbol: 'NDAQ',
    path: 'STOCKS\\US STOCKS\\NDAQ',
    full_name: 'NDAQ',
    description: 'Nasdaq Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nasdaq.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NDSN: {
    symbol: 'NDSN',
    path: 'STOCKS\\US STOCKS\\NDSN',
    full_name: 'NDSN',
    description: 'Nordson Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nordson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NEE: {
    symbol: 'NEE',
    path: 'STOCKS\\US STOCKS\\NEE',
    full_name: 'NEE',
    description: 'Nextera Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nextera-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NEM: {
    symbol: 'NEM',
    path: 'STOCKS\\US STOCKS\\NEM',
    full_name: 'NEM',
    description: 'Newmont Goldcorp Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/newmont.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NET: {
    symbol: 'NET',
    path: 'STOCKS\\US STOCKS\\NET',
    full_name: 'NET',
    description: 'Cloudflare Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cloudflare-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NEU: {
    symbol: 'NEU',
    path: 'STOCKS\\US STOCKS\\NEU',
    full_name: 'NEU',
    description: 'NewMarket Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/newmarket.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NFLX: {
    symbol: 'NFLX',
    path: 'STOCKS\\US STOCKS\\NFLX',
    full_name: 'NFLX',
    description: 'Netflix',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/netflix.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NGD: {
    symbol: 'NGD',
    path: 'STOCKS\\US STOCKS\\NGD',
    full_name: 'NGD',
    description: 'New Gold Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/new-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NGVC: {
    symbol: 'NGVC',
    path: 'STOCKS\\US STOCKS\\NGVC',
    full_name: 'NGVC',
    description: 'Natural Grocers by Vitamin Cottage Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natural-grocers-by-vitamin-cottage.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NIO: {
    symbol: 'NIO',
    path: 'STOCKS\\US STOCKS\\NIO',
    full_name: 'NIO',
    description: 'NIO Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NKE: {
    symbol: 'NKE',
    path: 'STOCKS\\US STOCKS\\NKE',
    full_name: 'NKE',
    description: 'Nike Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nike.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NKLA: {
    symbol: 'NKLA',
    path: 'STOCKS\\US STOCKS\\NKLA',
    full_name: 'NKLA',
    description: 'Nikola Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nikolamotor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NKTR: {
    symbol: 'NKTR',
    path: 'STOCKS\\US STOCKS\\NKTR',
    full_name: 'NKTR',
    description: 'Nektar Therapeutics',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nektar-therapeutics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NLY: {
    symbol: 'NLY',
    path: 'STOCKS\\US STOCKS\\NLY',
    full_name: 'NLY',
    description: 'Annaly Capital Management Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/annaly-capital-management.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NMRK: {
    symbol: 'NMRK',
    path: 'STOCKS\\US STOCKS\\NMRK',
    full_name: 'NMRK',
    description: 'Newmark Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/newmark-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NOC: {
    symbol: 'NOC',
    path: 'STOCKS\\US STOCKS\\NOC',
    full_name: 'NOC',
    description: 'Northrop Grumman Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/northrop-grumman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NOW: {
    symbol: 'NOW',
    path: 'STOCKS\\US STOCKS\\NOW',
    full_name: 'NOW',
    description: 'ServiceNow Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/servicenow.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NRG: {
    symbol: 'NRG',
    path: 'STOCKS\\US STOCKS\\NRG',
    full_name: 'NRG',
    description: 'NRG Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nrg-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NSC: {
    symbol: 'NSC',
    path: 'STOCKS\\US STOCKS\\NSC',
    full_name: 'NSC',
    description: 'Norfolk Southern Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/norfolk-southern.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NSTG: {
    symbol: 'NSTG',
    path: 'STOCKS\\US STOCKS\\NSTG',
    full_name: 'NSTG',
    description: 'NanoString Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nanostring-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NTAP: {
    symbol: 'NTAP',
    path: 'STOCKS\\US STOCKS\\NTAP',
    full_name: 'NTAP',
    description: 'Netapp Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/netapp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NTES: {
    symbol: 'NTES',
    path: 'STOCKS\\US STOCKS\\NTES',
    full_name: 'NTES',
    description: 'NetEase Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/netease.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NTRS: {
    symbol: 'NTRS',
    path: 'STOCKS\\US STOCKS\\NTRS',
    full_name: 'NTRS',
    description: 'Northern Trust Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/northern-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NUE: {
    symbol: 'NUE',
    path: 'STOCKS\\US STOCKS\\NUE',
    full_name: 'NUE',
    description: 'Nucor Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nucor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NUS: {
    symbol: 'NUS',
    path: 'STOCKS\\US STOCKS\\NUS',
    full_name: 'NUS',
    description: 'Nu Skin Enterprises Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nu-skin-enterprises.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NVAX: {
    symbol: 'NVAX',
    path: 'STOCKS\\US STOCKS\\NVAX',
    full_name: 'NVAX',
    description: 'Novavax Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/novavax.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NVDA: {
    symbol: 'NVDA',
    path: 'STOCKS\\US STOCKS\\NVDA',
    full_name: 'NVDA',
    description: 'NVIDIA Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nvidia.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NVRO: {
    symbol: 'NVRO',
    path: 'STOCKS\\US STOCKS\\NVRO',
    full_name: 'NVRO',
    description: 'Nevro Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nevro.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NVS: {
    symbol: 'NVS',
    path: 'STOCKS\\US STOCKS\\NVS',
    full_name: 'NVS',
    description: 'Novartis AG (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/novartis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NWL: {
    symbol: 'NWL',
    path: 'STOCKS\\US STOCKS\\NWL',
    full_name: 'NWL',
    description: 'Newell Brands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natural-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NWS: {
    symbol: 'NWS',
    path: 'STOCKS\\US STOCKS\\NWS',
    full_name: 'NWS',
    description: 'News Corp - Class B',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/news-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NWSA: {
    symbol: 'NWSA',
    path: 'STOCKS\\US STOCKS\\NWSA',
    full_name: 'NWSA',
    description: 'News Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/news-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NXPI: {
    symbol: 'NXPI',
    path: 'STOCKS\\US STOCKS\\NXPI',
    full_name: 'NXPI',
    description: 'NXP Semiconductors NV',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nxp-semiconductors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NYCB: {
    symbol: 'NYCB',
    path: 'STOCKS\\US STOCKS\\NYCB',
    full_name: 'NYCB',
    description: 'New York Community Bancorp Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/new-york-community.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NYT: {
    symbol: 'NYT',
    path: 'STOCKS\\US STOCKS\\NYT',
    full_name: 'NYT',
    description: 'The New York Times Co - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/new-york-times.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  O: {
    symbol: 'O',
    path: 'STOCKS\\US STOCKS\\O',
    full_name: 'O',
    description: 'Realty Income Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/realty-income.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OC: {
    symbol: 'OC',
    path: 'STOCKS\\US STOCKS\\OC',
    full_name: 'OC',
    description: 'Owens Corning Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/owens-corning.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OCSL: {
    symbol: 'OCSL',
    path: 'STOCKS\\US STOCKS\\OCSL',
    full_name: 'OCSL',
    description: 'Oaktree Specialty Lending Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oaktree-specialty-lending.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ODFL: {
    symbol: 'ODFL',
    path: 'STOCKS\\US STOCKS\\ODFL',
    full_name: 'ODFL',
    description: 'Old Dominion Freight Line',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/old-dominion.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OGE: {
    symbol: 'OGE',
    path: 'STOCKS\\US STOCKS\\OGE',
    full_name: 'OGE',
    description: 'OGE Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oge-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OHI: {
    symbol: 'OHI',
    path: 'STOCKS\\US STOCKS\\OHI',
    full_name: 'OHI',
    description: 'Omega Healthcare Investors Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/omega-healthcare-investors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OII: {
    symbol: 'OII',
    path: 'STOCKS\\US STOCKS\\OII',
    full_name: 'OII',
    description: 'Oceaneering Intl Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oceaneering-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OIS: {
    symbol: 'OIS',
    path: 'STOCKS\\US STOCKS\\OIS',
    full_name: 'OIS',
    description: 'Oil States International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oil-states-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OKE: {
    symbol: 'OKE',
    path: 'STOCKS\\US STOCKS\\OKE',
    full_name: 'OKE',
    description: 'Oneok Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oneok.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OKTA: {
    symbol: 'OKTA',
    path: 'STOCKS\\US STOCKS\\OKTA',
    full_name: 'OKTA',
    description: 'Okta Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/okta.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OLED: {
    symbol: 'OLED',
    path: 'STOCKS\\US STOCKS\\OLED',
    full_name: 'OLED',
    description: 'Universal Display Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/universal-display.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OLLI: {
    symbol: 'OLLI',
    path: 'STOCKS\\US STOCKS\\OLLI',
    full_name: 'OLLI',
    description: "Ollie's Bargain Outlet Hlds Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ollies-bargain-outlet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OMC: {
    symbol: 'OMC',
    path: 'STOCKS\\US STOCKS\\OMC',
    full_name: 'OMC',
    description: 'Omnicom Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/omnicom-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OMER: {
    symbol: 'OMER',
    path: 'STOCKS\\US STOCKS\\OMER',
    full_name: 'OMER',
    description: 'Omeros Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/omeros.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OMI: {
    symbol: 'OMI',
    path: 'STOCKS\\US STOCKS\\OMI',
    full_name: 'OMI',
    description: 'Owens & Minor Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/owens-and-minor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ON: {
    symbol: 'ON',
    path: 'STOCKS\\US STOCKS\\ON',
    full_name: 'ON',
    description: 'ON Semiconductor Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/on-semiconductor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OPFI: {
    symbol: 'OPFI',
    path: 'STOCKS\\US STOCKS\\OPFI',
    full_name: 'OPFI',
    description: 'OppFi Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oppfi.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OPK: {
    symbol: 'OPK',
    path: 'STOCKS\\US STOCKS\\OPK',
    full_name: 'OPK',
    description: 'OPKO Health Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/opko-health.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ORCL: {
    symbol: 'ORCL',
    path: 'STOCKS\\US STOCKS\\ORCL',
    full_name: 'ORCL',
    description: 'Oracle',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oracle.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ORI: {
    symbol: 'ORI',
    path: 'STOCKS\\US STOCKS\\ORI',
    full_name: 'ORI',
    description: 'Old Republic Intl Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/origin-property-public-company-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ORLY: {
    symbol: 'ORLY',
    path: 'STOCKS\\US STOCKS\\ORLY',
    full_name: 'ORLY',
    description: "O'Reilly Automotive Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/o-reilly-auto.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OSK: {
    symbol: 'OSK',
    path: 'STOCKS\\US STOCKS\\OSK',
    full_name: 'OSK',
    description: 'Oshkosh Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/oshkosh.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OTEX: {
    symbol: 'OTEX',
    path: 'STOCKS\\US STOCKS\\OTEX',
    full_name: 'OTEX',
    description: 'OpenText Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/open-text.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  OXY: {
    symbol: 'OXY',
    path: 'STOCKS\\US STOCKS\\OXY',
    full_name: 'OXY',
    description: 'Occidental Petroleum Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/occidental-petroleum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PAAS: {
    symbol: 'PAAS',
    path: 'STOCKS\\US STOCKS\\PAAS',
    full_name: 'PAAS',
    description: 'Pan American Silver Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pan-american-silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PACB: {
    symbol: 'PACB',
    path: 'STOCKS\\US STOCKS\\PACB',
    full_name: 'PACB',
    description: 'Pacific Biosciences of California Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pacific-biosciences.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PAGP: {
    symbol: 'PAGP',
    path: 'STOCKS\\US STOCKS\\PAGP',
    full_name: 'PAGP',
    description: 'Plains GP Holdings LP',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/plains-gp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PANW: {
    symbol: 'PANW',
    path: 'STOCKS\\US STOCKS\\PANW',
    full_name: 'PANW',
    description: 'Palo Alto Networks Inc Prov',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/palo-alto-networks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PARA: {
    symbol: 'PARA',
    path: 'STOCKS\\US STOCKS\\PARA',
    full_name: 'PARA',
    description: 'Paramount Global',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/viacomcbs.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PAYC: {
    symbol: 'PAYC',
    path: 'STOCKS\\US STOCKS\\PAYC',
    full_name: 'PAYC',
    description: 'Paycom Software Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paycom-software.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PAYX: {
    symbol: 'PAYX',
    path: 'STOCKS\\US STOCKS\\PAYX',
    full_name: 'PAYX',
    description: 'Paychex Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paychex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PBI: {
    symbol: 'PBI',
    path: 'STOCKS\\US STOCKS\\PBI',
    full_name: 'PBI',
    description: 'Pitney Bowes Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pitney-bowes.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PBR: {
    symbol: 'PBR',
    path: 'STOCKS\\US STOCKS\\PBR',
    full_name: 'PBR',
    description: 'Petroleo Brasileiro-Spon Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brasileiro-petrobras.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PCAR: {
    symbol: 'PCAR',
    path: 'STOCKS\\US STOCKS\\PCAR',
    full_name: 'PCAR',
    description: 'PACCAR Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paccar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PCG: {
    symbol: 'PCG',
    path: 'STOCKS\\US STOCKS\\PCG',
    full_name: 'PCG',
    description: 'PG&E Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pg-and-e.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PCRX: {
    symbol: 'PCRX',
    path: 'STOCKS\\US STOCKS\\PCRX',
    full_name: 'PCRX',
    description: 'Pacira Biosciences Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pacira-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PDCO: {
    symbol: 'PDCO',
    path: 'STOCKS\\US STOCKS\\PDCO',
    full_name: 'PDCO',
    description: 'Patterson Cos Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/patterson-companies-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PDD: {
    symbol: 'PDD',
    path: 'STOCKS\\US STOCKS\\PDD',
    full_name: 'PDD',
    description: 'Pinduoduo Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pinduoduo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PEG: {
    symbol: 'PEG',
    path: 'STOCKS\\US STOCKS\\PEG',
    full_name: 'PEG',
    description: 'Public Service Enterprise Group / PSEG',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/public-service-enterprise.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PENN: {
    symbol: 'PENN',
    path: 'STOCKS\\US STOCKS\\PENN',
    full_name: 'PENN',
    description: 'Penn National Gaming Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/penn-national-gaming.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PEP: {
    symbol: 'PEP',
    path: 'STOCKS\\US STOCKS\\PEP',
    full_name: 'PEP',
    description: 'PepsiCo Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pepsico.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PFE: {
    symbol: 'PFE',
    path: 'STOCKS\\US STOCKS\\PFE',
    full_name: 'PFE',
    description: 'Pfizer',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pfizer.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PFG: {
    symbol: 'PFG',
    path: 'STOCKS\\US STOCKS\\PFG',
    full_name: 'PFG',
    description: 'Principal Financial Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/principal.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PFGC: {
    symbol: 'PFGC',
    path: 'STOCKS\\US STOCKS\\PFGC',
    full_name: 'PFGC',
    description: 'Performance Food Group Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/performance-food.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PG: {
    symbol: 'PG',
    path: 'STOCKS\\US STOCKS\\PG',
    full_name: 'PG',
    description: 'Procter &Gamb',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/procter-and-gamble.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PGEN: {
    symbol: 'PGEN',
    path: 'STOCKS\\US STOCKS\\PGEN',
    full_name: 'PGEN',
    description: 'Precigen Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/precigen.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PGR: {
    symbol: 'PGR',
    path: 'STOCKS\\US STOCKS\\PGR',
    full_name: 'PGR',
    description: 'Progressive Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/progressive-ohio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PH: {
    symbol: 'PH',
    path: 'STOCKS\\US STOCKS\\PH',
    full_name: 'PH',
    description: 'Parker Hannifin Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/parker-hannifin.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PHM: {
    symbol: 'PHM',
    path: 'STOCKS\\US STOCKS\\PHM',
    full_name: 'PHM',
    description: 'PulteGroup Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pulte-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PII: {
    symbol: 'PII',
    path: 'STOCKS\\US STOCKS\\PII',
    full_name: 'PII',
    description: 'Polaris Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/polaris.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PINS: {
    symbol: 'PINS',
    path: 'STOCKS\\US STOCKS\\PINS',
    full_name: 'PINS',
    description: 'Pinterest Inc- Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pinterest.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PLAY: {
    symbol: 'PLAY',
    path: 'STOCKS\\US STOCKS\\PLAY',
    full_name: 'PLAY',
    description: 'Dave & Busters Entertainment Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dave-and-buster-s-entertainment.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PLCE: {
    symbol: 'PLCE',
    path: 'STOCKS\\US STOCKS\\PLCE',
    full_name: 'PLCE',
    description: "Children's Place Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/children-s-place.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PLD: {
    symbol: 'PLD',
    path: 'STOCKS\\US STOCKS\\PLD',
    full_name: 'PLD',
    description: 'Prologis Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prologis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PLTR: {
    symbol: 'PLTR',
    path: 'STOCKS\\US STOCKS\\PLTR',
    full_name: 'PLTR',
    description: 'Palantir Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/palantir.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PLUG: {
    symbol: 'PLUG',
    path: 'STOCKS\\US STOCKS\\PLUG',
    full_name: 'PLUG',
    description: 'Plug Power Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/plug-power.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PM: {
    symbol: 'PM',
    path: 'STOCKS\\US STOCKS\\PM',
    full_name: 'PM',
    description: 'Philip Morris International',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/philip-morris.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PNC: {
    symbol: 'PNC',
    path: 'STOCKS\\US STOCKS\\PNC',
    full_name: 'PNC',
    description: 'PNC Financial Services Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pnc-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PNNT: {
    symbol: 'PNNT',
    path: 'STOCKS\\US STOCKS\\PNNT',
    full_name: 'PNNT',
    description: 'PennantPark Investment Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pennantpark-floating-rate-capital-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PNR: {
    symbol: 'PNR',
    path: 'STOCKS\\US STOCKS\\PNR',
    full_name: 'PNR',
    description: 'Pentair PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pentair.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PNW: {
    symbol: 'PNW',
    path: 'STOCKS\\US STOCKS\\PNW',
    full_name: 'PNW',
    description: 'Pinnacle West Capital Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pinnacle-west-capital.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  POOL: {
    symbol: 'POOL',
    path: 'STOCKS\\US STOCKS\\POOL',
    full_name: 'POOL',
    description: 'Pool Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pool.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PPG: {
    symbol: 'PPG',
    path: 'STOCKS\\US STOCKS\\PPG',
    full_name: 'PPG',
    description: 'Ppg Industries Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ppg-industries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PPL: {
    symbol: 'PPL',
    path: 'STOCKS\\US STOCKS\\PPL',
    full_name: 'PPL',
    description: 'PPL Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ppl.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PRGO: {
    symbol: 'PRGO',
    path: 'STOCKS\\US STOCKS\\PRGO',
    full_name: 'PRGO',
    description: 'Perrigo Co PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/perrigo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PRU: {
    symbol: 'PRU',
    path: 'STOCKS\\US STOCKS\\PRU',
    full_name: 'PRU',
    description: 'Prudential Financial Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prudential.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PSA: {
    symbol: 'PSA',
    path: 'STOCKS\\US STOCKS\\PSA',
    full_name: 'PSA',
    description: 'Public Storage',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/public-storage.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PSEC: {
    symbol: 'PSEC',
    path: 'STOCKS\\US STOCKS\\PSEC',
    full_name: 'PSEC',
    description: 'Prospect Capital Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prospect-capital-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PSX: {
    symbol: 'PSX',
    path: 'STOCKS\\US STOCKS\\PSX',
    full_name: 'PSX',
    description: 'Phillips 66',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/phillips-66.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PTC: {
    symbol: 'PTC',
    path: 'STOCKS\\US STOCKS\\PTC',
    full_name: 'PTC',
    description: 'PTC Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ptc-india.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PTON: {
    symbol: 'PTON',
    path: 'STOCKS\\US STOCKS\\PTON',
    full_name: 'PTON',
    description: 'Peloton Interactive Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/peloton-interactive-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PVH: {
    symbol: 'PVH',
    path: 'STOCKS\\US STOCKS\\PVH',
    full_name: 'PVH',
    description: 'Pvh Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pvh.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PWR: {
    symbol: 'PWR',
    path: 'STOCKS\\US STOCKS\\PWR',
    full_name: 'PWR',
    description: 'Quanta Services Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/quanta-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  PYPL: {
    symbol: 'PYPL',
    path: 'STOCKS\\US STOCKS\\PYPL',
    full_name: 'PYPL',
    description: 'PayPal Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paypal.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  QCOM: {
    symbol: 'QCOM',
    path: 'STOCKS\\US STOCKS\\QCOM',
    full_name: 'QCOM',
    description: 'Qualcomm',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/qualcomm.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  QRTEA: {
    symbol: 'QRTEA',
    path: 'STOCKS\\US STOCKS\\QRTEA',
    full_name: 'QRTEA',
    description: 'Qurate Retail',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/qurate-retail.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  QRVO: {
    symbol: 'QRVO',
    path: 'STOCKS\\US STOCKS\\QRVO',
    full_name: 'QRVO',
    description: 'Qorvo Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/qorvo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  QS: {
    symbol: 'QS',
    path: 'STOCKS\\US STOCKS\\QS',
    full_name: 'QS',
    description: 'Quantumscape Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/quantumscape.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RACE: {
    symbol: 'RACE',
    path: 'STOCKS\\US STOCKS\\RACE',
    full_name: 'RACE',
    description: 'Ferrari',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ferrari.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RBLX: {
    symbol: 'RBLX',
    path: 'STOCKS\\US STOCKS\\RBLX',
    full_name: 'RBLX',
    description: 'Roblox Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/roblox.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RCL: {
    symbol: 'RCL',
    path: 'STOCKS\\US STOCKS\\RCL',
    full_name: 'RCL',
    description: 'Royal Caribbean Cruises Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/royal-caribbean-cruises.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  REG: {
    symbol: 'REG',
    path: 'STOCKS\\US STOCKS\\REG',
    full_name: 'REG',
    description: 'Regency Centers Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/regency-centers.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  REGN: {
    symbol: 'REGN',
    path: 'STOCKS\\US STOCKS\\REGN',
    full_name: 'REGN',
    description: 'Regeneron Pharmaceuticals',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/regeneron-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RF: {
    symbol: 'RF',
    path: 'STOCKS\\US STOCKS\\RF',
    full_name: 'RF',
    description: 'Regions Financial Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/regions-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RGLD: {
    symbol: 'RGLD',
    path: 'STOCKS\\US STOCKS\\RGLD',
    full_name: 'RGLD',
    description: 'Royal Gold Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/royal-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RH: {
    symbol: 'RH',
    path: 'STOCKS\\US STOCKS\\RH',
    full_name: 'RH',
    description: 'RH',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rh.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RHI: {
    symbol: 'RHI',
    path: 'STOCKS\\US STOCKS\\RHI',
    full_name: 'RHI',
    description: 'Robert Half International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/robert-half-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RIG: {
    symbol: 'RIG',
    path: 'STOCKS\\US STOCKS\\RIG',
    full_name: 'RIG',
    description: 'Transocean LTD',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/transocean-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RIOT: {
    symbol: 'RIOT',
    path: 'STOCKS\\US STOCKS\\RIOT',
    full_name: 'RIOT',
    description: 'Riot Blockchain Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/riot-blockchain.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RIVN: {
    symbol: 'RIVN',
    path: 'STOCKS\\US STOCKS\\RIVN',
    full_name: 'RIVN',
    description: 'Rivian Automotive Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rivian.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RKT: {
    symbol: 'RKT',
    path: 'STOCKS\\US STOCKS\\RKT',
    full_name: 'RKT',
    description: 'Rocket Cos Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rocket-companies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RL: {
    symbol: 'RL',
    path: 'STOCKS\\US STOCKS\\RL',
    full_name: 'RL',
    description: 'Ralph Lauren Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ralph-lauren.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RMD: {
    symbol: 'RMD',
    path: 'STOCKS\\US STOCKS\\RMD',
    full_name: 'RMD',
    description: 'ResMed Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/resmed.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RNG: {
    symbol: 'RNG',
    path: 'STOCKS\\US STOCKS\\RNG',
    full_name: 'RNG',
    description: 'RingCentral Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ringcentral.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RNR: {
    symbol: 'RNR',
    path: 'STOCKS\\US STOCKS\\RNR',
    full_name: 'RNR',
    description: 'RenaissanceRe Holdings Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/renaissancere.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ROK: {
    symbol: 'ROK',
    path: 'STOCKS\\US STOCKS\\ROK',
    full_name: 'ROK',
    description: 'Rockwell Automation Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rockwell-automation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ROKU: {
    symbol: 'ROKU',
    path: 'STOCKS\\US STOCKS\\ROKU',
    full_name: 'ROKU',
    description: 'Roku Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/roku.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ROP: {
    symbol: 'ROP',
    path: 'STOCKS\\US STOCKS\\ROP',
    full_name: 'ROP',
    description: 'Roper Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/roper-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ROST: {
    symbol: 'ROST',
    path: 'STOCKS\\US STOCKS\\ROST',
    full_name: 'ROST',
    description: 'Ross Stores Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ross-stores.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RRC: {
    symbol: 'RRC',
    path: 'STOCKS\\US STOCKS\\RRC',
    full_name: 'RRC',
    description: 'Range Resources Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/range-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RS: {
    symbol: 'RS',
    path: 'STOCKS\\US STOCKS\\RS',
    full_name: 'RS',
    description: 'Reliance Steel & Aluminum Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/reliance-steel-and-aluminium.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RSG: {
    symbol: 'RSG',
    path: 'STOCKS\\US STOCKS\\RSG',
    full_name: 'RSG',
    description: 'Republic Services Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/republic-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RTX: {
    symbol: 'RTX',
    path: 'STOCKS\\US STOCKS\\RTX',
    full_name: 'RTX',
    description: 'Raytheon Technologies',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/raytheon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RVTY: {
    symbol: 'RVTY',
    path: 'STOCKS\\US STOCKS\\RVTY',
    full_name: 'RVTY',
    description: 'Revvity Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/perkinelmer.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RYAAY: {
    symbol: 'RYAAY',
    path: 'STOCKS\\US STOCKS\\RYAAY',
    full_name: 'RYAAY',
    description: 'Ryanair Holdings Plc-Sp Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ryanair.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  RYN: {
    symbol: 'RYN',
    path: 'STOCKS\\US STOCKS\\RYN',
    full_name: 'RYN',
    description: 'Rayonier Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rayonier-reit.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SABR: {
    symbol: 'SABR',
    path: 'STOCKS\\US STOCKS\\SABR',
    full_name: 'SABR',
    description: 'Sabre Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sabre.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SAVE: {
    symbol: 'SAVE',
    path: 'STOCKS\\US STOCKS\\SAVE',
    full_name: 'SAVE',
    description: 'Spirit Airlines Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/spirit-airlines.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SBUX: {
    symbol: 'SBUX',
    path: 'STOCKS\\US STOCKS\\SBUX',
    full_name: 'SBUX',
    description: 'Starbucks Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/starbucks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SCHW: {
    symbol: 'SCHW',
    path: 'STOCKS\\US STOCKS\\SCHW',
    full_name: 'SCHW',
    description: 'Schwab (Charles) Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/schwab.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SE: {
    symbol: 'SE',
    path: 'STOCKS\\US STOCKS\\SE',
    full_name: 'SE',
    description: 'Sea Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sea-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SENS: {
    symbol: 'SENS',
    path: 'STOCKS\\US STOCKS\\SENS',
    full_name: 'SENS',
    description: 'Senseonich Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/senseonics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SFIX: {
    symbol: 'SFIX',
    path: 'STOCKS\\US STOCKS\\SFIX',
    full_name: 'SFIX',
    description: 'Stitch Fix Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stitch-fix.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SFM: {
    symbol: 'SFM',
    path: 'STOCKS\\US STOCKS\\SFM',
    full_name: 'SFM',
    description: 'Sprouts Farmers Market Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sprouts-farmers-markets.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SHAK: {
    symbol: 'SHAK',
    path: 'STOCKS\\US STOCKS\\SHAK',
    full_name: 'SHAK',
    description: 'Shake Shack Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/shake-shack.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SHOP: {
    symbol: 'SHOP',
    path: 'STOCKS\\US STOCKS\\SHOP',
    full_name: 'SHOP',
    description: 'Shopify Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/shopify.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SHW: {
    symbol: 'SHW',
    path: 'STOCKS\\US STOCKS\\SHW',
    full_name: 'SHW',
    description: 'Sherwin-Williams Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sherwin-williams.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SIG: {
    symbol: 'SIG',
    path: 'STOCKS\\US STOCKS\\SIG',
    full_name: 'SIG',
    description: 'Signet Jewelers Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/signet-jewelers.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SIRI: {
    symbol: 'SIRI',
    path: 'STOCKS\\US STOCKS\\SIRI',
    full_name: 'SIRI',
    description: 'Sirius XM Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sansiri.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SJM: {
    symbol: 'SJM',
    path: 'STOCKS\\US STOCKS\\SJM',
    full_name: 'SJM',
    description: 'J.M. Smucker Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smucker.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SKX: {
    symbol: 'SKX',
    path: 'STOCKS\\US STOCKS\\SKX',
    full_name: 'SKX',
    description: 'Skechers USA Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/skechers-usa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SLB: {
    symbol: 'SLB',
    path: 'STOCKS\\US STOCKS\\SLB',
    full_name: 'SLB',
    description: 'Schlumberger Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/schlumberger.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SLG: {
    symbol: 'SLG',
    path: 'STOCKS\\US STOCKS\\SLG',
    full_name: 'SLG',
    description: 'Sl Green Realty Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sl-green-realty-corp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SLM: {
    symbol: 'SLM',
    path: 'STOCKS\\US STOCKS\\SLM',
    full_name: 'SLM',
    description: 'SLM Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/slm.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SMAR: {
    symbol: 'SMAR',
    path: 'STOCKS\\US STOCKS\\SMAR',
    full_name: 'SMAR',
    description: 'Smartsheet Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smartsheet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SMCI: {
    symbol: 'SMCI',
    path: 'STOCKS\\US STOCKS\\SMCI',
    full_name: 'SMCI',
    description: 'Super Micro Computer Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/super-micro-computer.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SMG: {
    symbol: 'SMG',
    path: 'STOCKS\\US STOCKS\\SMG',
    full_name: 'SMG',
    description: 'ScottsMiracle-Gro Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/scotts-miracle-gro-company.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SNAP: {
    symbol: 'SNAP',
    path: 'STOCKS\\US STOCKS\\SNAP',
    full_name: 'SNAP',
    description: 'Snap Inc - A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/snap.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SNOW: {
    symbol: 'SNOW',
    path: 'STOCKS\\US STOCKS\\SNOW',
    full_name: 'SNOW',
    description: 'Snowflake Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/snowflake.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SNPS: {
    symbol: 'SNPS',
    path: 'STOCKS\\US STOCKS\\SNPS',
    full_name: 'SNPS',
    description: 'Synopsys Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/synopsys.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SO: {
    symbol: 'SO',
    path: 'STOCKS\\US STOCKS\\SO',
    full_name: 'SO',
    description: 'Southern Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/southern.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SOFI: {
    symbol: 'SOFI',
    path: 'STOCKS\\US STOCKS\\SOFI',
    full_name: 'SOFI',
    description: 'SoFi Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sofi.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SOHU: {
    symbol: 'SOHU',
    path: 'STOCKS\\US STOCKS\\SOHU',
    full_name: 'SOHU',
    description: 'Sohu.com Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sohu-com.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SONO: {
    symbol: 'SONO',
    path: 'STOCKS\\US STOCKS\\SONO',
    full_name: 'SONO',
    description: 'Sonos Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sonos.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SONY: {
    symbol: 'SONY',
    path: 'STOCKS\\US STOCKS\\SONY',
    full_name: 'SONY',
    description: 'Sony Corp - (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sony.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPCE: {
    symbol: 'SPCE',
    path: 'STOCKS\\US STOCKS\\SPCE',
    full_name: 'SPCE',
    description: 'Virgin Galactic Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/virgin-galactic.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPG: {
    symbol: 'SPG',
    path: 'STOCKS\\US STOCKS\\SPG',
    full_name: 'SPG',
    description: 'Simon Property Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/simon-property.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPGI: {
    symbol: 'SPGI',
    path: 'STOCKS\\US STOCKS\\SPGI',
    full_name: 'SPGI',
    description: 'S&P Global Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/s-and-p-global.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPOT: {
    symbol: 'SPOT',
    path: 'STOCKS\\US STOCKS\\SPOT',
    full_name: 'SPOT',
    description: 'Spotify Technology SA',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/spotify-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPR: {
    symbol: 'SPR',
    path: 'STOCKS\\US STOCKS\\SPR',
    full_name: 'SPR',
    description: 'Spirit AeroSystems Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/spirit-aerosystems-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPWH: {
    symbol: 'SPWH',
    path: 'STOCKS\\US STOCKS\\SPWH',
    full_name: 'SPWH',
    description: "Sportsman's Warehouse Holdings Inc",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sportsmans-warehouse.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SPWR: {
    symbol: 'SPWR',
    path: 'STOCKS\\US STOCKS\\SPWR',
    full_name: 'SPWR',
    description: 'SunPower Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sunpower.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SQ: {
    symbol: 'SQ',
    path: 'STOCKS\\US STOCKS\\SQ',
    full_name: 'SQ',
    description: 'Square Inc - A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/block.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SQM: {
    symbol: 'SQM',
    path: 'STOCKS\\US STOCKS\\SQM',
    full_name: 'SQM',
    description: 'Sociedad Quimica y Minera SA (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sqm.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SRCL: {
    symbol: 'SRCL',
    path: 'STOCKS\\US STOCKS\\SRCL',
    full_name: 'SRCL',
    description: 'Stericycle Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stericycle.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SRE: {
    symbol: 'SRE',
    path: 'STOCKS\\US STOCKS\\SRE',
    full_name: 'SRE',
    description: 'Sempra Energy',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sempra-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SSNC: {
    symbol: 'SSNC',
    path: 'STOCKS\\US STOCKS\\SSNC',
    full_name: 'SSNC',
    description: 'SS&C Technologies Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ss-and-c.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  STLA: {
    symbol: 'STLA',
    path: 'STOCKS\\US STOCKS\\STLA',
    full_name: 'STLA',
    description: 'Stellantis NV',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stellantis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  STLD: {
    symbol: 'STLD',
    path: 'STOCKS\\US STOCKS\\STLD',
    full_name: 'STLD',
    description: 'Steel Dynamics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/steel-dynamics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  STT: {
    symbol: 'STT',
    path: 'STOCKS\\US STOCKS\\STT',
    full_name: 'STT',
    description: 'State Street Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/state-street.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  STX: {
    symbol: 'STX',
    path: 'STOCKS\\US STOCKS\\STX',
    full_name: 'STX',
    description: 'Seagate Technology Holdings',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/seagate.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  STZ: {
    symbol: 'STZ',
    path: 'STOCKS\\US STOCKS\\STZ',
    full_name: 'STZ',
    description: 'Constellation Brands Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/constellation-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SU: {
    symbol: 'SU',
    path: 'STOCKS\\US STOCKS\\SU',
    full_name: 'SU',
    description: 'Suncor Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/suncor-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SUM: {
    symbol: 'SUM',
    path: 'STOCKS\\US STOCKS\\SUM',
    full_name: 'SUM',
    description: 'Summit Materials Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/summit-materials.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SVC: {
    symbol: 'SVC',
    path: 'STOCKS\\US STOCKS\\SVC',
    full_name: 'SVC',
    description: 'Service Properties Trust',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/service-properties-trust-shares-of-beneficial-interest.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SWBI: {
    symbol: 'SWBI',
    path: 'STOCKS\\US STOCKS\\SWBI',
    full_name: 'SWBI',
    description: 'Smith & Wesson Brands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smith-and-wesson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SWK: {
    symbol: 'SWK',
    path: 'STOCKS\\US STOCKS\\SWK',
    full_name: 'SWK',
    description: 'Stanley Black & Decker Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stanley-black-and-decker.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SWKS: {
    symbol: 'SWKS',
    path: 'STOCKS\\US STOCKS\\SWKS',
    full_name: 'SWKS',
    description: 'Skyworks Solutions Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/skyworks-solutions.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SWN: {
    symbol: 'SWN',
    path: 'STOCKS\\US STOCKS\\SWN',
    full_name: 'SWN',
    description: 'Southwestern Energy Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/southwestern-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SXC: {
    symbol: 'SXC',
    path: 'STOCKS\\US STOCKS\\SXC',
    full_name: 'SXC',
    description: 'SunCoke Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/suncoke-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SYF: {
    symbol: 'SYF',
    path: 'STOCKS\\US STOCKS\\SYF',
    full_name: 'SYF',
    description: 'Synchrony Financial',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/synchrony.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SYK: {
    symbol: 'SYK',
    path: 'STOCKS\\US STOCKS\\SYK',
    full_name: 'SYK',
    description: 'Stryker Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stryker.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SYMC: {
    symbol: 'SYMC',
    path: 'STOCKS\\US STOCKS\\SYMC',
    full_name: 'SYMC',
    description: 'NortonLifeLock Inc / Symantec',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nortonlifelock.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SYNA: {
    symbol: 'SYNA',
    path: 'STOCKS\\US STOCKS\\SYNA',
    full_name: 'SYNA',
    description: 'Synaptics Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/synaptics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SYY: {
    symbol: 'SYY',
    path: 'STOCKS\\US STOCKS\\SYY',
    full_name: 'SYY',
    description: 'Sysco Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sysco.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  T: {
    symbol: 'T',
    path: 'STOCKS\\US STOCKS\\T',
    full_name: 'T',
    description: 'At&T',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/at-and-t.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TAL: {
    symbol: 'TAL',
    path: 'STOCKS\\US STOCKS\\TAL',
    full_name: 'TAL',
    description: 'Tal Education Group (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tal-education.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TAP: {
    symbol: 'TAP',
    path: 'STOCKS\\US STOCKS\\TAP',
    full_name: 'TAP',
    description: 'Molson Coors Beverage Co - Class B',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/molson-coors.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TCOM: {
    symbol: 'TCOM',
    path: 'STOCKS\\US STOCKS\\TCOM',
    full_name: 'TCOM',
    description: 'Trip.Com Group Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ctrip-com-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TDOC: {
    symbol: 'TDOC',
    path: 'STOCKS\\US STOCKS\\TDOC',
    full_name: 'TDOC',
    description: 'Teladoc Health Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teladoc-health.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TDY: {
    symbol: 'TDY',
    path: 'STOCKS\\US STOCKS\\TDY',
    full_name: 'TDY',
    description: 'Teledyne Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teledyne-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TEAM: {
    symbol: 'TEAM',
    path: 'STOCKS\\US STOCKS\\TEAM',
    full_name: 'TEAM',
    description: 'Atlassian Corp Plc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/atlassian.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TEL: {
    symbol: 'TEL',
    path: 'STOCKS\\US STOCKS\\TEL',
    full_name: 'TEL',
    description: 'TE Connectivity Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/te-connectivity.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TEO: {
    symbol: 'TEO',
    path: 'STOCKS\\US STOCKS\\TEO',
    full_name: 'TEO',
    description: 'Telecom Argentina SA (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telecom-argentina.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TER: {
    symbol: 'TER',
    path: 'STOCKS\\US STOCKS\\TER',
    full_name: 'TER',
    description: 'Teradyne Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teradyne.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TEVA: {
    symbol: 'TEVA',
    path: 'STOCKS\\US STOCKS\\TEVA',
    full_name: 'TEVA',
    description: 'Teva Pharmaceutical Inds',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teva.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TFC: {
    symbol: 'TFC',
    path: 'STOCKS\\US STOCKS\\TFC',
    full_name: 'TFC',
    description: 'Truist Financial Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/truist-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TFX: {
    symbol: 'TFX',
    path: 'STOCKS\\US STOCKS\\TFX',
    full_name: 'TFX',
    description: 'Teleflex Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teleflex.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TGI: {
    symbol: 'TGI',
    path: 'STOCKS\\US STOCKS\\TGI',
    full_name: 'TGI',
    description: 'Triumph Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/triumph-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TGNA: {
    symbol: 'TGNA',
    path: 'STOCKS\\US STOCKS\\TGNA',
    full_name: 'TGNA',
    description: 'TEGNA Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tegna.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TGT: {
    symbol: 'TGT',
    path: 'STOCKS\\US STOCKS\\TGT',
    full_name: 'TGT',
    description: 'Target Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/target.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  THC: {
    symbol: 'THC',
    path: 'STOCKS\\US STOCKS\\THC',
    full_name: 'THC',
    description: 'Tenet Healthcare Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tenet-healthcare.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  THO: {
    symbol: 'THO',
    path: 'STOCKS\\US STOCKS\\THO',
    full_name: 'THO',
    description: 'Thor Industries Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/thor-industries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TJX: {
    symbol: 'TJX',
    path: 'STOCKS\\US STOCKS\\TJX',
    full_name: 'TJX',
    description: 'TJX Companies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tjx-cos.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TLRY: {
    symbol: 'TLRY',
    path: 'STOCKS\\US STOCKS\\TLRY',
    full_name: 'TLRY',
    description: 'Tilray Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tilray.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TM: {
    symbol: 'TM',
    path: 'STOCKS\\US STOCKS\\TM',
    full_name: 'TM',
    description: 'Toyota Motor Corp (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/toyota.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TME: {
    symbol: 'TME',
    path: 'STOCKS\\US STOCKS\\TME',
    full_name: 'TME',
    description: 'Tencent Music Entertainment (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tencent-music-entertainment.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TMO: {
    symbol: 'TMO',
    path: 'STOCKS\\US STOCKS\\TMO',
    full_name: 'TMO',
    description: 'Thermo Fisher Scientific Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/thermo-fisher-scientific.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TMUS: {
    symbol: 'TMUS',
    path: 'STOCKS\\US STOCKS\\TMUS',
    full_name: 'TMUS',
    description: 'T-Mobile US Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/t-mobile.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TNET: {
    symbol: 'TNET',
    path: 'STOCKS\\US STOCKS\\TNET',
    full_name: 'TNET',
    description: 'TriNet Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/trinet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TPH: {
    symbol: 'TPH',
    path: 'STOCKS\\US STOCKS\\TPH',
    full_name: 'TPH',
    description: 'Tri Pointe Homes Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tri-pointe.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TPR: {
    symbol: 'TPR',
    path: 'STOCKS\\US STOCKS\\TPR',
    full_name: 'TPR',
    description: 'Tapestry Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tapestry.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRI: {
    symbol: 'TRI',
    path: 'STOCKS\\US STOCKS\\TRI',
    full_name: 'TRI',
    description: 'Thomson Reuters Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/thomson-reuters.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRIP: {
    symbol: 'TRIP',
    path: 'STOCKS\\US STOCKS\\TRIP',
    full_name: 'TRIP',
    description: 'TripAdvisor Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tripadvisor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRMB: {
    symbol: 'TRMB',
    path: 'STOCKS\\US STOCKS\\TRMB',
    full_name: 'TRMB',
    description: 'Trimble Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/trimble.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRN: {
    symbol: 'TRN',
    path: 'STOCKS\\US STOCKS\\TRN',
    full_name: 'TRN',
    description: 'Trinity Industries Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/terna.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TROW: {
    symbol: 'TROW',
    path: 'STOCKS\\US STOCKS\\TROW',
    full_name: 'TROW',
    description: 'T Rowe Price Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rowe-price.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRV: {
    symbol: 'TRV',
    path: 'STOCKS\\US STOCKS\\TRV',
    full_name: 'TRV',
    description: 'The Travelers Companies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/travelers.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TRVG: {
    symbol: 'TRVG',
    path: 'STOCKS\\US STOCKS\\TRVG',
    full_name: 'TRVG',
    description: 'Trivago NV (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/trivago.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TSCO: {
    symbol: 'TSCO',
    path: 'STOCKS\\US STOCKS\\TSCO',
    full_name: 'TSCO',
    description: 'Tractor Supply Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tractor-supply.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TSLA: {
    symbol: 'TSLA',
    path: 'STOCKS\\US STOCKS\\TSLA',
    full_name: 'TSLA',
    description: 'Tesla Motors',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tesla.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TSM: {
    symbol: 'TSM',
    path: 'STOCKS\\US STOCKS\\TSM',
    full_name: 'TSM',
    description: 'TSMC / Taiwan Semiconductor Man Co (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/taiwan-semiconductor.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TSN: {
    symbol: 'TSN',
    path: 'STOCKS\\US STOCKS\\TSN',
    full_name: 'TSN',
    description: 'Tyson Foods Inc-Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tyson-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TTC: {
    symbol: 'TTC',
    path: 'STOCKS\\US STOCKS\\TTC',
    full_name: 'TTC',
    description: 'Toro Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/toro-company-the.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TTD: {
    symbol: 'TTD',
    path: 'STOCKS\\US STOCKS\\TTD',
    full_name: 'TTD',
    description: 'The Trade Desk Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/the-trade-desk.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TTE: {
    symbol: 'TTE',
    path: 'STOCKS\\US STOCKS\\TTE',
    full_name: 'TTE',
    description: 'TotalEnergies SE (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/total.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TTWO: {
    symbol: 'TTWO',
    path: 'STOCKS\\US STOCKS\\TTWO',
    full_name: 'TTWO',
    description: 'Take-Two Interactive SoftwareInc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/take-two-interactive-software.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TUP: {
    symbol: 'TUP',
    path: 'STOCKS\\US STOCKS\\TUP',
    full_name: 'TUP',
    description: 'Tupperware Brands Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tupperware-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TWLO: {
    symbol: 'TWLO',
    path: 'STOCKS\\US STOCKS\\TWLO',
    full_name: 'TWLO',
    description: 'Twilio Inc - A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/twilio.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TWOU: {
    symbol: 'TWOU',
    path: 'STOCKS\\US STOCKS\\TWOU',
    full_name: 'TWOU',
    description: '2U Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/2u.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TXN: {
    symbol: 'TXN',
    path: 'STOCKS\\US STOCKS\\TXN',
    full_name: 'TXN',
    description: 'Texas Instruments Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/texas-instruments.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TXRH: {
    symbol: 'TXRH',
    path: 'STOCKS\\US STOCKS\\TXRH',
    full_name: 'TXRH',
    description: 'Texas Roadhouse Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/texas-roadhouse.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  TXT: {
    symbol: 'TXT',
    path: 'STOCKS\\US STOCKS\\TXT',
    full_name: 'TXT',
    description: 'Textron Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/textron.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  U: {
    symbol: 'U',
    path: 'STOCKS\\US STOCKS\\U',
    full_name: 'U',
    description: 'Unity Software Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unity.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UA: {
    symbol: 'UA',
    path: 'STOCKS\\US STOCKS\\UA',
    full_name: 'UA',
    description: 'Under Armour Inc - Class C',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/under-armour.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UAA: {
    symbol: 'UAA',
    path: 'STOCKS\\US STOCKS\\UAA',
    full_name: 'UAA',
    description: 'Under Armour Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/under-armour.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UAL: {
    symbol: 'UAL',
    path: 'STOCKS\\US STOCKS\\UAL',
    full_name: 'UAL',
    description: 'United Airlines Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-airlines.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UBER: {
    symbol: 'UBER',
    path: 'STOCKS\\US STOCKS\\UBER',
    full_name: 'UBER',
    description: 'Uber Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/uber.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UBS: {
    symbol: 'UBS',
    path: 'STOCKS\\US STOCKS\\UBS',
    full_name: 'UBS',
    description: 'UBS Group AG (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ubs-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UDR: {
    symbol: 'UDR',
    path: 'STOCKS\\US STOCKS\\UDR',
    full_name: 'UDR',
    description: 'UDR Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/udr.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UI: {
    symbol: 'UI',
    path: 'STOCKS\\US STOCKS\\UI',
    full_name: 'UI',
    description: 'Ubiquiti Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ubiquiti-networks.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ULTA: {
    symbol: 'ULTA',
    path: 'STOCKS\\US STOCKS\\ULTA',
    full_name: 'ULTA',
    description: 'Ulta Beauty Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ulta-beauty.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UMC: {
    symbol: 'UMC',
    path: 'STOCKS\\US STOCKS\\UMC',
    full_name: 'UMC',
    description: 'United Microelectronics Corp (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-micro-electronics.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UNFI: {
    symbol: 'UNFI',
    path: 'STOCKS\\US STOCKS\\UNFI',
    full_name: 'UNFI',
    description: 'United Natural Foods Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-natural-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UNH: {
    symbol: 'UNH',
    path: 'STOCKS\\US STOCKS\\UNH',
    full_name: 'UNH',
    description: 'UnitedHealth Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unitedhealth.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UNM: {
    symbol: 'UNM',
    path: 'STOCKS\\US STOCKS\\UNM',
    full_name: 'UNM',
    description: 'Unum Group',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unum-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UNP: {
    symbol: 'UNP',
    path: 'STOCKS\\US STOCKS\\UNP',
    full_name: 'UNP',
    description: 'Union Pacific Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/union-pacific.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UPS: {
    symbol: 'UPS',
    path: 'STOCKS\\US STOCKS\\UPS',
    full_name: 'UPS',
    description: 'United Parcel Service Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-parcel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UPST: {
    symbol: 'UPST',
    path: 'STOCKS\\US STOCKS\\UPST',
    full_name: 'UPST',
    description: 'Upstart Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/upstart.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UPWK: {
    symbol: 'UPWK',
    path: 'STOCKS\\US STOCKS\\UPWK',
    full_name: 'UPWK',
    description: 'Upwork Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/upwork.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  URBN: {
    symbol: 'URBN',
    path: 'STOCKS\\US STOCKS\\URBN',
    full_name: 'URBN',
    description: 'Urban Outfitters Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/urban-outfitters.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  URI: {
    symbol: 'URI',
    path: 'STOCKS\\US STOCKS\\URI',
    full_name: 'URI',
    description: 'United Rentals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-rentals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  USB: {
    symbol: 'USB',
    path: 'STOCKS\\US STOCKS\\USB',
    full_name: 'USB',
    description: 'U.S. Bancorp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/us-bancorp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  USFD: {
    symbol: 'USFD',
    path: 'STOCKS\\US STOCKS\\USFD',
    full_name: 'USFD',
    description: 'US Foods Holding Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/us-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  V: {
    symbol: 'V',
    path: 'STOCKS\\US STOCKS\\V',
    full_name: 'V',
    description: 'Visa',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/visa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VALE: {
    symbol: 'VALE',
    path: 'STOCKS\\US STOCKS\\VALE',
    full_name: 'VALE',
    description: 'Vale Sa-Sp Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vale.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VEON: {
    symbol: 'VEON',
    path: 'STOCKS\\US STOCKS\\VEON',
    full_name: 'VEON',
    description: 'VEON Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/veon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VFC: {
    symbol: 'VFC',
    path: 'STOCKS\\US STOCKS\\VFC',
    full_name: 'VFC',
    description: 'VF Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/v-f.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VFS: {
    symbol: 'VFS',
    path: 'STOCKS\\US STOCKS\\VFS',
    full_name: 'VFS',
    description: 'VFS Auto',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vinfast-auto.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VIAV: {
    symbol: 'VIAV',
    path: 'STOCKS\\US STOCKS\\VIAV',
    full_name: 'VIAV',
    description: 'VIAVI Solutions Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/viavi-solutions.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VIPS: {
    symbol: 'VIPS',
    path: 'STOCKS\\US STOCKS\\VIPS',
    full_name: 'VIPS',
    description: 'Vipshop Holdings Ltd (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vipshop.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VIR: {
    symbol: 'VIR',
    path: 'STOCKS\\US STOCKS\\VIR',
    full_name: 'VIR',
    description: 'Vir Biotechnology Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vir-biotechnology.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VIRT: {
    symbol: 'VIRT',
    path: 'STOCKS\\US STOCKS\\VIRT',
    full_name: 'VIRT',
    description: 'Virtu Financial Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/virtu-financial.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VLO: {
    symbol: 'VLO',
    path: 'STOCKS\\US STOCKS\\VLO',
    full_name: 'VLO',
    description: 'Valero Energy Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/valero-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VMC: {
    symbol: 'VMC',
    path: 'STOCKS\\US STOCKS\\VMC',
    full_name: 'VMC',
    description: 'Vulcan Materials Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vulcan-materials.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VMI: {
    symbol: 'VMI',
    path: 'STOCKS\\US STOCKS\\VMI',
    full_name: 'VMI',
    description: 'Valmont Industries',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/valmont-industries.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VOD: {
    symbol: 'VOD',
    path: 'STOCKS\\US STOCKS\\VOD',
    full_name: 'VOD',
    description: 'Vodafone Group Plc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vodafone.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VRSN: {
    symbol: 'VRSN',
    path: 'STOCKS\\US STOCKS\\VRSN',
    full_name: 'VRSN',
    description: 'Verisign Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/verisign.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VRTX: {
    symbol: 'VRTX',
    path: 'STOCKS\\US STOCKS\\VRTX',
    full_name: 'VRTX',
    description: 'Vertex Pharmaceuticals Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vertex-pharmaceutical.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VTLE: {
    symbol: 'VTLE',
    path: 'STOCKS\\US STOCKS\\VTLE',
    full_name: 'VTLE',
    description: 'Vital Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/laredo-petroleum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VTR: {
    symbol: 'VTR',
    path: 'STOCKS\\US STOCKS\\VTR',
    full_name: 'VTR',
    description: 'Ventas Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ventas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VTRS: {
    symbol: 'VTRS',
    path: 'STOCKS\\US STOCKS\\VTRS',
    full_name: 'VTRS',
    description: 'Viatris Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/viatris.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VYX: {
    symbol: 'VYX',
    path: 'STOCKS\\US STOCKS\\VYX',
    full_name: 'VYX',
    description: 'NCR Voyix Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ncr.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  VZ: {
    symbol: 'VZ',
    path: 'STOCKS\\US STOCKS\\VZ',
    full_name: 'VZ',
    description: 'Verizon Communications Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/verizon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  W: {
    symbol: 'W',
    path: 'STOCKS\\US STOCKS\\W',
    full_name: 'W',
    description: 'Wayfair Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wayfair.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WAT: {
    symbol: 'WAT',
    path: 'STOCKS\\US STOCKS\\WAT',
    full_name: 'WAT',
    description: 'Waters Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/waters.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WB: {
    symbol: 'WB',
    path: 'STOCKS\\US STOCKS\\WB',
    full_name: 'WB',
    description: 'Weibo Corp-Spon Adr',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/weibo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WBA: {
    symbol: 'WBA',
    path: 'STOCKS\\US STOCKS\\WBA',
    full_name: 'WBA',
    description: 'Walgreens Boots Alliance Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/walgreens-boots-alliance.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WCC: {
    symbol: 'WCC',
    path: 'STOCKS\\US STOCKS\\WCC',
    full_name: 'WCC',
    description: 'WESCO International Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wesco-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WDAY: {
    symbol: 'WDAY',
    path: 'STOCKS\\US STOCKS\\WDAY',
    full_name: 'WDAY',
    description: 'Workday Inc - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/workday.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WDC: {
    symbol: 'WDC',
    path: 'STOCKS\\US STOCKS\\WDC',
    full_name: 'WDC',
    description: 'Western Digital Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/western-digital.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WEC: {
    symbol: 'WEC',
    path: 'STOCKS\\US STOCKS\\WEC',
    full_name: 'WEC',
    description: 'WEC Energy Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wec-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WEN: {
    symbol: 'WEN',
    path: 'STOCKS\\US STOCKS\\WEN',
    full_name: 'WEN',
    description: "Wendy'S Co/The",
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wendys-company.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WFC: {
    symbol: 'WFC',
    path: 'STOCKS\\US STOCKS\\WFC',
    full_name: 'WFC',
    description: 'Wells Fargo & Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wells-fargo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WHR: {
    symbol: 'WHR',
    path: 'STOCKS\\US STOCKS\\WHR',
    full_name: 'WHR',
    description: 'Whirlpool Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/whirlpool.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WIX: {
    symbol: 'WIX',
    path: 'STOCKS\\US STOCKS\\WIX',
    full_name: 'WIX',
    description: 'Wix.com Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wix.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WKC: {
    symbol: 'WKC',
    path: 'STOCKS\\US STOCKS\\WKC',
    full_name: 'WKC',
    description: 'World Fuel Services Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/world-fuel-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WKHS: {
    symbol: 'WKHS',
    path: 'STOCKS\\US STOCKS\\WKHS',
    full_name: 'WKHS',
    description: 'Workhorse Group Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/workhorse.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WLK: {
    symbol: 'WLK',
    path: 'STOCKS\\US STOCKS\\WLK',
    full_name: 'WLK',
    description: 'Westlake Chemical Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/westlake-chemical.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WM: {
    symbol: 'WM',
    path: 'STOCKS\\US STOCKS\\WM',
    full_name: 'WM',
    description: 'Waste Management Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/waste-management.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WMB: {
    symbol: 'WMB',
    path: 'STOCKS\\US STOCKS\\WMB',
    full_name: 'WMB',
    description: 'Williams Cos Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/williams.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WMG: {
    symbol: 'WMG',
    path: 'STOCKS\\US STOCKS\\WMG',
    full_name: 'WMG',
    description: 'Warner Music Group Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wmg.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WMS: {
    symbol: 'WMS',
    path: 'STOCKS\\US STOCKS\\WMS',
    full_name: 'WMS',
    description: 'Advanced Drainage Systems Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/advanced-drainage-system.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WMT: {
    symbol: 'WMT',
    path: 'STOCKS\\US STOCKS\\WMT',
    full_name: 'WMT',
    description: 'Walmart Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/walmart.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WPC: {
    symbol: 'WPC',
    path: 'STOCKS\\US STOCKS\\WPC',
    full_name: 'WPC',
    description: 'W.P. Carey Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/w-p-carey.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WPM: {
    symbol: 'WPM',
    path: 'STOCKS\\US STOCKS\\WPM',
    full_name: 'WPM',
    description: 'Wheaton Precious Metals Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wheaton-precious-metals.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WRB: {
    symbol: 'WRB',
    path: 'STOCKS\\US STOCKS\\WRB',
    full_name: 'WRB',
    description: 'W.R. Berkley Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/berkley-w-r-corp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WSM: {
    symbol: 'WSM',
    path: 'STOCKS\\US STOCKS\\WSM',
    full_name: 'WSM',
    description: 'Williams-Sonoma Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/williams-sonoma.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WTW: {
    symbol: 'WTW',
    path: 'STOCKS\\US STOCKS\\WTW',
    full_name: 'WTW',
    description: 'Willis Towers Watson PLC',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/willis-towers-watson.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WU: {
    symbol: 'WU',
    path: 'STOCKS\\US STOCKS\\WU',
    full_name: 'WU',
    description: 'Western Union Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/western-union-company.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WVE: {
    symbol: 'WVE',
    path: 'STOCKS\\US STOCKS\\WVE',
    full_name: 'WVE',
    description: 'Wave Life Sciences Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wave-life-sciences.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WY: {
    symbol: 'WY',
    path: 'STOCKS\\US STOCKS\\WY',
    full_name: 'WY',
    description: 'Weyerhauser Co',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/weyerhaeuserdrn.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  WYNN: {
    symbol: 'WYNN',
    path: 'STOCKS\\US STOCKS\\WYNN',
    full_name: 'WYNN',
    description: 'Wynn Resorts Ltd',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wynn-resorts.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  X: {
    symbol: 'X',
    path: 'STOCKS\\US STOCKS\\X',
    full_name: 'X',
    description: 'United States Steel Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-states-steel.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XEL: {
    symbol: 'XEL',
    path: 'STOCKS\\US STOCKS\\XEL',
    full_name: 'XEL',
    description: 'Xcel Energy Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/xcel-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XOM: {
    symbol: 'XOM',
    path: 'STOCKS\\US STOCKS\\XOM',
    full_name: 'XOM',
    description: 'Exxon Mobil',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/exxon.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XPEV: {
    symbol: 'XPEV',
    path: 'STOCKS\\US STOCKS\\XPEV',
    full_name: 'XPEV',
    description: 'XPeng Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/xpeng.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XRAY: {
    symbol: 'XRAY',
    path: 'STOCKS\\US STOCKS\\XRAY',
    full_name: 'XRAY',
    description: 'Dentsply Sirona Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dentsply-sirona.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  XRX: {
    symbol: 'XRX',
    path: 'STOCKS\\US STOCKS\\XRX',
    full_name: 'XRX',
    description: 'Xerox Holdings Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/xerox.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  YELP: {
    symbol: 'YELP',
    path: 'STOCKS\\US STOCKS\\YELP',
    full_name: 'YELP',
    description: 'Yelp Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/yelp.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  YUM: {
    symbol: 'YUM',
    path: 'STOCKS\\US STOCKS\\YUM',
    full_name: 'YUM',
    description: 'Yum! Brands Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/yum-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  YUMC: {
    symbol: 'YUMC',
    path: 'STOCKS\\US STOCKS\\YUMC',
    full_name: 'YUMC',
    description: 'Yum China Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/yum-china-holdings.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  YY: {
    symbol: 'YY',
    path: 'STOCKS\\US STOCKS\\YY',
    full_name: 'YY',
    description: 'Joyy Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/yy-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  Z: {
    symbol: 'Z',
    path: 'STOCKS\\US STOCKS\\Z',
    full_name: 'Z',
    description: 'Zillow Group Inc - C',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zillow-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZBH: {
    symbol: 'ZBH',
    path: 'STOCKS\\US STOCKS\\ZBH',
    full_name: 'ZBH',
    description: 'Zimmer Biomet Holdings Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zimmer-biomet.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZBRA: {
    symbol: 'ZBRA',
    path: 'STOCKS\\US STOCKS\\ZBRA',
    full_name: 'ZBRA',
    description: 'Zebra Technologies Corp - Class A',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zebra.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZI: {
    symbol: 'ZI',
    path: 'STOCKS\\US STOCKS\\ZI',
    full_name: 'ZI',
    description: 'ZoomInfo Technologies Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zoominfo.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZION: {
    symbol: 'ZION',
    path: 'STOCKS\\US STOCKS\\ZION',
    full_name: 'ZION',
    description: 'Zions Bancorp NA',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zions-bancorporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZM: {
    symbol: 'ZM',
    path: 'STOCKS\\US STOCKS\\ZM',
    full_name: 'ZM',
    description: 'Zoom Video Communications Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zoom-video-communications.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZOM: {
    symbol: 'ZOM',
    path: 'STOCKS\\US STOCKS\\ZOM',
    full_name: 'ZOM',
    description: 'Zomedica Corp',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zomedica.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZS: {
    symbol: 'ZS',
    path: 'STOCKS\\US STOCKS\\ZS',
    full_name: 'ZS',
    description: 'Zscaler Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commodity/soybean.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZTO: {
    symbol: 'ZTO',
    path: 'STOCKS\\US STOCKS\\ZTO',
    full_name: 'ZTO',
    description: 'Zto Express Cayman Inc (ADRs)',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zto-express-cayman.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  ZTS: {
    symbol: 'ZTS',
    path: 'STOCKS\\US STOCKS\\ZTS',
    full_name: 'ZTS',
    description: 'Zoetis Inc',
    type: 'us stocks',
    session: '1;1330-2000:2|1330-2000:3|1330-2000:4|1330-2000:5|1330-2000:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zoetis.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
};

export const hk_stocks = {
  '1.xhkg': {
    symbol: '1.xhkg',
    path: 'STOCKS\\HK STOCKS\\1.xhkg',
    full_name: '1.xhkg',
    description: 'CK Hutchison Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ck-hutchison-holding.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '101.xhkg': {
    symbol: '101.xhkg',
    path: 'STOCKS\\HK STOCKS\\101.xhkg',
    full_name: '101.xhkg',
    description: 'Hang Lung Properties Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hang-lung-properties-hlp.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1024.xhkg': {
    symbol: '1024.xhkg',
    path: 'STOCKS\\HK STOCKS\\1024.xhkg',
    full_name: '1024.xhkg',
    description: 'Kuaishou Technology',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kuaishou-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1038.xhkg': {
    symbol: '1038.xhkg',
    path: 'STOCKS\\HK STOCKS\\1038.xhkg',
    full_name: '1038.xhkg',
    description: 'CK Infrastructure Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ck-infrastructure-holdings-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1044.xhkg': {
    symbol: '1044.xhkg',
    path: 'STOCKS\\HK STOCKS\\1044.xhkg',
    full_name: '1044.xhkg',
    description: 'Hengan Intl Group Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hengan-international-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1055.xhkg': {
    symbol: '1055.xhkg',
    path: 'STOCKS\\HK STOCKS\\1055.xhkg',
    full_name: '1055.xhkg',
    description: 'China Southern Airlines Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-southern-airlines.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1088.xhkg': {
    symbol: '1088.xhkg',
    path: 'STOCKS\\HK STOCKS\\1088.xhkg',
    full_name: '1088.xhkg',
    description: 'China Shenhua Energy Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-shenhua.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1093.xhkg': {
    symbol: '1093.xhkg',
    path: 'STOCKS\\HK STOCKS\\1093.xhkg',
    full_name: '1093.xhkg',
    description: 'CSPC Pharmaceutical Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cspc-pharma.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '11.xhkg': {
    symbol: '11.xhkg',
    path: 'STOCKS\\HK STOCKS\\11.xhkg',
    full_name: '11.xhkg',
    description: 'Hang Seng Bank Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hang-seng-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1109.xhkg': {
    symbol: '1109.xhkg',
    path: 'STOCKS\\HK STOCKS\\1109.xhkg',
    full_name: '1109.xhkg',
    description: 'China Resources Land Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-resources-land.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1113.xhkg': {
    symbol: '1113.xhkg',
    path: 'STOCKS\\HK STOCKS\\1113.xhkg',
    full_name: '1113.xhkg',
    description: 'CK Asset Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ck-asset-holdings-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1128.xhkg': {
    symbol: '1128.xhkg',
    path: 'STOCKS\\HK STOCKS\\1128.xhkg',
    full_name: '1128.xhkg',
    description: 'Wynn Macau Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wynn-macau-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1177.xhkg': {
    symbol: '1177.xhkg',
    path: 'STOCKS\\HK STOCKS\\1177.xhkg',
    full_name: '1177.xhkg',
    description: 'Sino Biopharmaceutical Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sino-biopharm.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1186.xhkg': {
    symbol: '1186.xhkg',
    path: 'STOCKS\\HK STOCKS\\1186.xhkg',
    full_name: '1186.xhkg',
    description: 'China Railway Construction Corp Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-railway-construction.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1193.xhkg': {
    symbol: '1193.xhkg',
    path: 'STOCKS\\HK STOCKS\\1193.xhkg',
    full_name: '1193.xhkg',
    description: 'China Resources Gas Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-resources-gas-group-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '12.xhkg': {
    symbol: '12.xhkg',
    path: 'STOCKS\\HK STOCKS\\12.xhkg',
    full_name: '12.xhkg',
    description: 'Henderson Land Development Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/henderson-land-development-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1211.xhkg': {
    symbol: '1211.xhkg',
    path: 'STOCKS\\HK STOCKS\\1211.xhkg',
    full_name: '1211.xhkg',
    description: 'BYD Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/byd-electronic.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1288.xhkg': {
    symbol: '1288.xhkg',
    path: 'STOCKS\\HK STOCKS\\1288.xhkg',
    full_name: '1288.xhkg',
    description: 'Agricultural Bank Of China Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/agricultural-bank-of-china.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1299.xhkg': {
    symbol: '1299.xhkg',
    path: 'STOCKS\\HK STOCKS\\1299.xhkg',
    full_name: '1299.xhkg',
    description: 'AIA Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aia.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1339.xhkg': {
    symbol: '1339.xhkg',
    path: 'STOCKS\\HK STOCKS\\1339.xhkg',
    full_name: '1339.xhkg',
    description: "People's Insurance Co Group of China Ltd",
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/the-people-s-insurance-company-of-china-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '135.xhkg': {
    symbol: '135.xhkg',
    path: 'STOCKS\\HK STOCKS\\135.xhkg',
    full_name: '135.xhkg',
    description: 'Kunlun Energy Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kunlun-energy-company-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1398.xhkg': {
    symbol: '1398.xhkg',
    path: 'STOCKS\\HK STOCKS\\1398.xhkg',
    full_name: '1398.xhkg',
    description: 'Ind & Comm Bank of China Ltd / ICBC',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/industrial-and-commercial-bank-of-china.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '144.xhkg': {
    symbol: '144.xhkg',
    path: 'STOCKS\\HK STOCKS\\144.xhkg',
    full_name: '144.xhkg',
    description: 'China Merchants Port Holding Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-merchants-port-hldgs-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '151.xhkg': {
    symbol: '151.xhkg',
    path: 'STOCKS\\HK STOCKS\\151.xhkg',
    full_name: '151.xhkg',
    description: 'Want Want China Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/want-want-china-holdings-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '16.xhkg': {
    symbol: '16.xhkg',
    path: 'STOCKS\\HK STOCKS\\16.xhkg',
    full_name: '16.xhkg',
    description: 'Sun Hung Kai Properties Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sun-hung-kai-properties-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1658.xhkg': {
    symbol: '1658.xhkg',
    path: 'STOCKS\\HK STOCKS\\1658.xhkg',
    full_name: '1658.xhkg',
    description: 'Postal Savings Bank Of China',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ishares.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '168.xhkg': {
    symbol: '168.xhkg',
    path: 'STOCKS\\HK STOCKS\\168.xhkg',
    full_name: '168.xhkg',
    description: 'Tsingtao Brewery Co Ltd - Class H',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tsingtao-brew.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '17.xhkg': {
    symbol: '17.xhkg',
    path: 'STOCKS\\HK STOCKS\\17.xhkg',
    full_name: '17.xhkg',
    description: 'New World Development Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/new-world-development-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '175.xhkg': {
    symbol: '175.xhkg',
    path: 'STOCKS\\HK STOCKS\\175.xhkg',
    full_name: '175.xhkg',
    description: 'Geely Automobile Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/geely-auto.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1800.xhkg': {
    symbol: '1800.xhkg',
    path: 'STOCKS\\HK STOCKS\\1800.xhkg',
    full_name: '1800.xhkg',
    description: 'China Communications Construction Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-communications-construction-coltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1810.xhkg': {
    symbol: '1810.xhkg',
    path: 'STOCKS\\HK STOCKS\\1810.xhkg',
    full_name: '1810.xhkg',
    description: 'Xiaomi Corp',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/xiaomi.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1816.xhkg': {
    symbol: '1816.xhkg',
    path: 'STOCKS\\HK STOCKS\\1816.xhkg',
    full_name: '1816.xhkg',
    description: 'CGN Power Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cgn-power-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1876.xhkg': {
    symbol: '1876.xhkg',
    path: 'STOCKS\\HK STOCKS\\1876.xhkg',
    full_name: '1876.xhkg',
    description: 'Budweiser Brewing Co APAC Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/budweiser-brewing-company-apac-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1898.xhkg': {
    symbol: '1898.xhkg',
    path: 'STOCKS\\HK STOCKS\\1898.xhkg',
    full_name: '1898.xhkg',
    description: 'China Coal Energy Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-coal-energy-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '19.xhkg': {
    symbol: '19.xhkg',
    path: 'STOCKS\\HK STOCKS\\19.xhkg',
    full_name: '19.xhkg',
    description: 'Swire Pacific Ltd - Class A',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/swire-pacific.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1928.xhkg': {
    symbol: '1928.xhkg',
    path: 'STOCKS\\HK STOCKS\\1928.xhkg',
    full_name: '1928.xhkg',
    description: 'Sands China Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sekisui-house.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1972.xhkg': {
    symbol: '1972.xhkg',
    path: 'STOCKS\\HK STOCKS\\1972.xhkg',
    full_name: '1972.xhkg',
    description: 'Swire Properties Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/swire-properties-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '1997.xhkg': {
    symbol: '1997.xhkg',
    path: 'STOCKS\\HK STOCKS\\1997.xhkg',
    full_name: '1997.xhkg',
    description: 'Wharf Real Estate Investment Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wharf-real-estate-investment-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2.xhkg': {
    symbol: '2.xhkg',
    path: 'STOCKS\\HK STOCKS\\2.xhkg',
    full_name: '2.xhkg',
    description: 'CLP Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/clp-holdings-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2007.xhkg': {
    symbol: '2007.xhkg',
    path: 'STOCKS\\HK STOCKS\\2007.xhkg',
    full_name: '2007.xhkg',
    description: 'Country Garden Holdings Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country-garden.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2018.xhkg': {
    symbol: '2018.xhkg',
    path: 'STOCKS\\HK STOCKS\\2018.xhkg',
    full_name: '2018.xhkg',
    description: 'AAC Technologies Holdings Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/global-x.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2020.xhkg': {
    symbol: '2020.xhkg',
    path: 'STOCKS\\HK STOCKS\\2020.xhkg',
    full_name: '2020.xhkg',
    description: 'Anta Sports Products Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sabic-agri-nutrients.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2202.xhkg': {
    symbol: '2202.xhkg',
    path: 'STOCKS\\HK STOCKS\\2202.xhkg',
    full_name: '2202.xhkg',
    description: 'China Vanke Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-vanke-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2238.xhkg': {
    symbol: '2238.xhkg',
    path: 'STOCKS\\HK STOCKS\\2238.xhkg',
    full_name: '2238.xhkg',
    description: 'Guangzhou Automobile Group / GAC',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/daiwa-am-ifreeetf-nikkei225.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2269.xhkg': {
    symbol: '2269.xhkg',
    path: 'STOCKS\\HK STOCKS\\2269.xhkg',
    full_name: '2269.xhkg',
    description: 'WuXi Biologics Cayman Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wuxi-bio.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '23.xhkg': {
    symbol: '23.xhkg',
    path: 'STOCKS\\HK STOCKS\\23.xhkg',
    full_name: '23.xhkg',
    description: 'Bank of East Asia Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diesel.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2313.xhkg': {
    symbol: '2313.xhkg',
    path: 'STOCKS\\HK STOCKS\\2313.xhkg',
    full_name: '2313.xhkg',
    description: 'Shenzhou International Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/compeq-manufacturing.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2318.xhkg': {
    symbol: '2318.xhkg',
    path: 'STOCKS\\HK STOCKS\\2318.xhkg',
    full_name: '2318.xhkg',
    description: 'Ping An Insurance Group Co of China Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ping-an.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2319.xhkg': {
    symbol: '2319.xhkg',
    path: 'STOCKS\\HK STOCKS\\2319.xhkg',
    full_name: '2319.xhkg',
    description: 'China Mengniu Dairy Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mengniu-dairy.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2328.xhkg': {
    symbol: '2328.xhkg',
    path: 'STOCKS\\HK STOCKS\\2328.xhkg',
    full_name: '2328.xhkg',
    description: 'PICC Property & Casualty Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/picc-property-and-casualty-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2338.xhkg': {
    symbol: '2338.xhkg',
    path: 'STOCKS\\HK STOCKS\\2338.xhkg',
    full_name: '2338.xhkg',
    description: 'Weichai Power Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/weichai-power-company-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2382.xhkg': {
    symbol: '2382.xhkg',
    path: 'STOCKS\\HK STOCKS\\2382.xhkg',
    full_name: '2382.xhkg',
    description: 'Sunny Optical Technology Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/quanta-computer.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2388.xhkg': {
    symbol: '2388.xhkg',
    path: 'STOCKS\\HK STOCKS\\2388.xhkg',
    full_name: '2388.xhkg',
    description: 'BOC Hong Kong Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/boc-hong-kong-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '241.xhkg': {
    symbol: '241.xhkg',
    path: 'STOCKS\\HK STOCKS\\241.xhkg',
    full_name: '241.xhkg',
    description: 'Alibaba Health Information Tech Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ali-health.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2628.xhkg': {
    symbol: '2628.xhkg',
    path: 'STOCKS\\HK STOCKS\\2628.xhkg',
    full_name: '2628.xhkg',
    description: 'China Life Insurance Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/daiwa-am-ifreeetf-nikkei225.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '267.xhkg': {
    symbol: '267.xhkg',
    path: 'STOCKS\\HK STOCKS\\267.xhkg',
    full_name: '267.xhkg',
    description: 'CITIC Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/citic-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '2688.xhkg': {
    symbol: '2688.xhkg',
    path: 'STOCKS\\HK STOCKS\\2688.xhkg',
    full_name: '2688.xhkg',
    description: 'ENN Energy Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enn-energy-holdings-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '27.xhkg': {
    symbol: '27.xhkg',
    path: 'STOCKS\\HK STOCKS\\27.xhkg',
    full_name: '27.xhkg',
    description: 'Galaxy Entertainment Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gasoline.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '270.xhkg': {
    symbol: '270.xhkg',
    path: 'STOCKS\\HK STOCKS\\270.xhkg',
    full_name: '270.xhkg',
    description: 'Guangdong Investment Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/guangdong-investment.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '272.xhkg': {
    symbol: '272.xhkg',
    path: 'STOCKS\\HK STOCKS\\272.xhkg',
    full_name: '272.xhkg',
    description: 'Shui On Land Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/shui-on-land-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '288.xhkg': {
    symbol: '288.xhkg',
    path: 'STOCKS\\HK STOCKS\\288.xhkg',
    full_name: '288.xhkg',
    description: 'WH Group Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/veritiv.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '291.xhkg': {
    symbol: '291.xhkg',
    path: 'STOCKS\\HK STOCKS\\291.xhkg',
    full_name: '291.xhkg',
    description: 'China Resources Beer Holding Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-resources-beer-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '293.xhkg': {
    symbol: '293.xhkg',
    path: 'STOCKS\\HK STOCKS\\293.xhkg',
    full_name: '293.xhkg',
    description: 'Cathay Pacific Airways Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cathay-pacific.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3.xhkg': {
    symbol: '3.xhkg',
    path: 'STOCKS\\HK STOCKS\\3.xhkg',
    full_name: '3.xhkg',
    description: 'Hong Kong & China Gas Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hk-and-china-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '322.xhkg': {
    symbol: '322.xhkg',
    path: 'STOCKS\\HK STOCKS\\322.xhkg',
    full_name: '322.xhkg',
    description: 'Tingyi (Cayman Isln) Hldg Corp',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tingyi-cayman-islands-hldg-corp.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '330.xhkg': {
    symbol: '330.xhkg',
    path: 'STOCKS\\HK STOCKS\\330.xhkg',
    full_name: '330.xhkg',
    description: 'Esprit Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/esprit-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3328.xhkg': {
    symbol: '3328.xhkg',
    path: 'STOCKS\\HK STOCKS\\3328.xhkg',
    full_name: '3328.xhkg',
    description: 'Bank of Communications Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-communications.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3333.xhkg': {
    symbol: '3333.xhkg',
    path: 'STOCKS\\HK STOCKS\\3333.xhkg',
    full_name: '3333.xhkg',
    description: 'China Evergrande Group',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/evergrande.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '336.xhkg': {
    symbol: '336.xhkg',
    path: 'STOCKS\\HK STOCKS\\336.xhkg',
    full_name: '336.xhkg',
    description: 'Huabao International Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hepion-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '358.xhkg': {
    symbol: '358.xhkg',
    path: 'STOCKS\\HK STOCKS\\358.xhkg',
    full_name: '358.xhkg',
    description: 'Jiangxi Copper Co Ltd - Class H',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jiangxi-copper-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3618.xhkg': {
    symbol: '3618.xhkg',
    path: 'STOCKS\\HK STOCKS\\3618.xhkg',
    full_name: '3618.xhkg',
    description: 'Chongqing Rural Commercial Bank Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chongqing-rural-commercial-bank-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '363.xhkg': {
    symbol: '363.xhkg',
    path: 'STOCKS\\HK STOCKS\\363.xhkg',
    full_name: '363.xhkg',
    description: 'Shanghai Industrial Hldg Ltd / SIHL',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/shanghai-industrial-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3690.xhkg': {
    symbol: '3690.xhkg',
    path: 'STOCKS\\HK STOCKS\\3690.xhkg',
    full_name: '3690.xhkg',
    description: 'Meituan Dianping - Class B',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/meituan.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3692.xhkg': {
    symbol: '3692.xhkg',
    path: 'STOCKS\\HK STOCKS\\3692.xhkg',
    full_name: '3692.xhkg',
    description: 'Hansoh Pharmaceutical Group Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hansoh-pharmaceutical-group-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '384.xhkg': {
    symbol: '384.xhkg',
    path: 'STOCKS\\HK STOCKS\\384.xhkg',
    full_name: '384.xhkg',
    description: 'China Gas Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '386.xhkg': {
    symbol: '386.xhkg',
    path: 'STOCKS\\HK STOCKS\\386.xhkg',
    full_name: '386.xhkg',
    description: 'China Petroleum & Chemical / Sinopec',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-petroleum-and-chemical.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '388.xhkg': {
    symbol: '388.xhkg',
    path: 'STOCKS\\HK STOCKS\\388.xhkg',
    full_name: '388.xhkg',
    description: 'Hong Kong Exchanges & Clearing Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hkex.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '390.xhkg': {
    symbol: '390.xhkg',
    path: 'STOCKS\\HK STOCKS\\390.xhkg',
    full_name: '390.xhkg',
    description: 'China Railway Group Ltd / CREC',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-railway-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '392.xhkg': {
    symbol: '392.xhkg',
    path: 'STOCKS\\HK STOCKS\\392.xhkg',
    full_name: '392.xhkg',
    description: 'Beijing Enterprises Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/beijing-enterprise-hldgs.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '3988.xhkg': {
    symbol: '3988.xhkg',
    path: 'STOCKS\\HK STOCKS\\3988.xhkg',
    full_name: '3988.xhkg',
    description: 'Bank of China Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bank-of-china.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '4.xhkg': {
    symbol: '4.xhkg',
    path: 'STOCKS\\HK STOCKS\\4.xhkg',
    full_name: '4.xhkg',
    description: 'Wharf Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wharf-hldgs.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '410.xhkg': {
    symbol: '410.xhkg',
    path: 'STOCKS\\HK STOCKS\\410.xhkg',
    full_name: '410.xhkg',
    description: 'Soho China Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/soho-china.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '489.xhkg': {
    symbol: '489.xhkg',
    path: 'STOCKS\\HK STOCKS\\489.xhkg',
    full_name: '489.xhkg',
    description: 'Dongfeng Motor Group Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dongfeng-motor-group-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '5.xhkg': {
    symbol: '5.xhkg',
    path: 'STOCKS\\HK STOCKS\\5.xhkg',
    full_name: '5.xhkg',
    description: 'HSBC Holdings Plc [HKEX]',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hsbc.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6.xhkg': {
    symbol: '6.xhkg',
    path: 'STOCKS\\HK STOCKS\\6.xhkg',
    full_name: '6.xhkg',
    description: 'Power Assets Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/power-assets-holdings-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6066.xhkg': {
    symbol: '6066.xhkg',
    path: 'STOCKS\\HK STOCKS\\6066.xhkg',
    full_name: '6066.xhkg',
    description: 'CSC Financial Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-securities.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6185.xhkg': {
    symbol: '6185.xhkg',
    path: 'STOCKS\\HK STOCKS\\6185.xhkg',
    full_name: '6185.xhkg',
    description: 'CanSino Biologics Inc  - Class H',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cansino-biologics.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '656.xhkg': {
    symbol: '656.xhkg',
    path: 'STOCKS\\HK STOCKS\\656.xhkg',
    full_name: '656.xhkg',
    description: 'Fosun International Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fosun-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '66.xhkg': {
    symbol: '66.xhkg',
    path: 'STOCKS\\HK STOCKS\\66.xhkg',
    full_name: '66.xhkg',
    description: 'MTR Corp',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mtr.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6618.xhkg': {
    symbol: '6618.xhkg',
    path: 'STOCKS\\HK STOCKS\\6618.xhkg',
    full_name: '6618.xhkg',
    description: 'JD Health International Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jd-health-international-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '669.xhkg': {
    symbol: '669.xhkg',
    path: 'STOCKS\\HK STOCKS\\669.xhkg',
    full_name: '669.xhkg',
    description: 'Techtronic Industries Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/techtronic-ind.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '670.xhkg': {
    symbol: '670.xhkg',
    path: 'STOCKS\\HK STOCKS\\670.xhkg',
    full_name: '670.xhkg',
    description: 'China Eastern Airlines Co',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-eastern-airlines.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6823.xhkg': {
    symbol: '6823.xhkg',
    path: 'STOCKS\\HK STOCKS\\6823.xhkg',
    full_name: '6823.xhkg',
    description: 'HKT Trust And HKT Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hkt-trust-and-hkt-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '683.xhkg': {
    symbol: '683.xhkg',
    path: 'STOCKS\\HK STOCKS\\683.xhkg',
    full_name: '683.xhkg',
    description: 'Kerry Properties Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kerry-ppt.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6862.xhkg': {
    symbol: '6862.xhkg',
    path: 'STOCKS\\HK STOCKS\\6862.xhkg',
    full_name: '6862.xhkg',
    description: 'HaiDiLao International Holding Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/haidilao.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '688.xhkg': {
    symbol: '688.xhkg',
    path: 'STOCKS\\HK STOCKS\\688.xhkg',
    full_name: '688.xhkg',
    description: 'China Overseas Land & Investment Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-overseas-land-and-investmnts.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '6881.xhkg': {
    symbol: '6881.xhkg',
    path: 'STOCKS\\HK STOCKS\\6881.xhkg',
    full_name: '6881.xhkg',
    description: 'China Galaxy Securities Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-galaxy-securities-co-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '700.xhkg': {
    symbol: '700.xhkg',
    path: 'STOCKS\\HK STOCKS\\700.xhkg',
    full_name: '700.xhkg',
    description: 'Tencent Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tencent.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '728.xhkg': {
    symbol: '728.xhkg',
    path: 'STOCKS\\HK STOCKS\\728.xhkg',
    full_name: '728.xhkg',
    description: 'China Telecom Corp Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-telecom.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '762.xhkg': {
    symbol: '762.xhkg',
    path: 'STOCKS\\HK STOCKS\\762.xhkg',
    full_name: '762.xhkg',
    description: 'China Unicom Hong Kong Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-unicom.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '763.xhkg': {
    symbol: '763.xhkg',
    path: 'STOCKS\\HK STOCKS\\763.xhkg',
    full_name: '763.xhkg',
    description: 'ZTE Corp - Class H',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zte.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '788.xhkg': {
    symbol: '788.xhkg',
    path: 'STOCKS\\HK STOCKS\\788.xhkg',
    full_name: '788.xhkg',
    description: 'China Tower Corp Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coupang.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '813.xhkg': {
    symbol: '813.xhkg',
    path: 'STOCKS\\HK STOCKS\\813.xhkg',
    full_name: '813.xhkg',
    description: 'Shimao Property Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/acm-research.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '83.xhkg': {
    symbol: '83.xhkg',
    path: 'STOCKS\\HK STOCKS\\83.xhkg',
    full_name: '83.xhkg',
    description: 'Sino Land Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sino-land-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '836.xhkg': {
    symbol: '836.xhkg',
    path: 'STOCKS\\HK STOCKS\\836.xhkg',
    full_name: '836.xhkg',
    description: 'China Resources Power Holdings Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-resources-power-hldgs-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '857.xhkg': {
    symbol: '857.xhkg',
    path: 'STOCKS\\HK STOCKS\\857.xhkg',
    full_name: '857.xhkg',
    description: 'PetroChina Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/petrochina.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '868.xhkg': {
    symbol: '868.xhkg',
    path: 'STOCKS\\HK STOCKS\\868.xhkg',
    full_name: '868.xhkg',
    description: 'Xinyi Glass Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/xinyi-glass.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '881.xhkg': {
    symbol: '881.xhkg',
    path: 'STOCKS\\HK STOCKS\\881.xhkg',
    full_name: '881.xhkg',
    description: 'Zhongsheng Group Holdings',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zhongsheng-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '883.xhkg': {
    symbol: '883.xhkg',
    path: 'STOCKS\\HK STOCKS\\883.xhkg',
    full_name: '883.xhkg',
    description: 'CNOOC Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cnooc-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '902.xhkg': {
    symbol: '902.xhkg',
    path: 'STOCKS\\HK STOCKS\\902.xhkg',
    full_name: '902.xhkg',
    description: 'Huaneng Power International Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huaneng-power.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '914.xhkg': {
    symbol: '914.xhkg',
    path: 'STOCKS\\HK STOCKS\\914.xhkg',
    full_name: '914.xhkg',
    description: 'Anhui Conch Cement Co Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/conch-cement.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '916.xhkg': {
    symbol: '916.xhkg',
    path: 'STOCKS\\HK STOCKS\\916.xhkg',
    full_name: '916.xhkg',
    description: 'China Longyuan Power Group Ltd - Class H',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-longyuan-power-group-corp-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '939.xhkg': {
    symbol: '939.xhkg',
    path: 'STOCKS\\HK STOCKS\\939.xhkg',
    full_name: '939.xhkg',
    description: 'China Construction Bank Corp',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-construction-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '941.xhkg': {
    symbol: '941.xhkg',
    path: 'STOCKS\\HK STOCKS\\941.xhkg',
    full_name: '941.xhkg',
    description: 'China Mobile Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-mobile.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '960.xhkg': {
    symbol: '960.xhkg',
    path: 'STOCKS\\HK STOCKS\\960.xhkg',
    full_name: '960.xhkg',
    description: 'Longfor Group Holdings Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/longfor-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '9618.xhkg': {
    symbol: '9618.xhkg',
    path: 'STOCKS\\HK STOCKS\\9618.xhkg',
    full_name: '9618.xhkg',
    description: 'JD.com Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jd-com.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '9626.xhkg': {
    symbol: '9626.xhkg',
    path: 'STOCKS\\HK STOCKS\\9626.xhkg',
    full_name: '9626.xhkg',
    description: 'Bilibili Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bilibili.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '9888.xhkg': {
    symbol: '9888.xhkg',
    path: 'STOCKS\\HK STOCKS\\9888.xhkg',
    full_name: '9888.xhkg',
    description: 'Baidu',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/baidu.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '992.xhkg': {
    symbol: '992.xhkg',
    path: 'STOCKS\\HK STOCKS\\992.xhkg',
    full_name: '992.xhkg',
    description: 'Lenovo Group Limited',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lenovo.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '998.xhkg': {
    symbol: '998.xhkg',
    path: 'STOCKS\\HK STOCKS\\998.xhkg',
    full_name: '998.xhkg',
    description: 'China Citic Bank Corp Ltd',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-citic-bank-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '9988.xhkg': {
    symbol: '9988.xhkg',
    path: 'STOCKS\\HK STOCKS\\9988.xhkg',
    full_name: '9988.xhkg',
    description: 'Alibaba Group Holding Ltd [HKG]',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alibaba.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  '9999.xhkg': {
    symbol: '9999.xhkg',
    path: 'STOCKS\\HK STOCKS\\9999.xhkg',
    full_name: '9999.xhkg',
    description: 'NetEase Inc',
    type: 'hk stocks',
    session:
      '1;0130-0400,0500-0800:2|0130-0400,0500-0800:3|0130-0400,0500-0800:4|0130-0400,0500-0800:5|0130-0400,0500-0800:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/netease.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
};

export const uk_stocks = {
  SAN: {
    symbol: 'SAN',
    path: 'STOCKS\\UK STOCKS\\SAN',
    full_name: 'SAN',
    description: 'Banco Santander',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/santander.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  TEF: {
    symbol: 'TEF',
    path: 'STOCKS\\UK STOCKS\\TEF',
    full_name: 'TEF',
    description: 'TELEFONICA',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telefonica.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  ADSGn: {
    symbol: 'ADSGn',
    path: 'STOCKS\\UK STOCKS\\ADSGn',
    full_name: 'ADSGn',
    description: 'Adidas Salomon',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adidas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  AIRF: {
    symbol: 'AIRF',
    path: 'STOCKS\\UK STOCKS\\AIRF',
    full_name: 'AIRF',
    description: 'Air France - Klm',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/air-france-klm.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  ALVG: {
    symbol: 'ALVG',
    path: 'STOCKS\\UK STOCKS\\ALVG',
    full_name: 'ALVG',
    description: 'Allianz AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allianz.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  BAYGn: {
    symbol: 'BAYGn',
    path: 'STOCKS\\UK STOCKS\\BAYGn',
    full_name: 'BAYGn',
    description: 'Bayer AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bayer.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  BMWG: {
    symbol: 'BMWG',
    path: 'STOCKS\\UK STOCKS\\BMWG',
    full_name: 'BMWG',
    description: '(BMW) Bay Mot Werke ',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bmw.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  BNPP: {
    symbol: 'BNPP',
    path: 'STOCKS\\UK STOCKS\\BNPP',
    full_name: 'BNPP',
    description: 'BNP Paribas',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paribas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DAIGn: {
    symbol: 'DAIGn',
    path: 'STOCKS\\UK STOCKS\\DAIGn',
    full_name: 'DAIGn',
    description: 'Daimler AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DANO: {
    symbol: 'DANO',
    path: 'STOCKS\\UK STOCKS\\DANO',
    full_name: 'DANO',
    description: 'Danone',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/danone.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DBKGn: {
    symbol: 'DBKGn',
    path: 'STOCKS\\UK STOCKS\\DBKGn',
    full_name: 'DBKGn',
    description: 'Deutsche Bank',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-bank-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DPWGn: {
    symbol: 'DPWGn',
    path: 'STOCKS\\UK STOCKS\\DPWGn',
    full_name: 'DPWGn',
    description: 'Deutsche Post',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-post-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  EONGn: {
    symbol: 'EONGn',
    path: 'STOCKS\\UK STOCKS\\EONGn',
    full_name: 'EONGn',
    description: 'E.On  AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  IBE: {
    symbol: 'IBE',
    path: 'STOCKS\\UK STOCKS\\IBE',
    full_name: 'IBE',
    description: 'IBERDROLA',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iberdrola.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  LHAG: {
    symbol: 'LHAG',
    path: 'STOCKS\\UK STOCKS\\LHAG',
    full_name: 'LHAG',
    description: 'DT Lufthansa',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lufthansa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  LVMH: {
    symbol: 'LVMH',
    path: 'STOCKS\\UK STOCKS\\LVMH',
    full_name: 'LVMH',
    description: 'L.V.M.H.',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lvmh.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  CBKG: {
    symbol: 'CBKG',
    path: 'STOCKS\\UK STOCKS\\CBKG',
    full_name: 'CBKG',
    description: 'Commerzbank AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commerzbank.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  SIEGn: {
    symbol: 'SIEGn',
    path: 'STOCKS\\UK STOCKS\\SIEGn',
    full_name: 'SIEGn',
    description: 'Siemens',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  SOGN: {
    symbol: 'SOGN',
    path: 'STOCKS\\UK STOCKS\\SOGN',
    full_name: 'SOGN',
    description: 'Societe Generale',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sogn-sparebank.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  TOTF: {
    symbol: 'TOTF',
    path: 'STOCKS\\UK STOCKS\\TOTF',
    full_name: 'TOTF',
    description: 'Total',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/undefined.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  VOWG_p: {
    symbol: 'VOWG_p',
    path: 'STOCKS\\UK STOCKS\\VOWG_p',
    full_name: 'VOWG_p',
    description: 'Volkswagen AG',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/volkswagen.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  MAP: {
    symbol: 'MAP',
    path: 'STOCKS\\UK STOCKS\\MAP',
    full_name: 'MAP',
    description: 'MAPFRE',
    type: 'uk stocks',
    session: '1;0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6',
    decimals: 2,
    qty: { min: 1, max: 1000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mapfre.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AAF.uk': {
    symbol: 'AAF.uk',
    path: 'EU Shares\\LSE\\AAF.uk',
    full_name: 'AAF.uk',
    description: 'Airtel Africa PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/airtel-africa-plc-ord-usd0-50.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AAL.uk': {
    symbol: 'AAL.uk',
    path: 'EU Shares\\LSE\\AAL.uk',
    full_name: 'AAL.uk',
    description: 'Anglo American PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-airlines-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ABDN.uk': {
    symbol: 'ABDN.uk',
    path: 'EU Shares\\LSE\\ABDN.uk',
    full_name: 'ABDN.uk',
    description: 'abrdn PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/abrdn.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ADM.uk': {
    symbol: 'ADM.uk',
    path: 'EU Shares\\LSE\\ADM.uk',
    full_name: 'ADM.uk',
    description: 'Admiral Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/archer-daniels-midland.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AHT.uk': {
    symbol: 'AHT.uk',
    path: 'EU Shares\\LSE\\AHT.uk',
    full_name: 'AHT.uk',
    description: 'Ashtead Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ashford-hospitality-trust-7375-series-f-cumulative-preferred-stock.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AML.uk': {
    symbol: 'AML.uk',
    path: 'EU Shares\\LSE\\AML.uk',
    full_name: 'AML.uk',
    description: 'Aston Martin Lagonda Global Holdings PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aston-martin-lagonda.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ANTO.uk': {
    symbol: 'ANTO.uk',
    path: 'EU Shares\\LSE\\ANTO.uk',
    full_name: 'ANTO.uk',
    description: 'Antofagasta Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/antofagasta.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AO.uk': {
    symbol: 'AO.uk',
    path: 'EU Shares\\LSE\\AO.uk',
    full_name: 'AO.uk',
    description: 'AO World PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/aluminum.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ASC.uk': {
    symbol: 'ASC.uk',
    path: 'EU Shares\\LSE\\ASC.uk',
    full_name: 'ASC.uk',
    description: 'ASOS PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ardmore-shipping.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ASHM.uk': {
    symbol: 'ASHM.uk',
    path: 'EU Shares\\LSE\\ASHM.uk',
    full_name: 'ASHM.uk',
    description: 'Ashmore Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ashmore-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AUTO.uk': {
    symbol: 'AUTO.uk',
    path: 'EU Shares\\LSE\\AUTO.uk',
    full_name: 'AUTO.uk',
    description: 'Auto Trader Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/astra-otoparts.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ABF.uk': {
    symbol: 'ABF.uk',
    path: 'EU Shares\\LSE\\ABF.uk',
    full_name: 'ABF.uk',
    description: 'Associated British Foods PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/associated-british-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AZN.uk': {
    symbol: 'AZN.uk',
    path: 'EU Shares\\LSE\\AZN.uk',
    full_name: 'AZN.uk',
    description: 'AstraZeneca PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/astrazeneca.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BAB.uk': {
    symbol: 'BAB.uk',
    path: 'EU Shares\\LSE\\BAB.uk',
    full_name: 'BAB.uk',
    description: 'Babcock Intl Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/invesco.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BAES.uk': {
    symbol: 'BAES.uk',
    path: 'EU Shares\\LSE\\BAES.uk',
    full_name: 'BAES.uk',
    description: 'BAE Systems Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/leverage-shares.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BARC.uk': {
    symbol: 'BARC.uk',
    path: 'EU Shares\\LSE\\BARC.uk',
    full_name: 'BARC.uk',
    description: 'Barclays PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/barclays.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BATS.uk': {
    symbol: 'BATS.uk',
    path: 'EU Shares\\LSE\\BATS.uk',
    full_name: 'BATS.uk',
    description: 'British American Tobacco PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/british-american-tobacco.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BBY.uk': {
    symbol: 'BBY.uk',
    path: 'EU Shares\\LSE\\BBY.uk',
    full_name: 'BBY.uk',
    description: 'Balfour Beatty PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/best-buy.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BDEV.uk': {
    symbol: 'BDEV.uk',
    path: 'EU Shares\\LSE\\BDEV.uk',
    full_name: 'BDEV.uk',
    description: 'Barratt Developments Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/barratt-developments.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BHP.uk': {
    symbol: 'BHP.uk',
    path: 'EU Shares\\LSE\\BHP.uk',
    full_name: 'BHP.uk',
    description: 'BHP Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bhp.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'AV.uk': {
    symbol: 'AV.uk',
    path: 'EU Shares\\LSE\\AV.uk',
    full_name: 'AV.uk',
    description: 'Aviva PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/antares-vision.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BLND.uk': {
    symbol: 'BLND.uk',
    path: 'EU Shares\\LSE\\BLND.uk',
    full_name: 'BLND.uk',
    description: 'British Land Co Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blend.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BNZL.uk': {
    symbol: 'BNZL.uk',
    path: 'EU Shares\\LSE\\BNZL.uk',
    full_name: 'BNZL.uk',
    description: 'Bunzl Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bunzl.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BP.uk': {
    symbol: 'BP.uk',
    path: 'EU Shares\\LSE\\BP.uk',
    full_name: 'BP.uk',
    description: 'BP PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bp.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BRBY.uk': {
    symbol: 'BRBY.uk',
    path: 'EU Shares\\LSE\\BRBY.uk',
    full_name: 'BRBY.uk',
    description: 'Burberry Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/burberry.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BT.uk': {
    symbol: 'BT.uk',
    path: 'EU Shares\\LSE\\BT.uk',
    full_name: 'BT.uk',
    description: 'BT Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/banque-de-tunisie.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BVIC.uk': {
    symbol: 'BVIC.uk',
    path: 'EU Shares\\LSE\\BVIC.uk',
    full_name: 'BVIC.uk',
    description: 'Britvic PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/britvic-plc-ord-20p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CCH.uk': {
    symbol: 'CCH.uk',
    path: 'EU Shares\\LSE\\CCH.uk',
    full_name: 'CCH.uk',
    description: 'Coca-Cola HBC AG',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/coca-cola.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CCL.uk': {
    symbol: 'CCL.uk',
    path: 'EU Shares\\LSE\\CCL.uk',
    full_name: 'CCL.uk',
    description: 'Carnival Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carnival.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BKG.uk': {
    symbol: 'BKG.uk',
    path: 'EU Shares\\LSE\\BKG.uk',
    full_name: 'BKG.uk',
    description: 'Berkeley Group Holdings',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/berkeley-group-holdings-the-plc-ord-5-4141p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CNA.uk': {
    symbol: 'CNA.uk',
    path: 'EU Shares\\LSE\\CNA.uk',
    full_name: 'CNA.uk',
    description: 'Centrica Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cna.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CPG.uk': {
    symbol: 'CPG.uk',
    path: 'EU Shares\\LSE\\CPG.uk',
    full_name: 'CPG.uk',
    description: 'Compass Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crescent-point-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CPI.uk': {
    symbol: 'CPI.uk',
    path: 'EU Shares\\LSE\\CPI.uk',
    full_name: 'CPI.uk',
    description: 'Capita PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/capita.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CRDA.uk': {
    symbol: 'CRDA.uk',
    path: 'EU Shares\\LSE\\CRDA.uk',
    full_name: 'CRDA.uk',
    description: 'Croda International Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/croda.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CRH.uk': {
    symbol: 'CRH.uk',
    path: 'EU Shares\\LSE\\CRH.uk',
    full_name: 'CRH.uk',
    description: 'CRH PLC (LSE)',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crh.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CURY.uk': {
    symbol: 'CURY.uk',
    path: 'EU Shares\\LSE\\CURY.uk',
    full_name: 'CURY.uk',
    description: 'Currys PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/currys-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DCC.uk': {
    symbol: 'DCC.uk',
    path: 'EU Shares\\LSE\\DCC.uk',
    full_name: 'DCC.uk',
    description: 'DCC Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dynasty-ceramic.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DGE.uk': {
    symbol: 'DGE.uk',
    path: 'EU Shares\\LSE\\DGE.uk',
    full_name: 'DGE.uk',
    description: 'Diageo PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diageo.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'CEY.uk': {
    symbol: 'CEY.uk',
    path: 'EU Shares\\LSE\\CEY.uk',
    full_name: 'CEY.uk',
    description: 'Centamin PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/centamin.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DNLM.uk': {
    symbol: 'DNLM.uk',
    path: 'EU Shares\\LSE\\DNLM.uk',
    full_name: 'DNLM.uk',
    description: 'Dunelm Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dunelm-group-plc-ord-1p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DOM.uk': {
    symbol: 'DOM.uk',
    path: 'EU Shares\\LSE\\DOM.uk',
    full_name: 'DOM.uk',
    description: "Domino's Pizza Group PLC",
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/global-dominion-access.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DRX.uk': {
    symbol: 'DRX.uk',
    path: 'EU Shares\\LSE\\DRX.uk',
    full_name: 'DRX.uk',
    description: 'Drax Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/drax-group-plc-ord-11-16-29p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ECM.uk': {
    symbol: 'ECM.uk',
    path: 'EU Shares\\LSE\\ECM.uk',
    full_name: 'ECM.uk',
    description: 'Electrocomponents PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ecolomondo.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ELM.uk': {
    symbol: 'ELM.uk',
    path: 'EU Shares\\LSE\\ELM.uk',
    full_name: 'ELM.uk',
    description: 'Elementis PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/elementis-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'EMG.uk': {
    symbol: 'EMG.uk',
    path: 'EU Shares\\LSE\\EMG.uk',
    full_name: 'EMG.uk',
    description: 'Man Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amundi.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ENT.uk': {
    symbol: 'ENT.uk',
    path: 'EU Shares\\LSE\\ENT.uk',
    full_name: 'ENT.uk',
    description: 'Entain Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/entain.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'EXPN.uk': {
    symbol: 'EXPN.uk',
    path: 'EU Shares\\LSE\\EXPN.uk',
    full_name: 'EXPN.uk',
    description: 'Experian PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/experian.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'DLG.uk': {
    symbol: 'DLG.uk',
    path: 'EU Shares\\LSE\\DLG.uk',
    full_name: 'DLG.uk',
    description: 'Direct Line Insurance Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/de-longhi.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'FERG.uk': {
    symbol: 'FERG.uk',
    path: 'EU Shares\\LSE\\FERG.uk',
    full_name: 'FERG.uk',
    description: 'Ferguson Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ferguson.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'FGP.uk': {
    symbol: 'FGP.uk',
    path: 'EU Shares\\LSE\\FGP.uk',
    full_name: 'FGP.uk',
    description: 'FirstGroup PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/firstgroup-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'FLTR.uk': {
    symbol: 'FLTR.uk',
    path: 'EU Shares\\LSE\\FLTR.uk',
    full_name: 'FLTR.uk',
    description: 'Flutter Entertainment Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vaneck.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'FRAS.uk': {
    symbol: 'FRAS.uk',
    path: 'EU Shares\\LSE\\FRAS.uk',
    full_name: 'FRAS.uk',
    description: 'Frasers Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/frasers-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'FRES.uk': {
    symbol: 'FRES.uk',
    path: 'EU Shares\\LSE\\FRES.uk',
    full_name: 'FRES.uk',
    description: 'Fresnillo Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/anpac-bio.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'GLEN.uk': {
    symbol: 'GLEN.uk',
    path: 'EU Shares\\LSE\\GLEN.uk',
    full_name: 'GLEN.uk',
    description: 'Glencore PLC (LSE)',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/glencore.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'GNC.uk': {
    symbol: 'GNC.uk',
    path: 'EU Shares\\LSE\\GNC.uk',
    full_name: 'GNC.uk',
    description: 'Greencore Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/greencore-plc-cdi.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'GRG.uk': {
    symbol: 'GRG.uk',
    path: 'EU Shares\\LSE\\GRG.uk',
    full_name: 'GRG.uk',
    description: 'Greggs PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/greggs-plc-ord-2p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'EZJ.uk': {
    symbol: 'EZJ.uk',
    path: 'EU Shares\\LSE\\EZJ.uk',
    full_name: 'EZJ.uk',
    description: 'Easyjet Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/proshares.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HAS.uk': {
    symbol: 'HAS.uk',
    path: 'EU Shares\\LSE\\HAS.uk',
    full_name: 'HAS.uk',
    description: 'Hays PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hasbro.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HIK.uk': {
    symbol: 'HIK.uk',
    path: 'EU Shares\\LSE\\HIK.uk',
    full_name: 'HIK.uk',
    description: 'Hikma Pharmaceuticals Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hikma-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HL.uk': {
    symbol: 'HL.uk',
    path: 'EU Shares\\LSE\\HL.uk',
    full_name: 'HL.uk',
    description: 'Hargreaves Lansdown Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hecla-mining.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HLMA.uk': {
    symbol: 'HLMA.uk',
    path: 'EU Shares\\LSE\\HLMA.uk',
    full_name: 'HLMA.uk',
    description: 'Halma Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/halma.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HMSO.uk': {
    symbol: 'HMSO.uk',
    path: 'EU Shares\\LSE\\HMSO.uk',
    full_name: 'HMSO.uk',
    description: 'Hammerson PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hammerson.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HOC.uk': {
    symbol: 'HOC.uk',
    path: 'EU Shares\\LSE\\HOC.uk',
    full_name: 'HOC.uk',
    description: 'Hochschild Mining PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hochschild-mining-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HSBA.uk': {
    symbol: 'HSBA.uk',
    path: 'EU Shares\\LSE\\HSBA.uk',
    full_name: 'HSBA.uk',
    description: 'HSBC Holdings PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hsbc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HSX.uk': {
    symbol: 'HSX.uk',
    path: 'EU Shares\\LSE\\HSX.uk',
    full_name: 'HSX.uk',
    description: 'Hiscox Ltd',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hiscox.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'GSK.uk': {
    symbol: 'GSK.uk',
    path: 'EU Shares\\LSE\\GSK.uk',
    full_name: 'GSK.uk',
    description: 'GlaxoSmithKline PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/glaxosmithkline.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'IAG.uk': {
    symbol: 'IAG.uk',
    path: 'EU Shares\\LSE\\IAG.uk',
    full_name: 'IAG.uk',
    description: 'International Airlines Group SA / IAG [LSE]',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/international-consolidated-airlines-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ICP.uk': {
    symbol: 'ICP.uk',
    path: 'EU Shares\\LSE\\ICP.uk',
    full_name: 'ICP.uk',
    description: 'Intermediate Capital Group PLC / ICG',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCICP.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'IGG.uk': {
    symbol: 'IGG.uk',
    path: 'EU Shares\\LSE\\IGG.uk',
    full_name: 'IGG.uk',
    description: 'IG Group Holdings PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ig.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'IHG.uk': {
    symbol: 'IHG.uk',
    path: 'EU Shares\\LSE\\IHG.uk',
    full_name: 'IHG.uk',
    description: 'InterContinental Hotels Group Plc / IHG',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intercontinental-hotels.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'III.uk': {
    symbol: 'III.uk',
    path: 'EU Shares\\LSE\\III.uk',
    full_name: 'III.uk',
    description: '3i Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/isg.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'IMB.uk': {
    symbol: 'IMB.uk',
    path: 'EU Shares\\LSE\\IMB.uk',
    full_name: 'IMB.uk',
    description: 'Imperial Brands PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/imperial-brands.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'INCH.uk': {
    symbol: 'INCH.uk',
    path: 'EU Shares\\LSE\\INCH.uk',
    full_name: 'INCH.uk',
    description: 'Inchcape PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/inchcape-plc-ord-10p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'INF.uk': {
    symbol: 'INF.uk',
    path: 'EU Shares\\LSE\\INF.uk',
    full_name: 'INF.uk',
    description: 'Informa Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/informa.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'HWDN.uk': {
    symbol: 'HWDN.uk',
    path: 'EU Shares\\LSE\\HWDN.uk',
    full_name: 'HWDN.uk',
    description: 'Howden Joinery Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/howden-joinery.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'IPO.uk': {
    symbol: 'IPO.uk',
    path: 'EU Shares\\LSE\\IPO.uk',
    full_name: 'IPO.uk',
    description: 'IP Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/renaissance-capital-greenwich.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ITRK.uk': {
    symbol: 'ITRK.uk',
    path: 'EU Shares\\LSE\\ITRK.uk',
    full_name: 'ITRK.uk',
    description: 'Intertek Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intertek.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ITV.uk': {
    symbol: 'ITV.uk',
    path: 'EU Shares\\LSE\\ITV.uk',
    full_name: 'ITV.uk',
    description: 'ITV Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/itv.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'JD.uk': {
    symbol: 'JD.uk',
    path: 'EU Shares\\LSE\\JD.uk',
    full_name: 'JD.uk',
    description: 'JD Sports Fashion Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jd-com.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'JET.uk': {
    symbol: 'JET.uk',
    path: 'EU Shares\\LSE\\JET.uk',
    full_name: 'JET.uk',
    description: 'JustEat Takeway.com',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/just-eat-takeaway.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'JMAT.uk': {
    symbol: 'JMAT.uk',
    path: 'EU Shares\\LSE\\JMAT.uk',
    full_name: 'JMAT.uk',
    description: 'Johnson Matthey Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/johnson-matthey.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'JUP.uk': {
    symbol: 'JUP.uk',
    path: 'EU Shares\\LSE\\JUP.uk',
    full_name: 'JUP.uk',
    description: 'Jupiter Fund Management PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jupiter-fund-management.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'JUST.uk': {
    symbol: 'JUST.uk',
    path: 'EU Shares\\LSE\\JUST.uk',
    full_name: 'JUST.uk',
    description: 'Just Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/golden-sachs-etf-trust-goldman.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'INVP.uk': {
    symbol: 'INVP.uk',
    path: 'EU Shares\\LSE\\INVP.uk',
    full_name: 'INVP.uk',
    description: 'Investec PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/investec-plc-ord-gbp0-0002.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'LAND.uk': {
    symbol: 'LAND.uk',
    path: 'EU Shares\\LSE\\LAND.uk',
    full_name: 'LAND.uk',
    description: 'Land Securities Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gladstone-land.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'LGEN.uk': {
    symbol: 'LGEN.uk',
    path: 'EU Shares\\LSE\\LGEN.uk',
    full_name: 'LGEN.uk',
    description: 'Legal & General Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/legal-and-general.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'LLOY.uk': {
    symbol: 'LLOY.uk',
    path: 'EU Shares\\LSE\\LLOY.uk',
    full_name: 'LLOY.uk',
    description: 'Lloyds Banking Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lloyds.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'LSEG.uk': {
    symbol: 'LSEG.uk',
    path: 'EU Shares\\LSE\\LSEG.uk',
    full_name: 'LSEG.uk',
    description: 'London Stock Exchange Group / LSE',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/london-stock-exchange.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MAB.uk': {
    symbol: 'MAB.uk',
    path: 'EU Shares\\LSE\\MAB.uk',
    full_name: 'MAB.uk',
    description: 'Mitchells & Butlers PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mitchells-and-butlers-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MARS.uk': {
    symbol: 'MARS.uk',
    path: 'EU Shares\\LSE\\MARS.uk',
    full_name: 'MARS.uk',
    description: "Marston's PLC",
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marston-s-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MKS.uk': {
    symbol: 'MKS.uk',
    path: 'EU Shares\\LSE\\MKS.uk',
    full_name: 'MKS.uk',
    description: 'Marks & Spencer Group PLC / M&S',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marks-and-spencer.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MNDI.uk': {
    symbol: 'MNDI.uk',
    path: 'EU Shares\\LSE\\MNDI.uk',
    full_name: 'MNDI.uk',
    description: 'Mondi Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mondi.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'KGF.uk': {
    symbol: 'KGF.uk',
    path: 'EU Shares\\LSE\\KGF.uk',
    full_name: 'KGF.uk',
    description: 'Kingfisher Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kingfisher-plc-ord-15-5-7p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MRO.uk': {
    symbol: 'MRO.uk',
    path: 'EU Shares\\LSE\\MRO.uk',
    full_name: 'MRO.uk',
    description: 'Melrose Industries Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/marathon-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MTO.uk': {
    symbol: 'MTO.uk',
    path: 'EU Shares\\LSE\\MTO.uk',
    full_name: 'MTO.uk',
    description: 'Mitie Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mitie-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MTRO.uk': {
    symbol: 'MTRO.uk',
    path: 'EU Shares\\LSE\\MTRO.uk',
    full_name: 'MTRO.uk',
    description: 'Metro Bank PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metro-bank-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'NETW.uk': {
    symbol: 'NETW.uk',
    path: 'EU Shares\\LSE\\NETW.uk',
    full_name: 'NETW.uk',
    description: 'Network International Holding',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/network-intl-hldgs-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'NG.uk': {
    symbol: 'NG.uk',
    path: 'EU Shares\\LSE\\NG.uk',
    full_name: 'NG.uk',
    description: 'National Grid PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natural-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'NWG.uk': {
    symbol: 'NWG.uk',
    path: 'EU Shares\\LSE\\NWG.uk',
    full_name: 'NWG.uk',
    description: 'Natwest Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natwest-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'NXT.uk': {
    symbol: 'NXT.uk',
    path: 'EU Shares\\LSE\\NXT.uk',
    full_name: 'NXT.uk',
    description: 'Next Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nextracker.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'OCDO.uk': {
    symbol: 'OCDO.uk',
    path: 'EU Shares\\LSE\\OCDO.uk',
    full_name: 'OCDO.uk',
    description: 'Ocado Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ocado-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'MONY.uk': {
    symbol: 'MONY.uk',
    path: 'EU Shares\\LSE\\MONY.uk',
    full_name: 'MONY.uk',
    description: 'Moneysupermarket.com Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/moneysupermarketcom.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PAGE.uk': {
    symbol: 'PAGE.uk',
    path: 'EU Shares\\LSE\\PAGE.uk',
    full_name: 'PAGE.uk',
    description: 'PageGroup PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/michael-page-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PAY.uk': {
    symbol: 'PAY.uk',
    path: 'EU Shares\\LSE\\PAY.uk',
    full_name: 'PAY.uk',
    description: 'PayPoint PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paymentus.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PETS.uk': {
    symbol: 'PETS.uk',
    path: 'EU Shares\\LSE\\PETS.uk',
    full_name: 'PETS.uk',
    description: 'Pets at Home Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/petmed-express.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PFC.uk': {
    symbol: 'PFC.uk',
    path: 'EU Shares\\LSE\\PFC.uk',
    full_name: 'PFC.uk',
    description: 'Petrofac Limited',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/power-finance.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PHNX.uk': {
    symbol: 'PHNX.uk',
    path: 'EU Shares\\LSE\\PHNX.uk',
    full_name: 'PHNX.uk',
    description: 'Phoenix Group Holdings Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/phoenix-group-holdings-plc-ord-10p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PLUS.uk': {
    symbol: 'PLUS.uk',
    path: 'EU Shares\\LSE\\PLUS.uk',
    full_name: 'PLUS.uk',
    description: 'Plus500 Ltd',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eplus.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PNN.uk': {
    symbol: 'PNN.uk',
    path: 'EU Shares\\LSE\\PNN.uk',
    full_name: 'PNN.uk',
    description: 'Pennon Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pennon-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PRU.uk': {
    symbol: 'PRU.uk',
    path: 'EU Shares\\LSE\\PRU.uk',
    full_name: 'PRU.uk',
    description: 'Prudential PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prudential.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PSH.uk': {
    symbol: 'PSH.uk',
    path: 'EU Shares\\LSE\\PSH.uk',
    full_name: 'PSH.uk',
    description: 'Pershing Square Holdings Ltd',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pgim-active-aggregate-bond-etf.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PAG.uk': {
    symbol: 'PAG.uk',
    path: 'EU Shares\\LSE\\PAG.uk',
    full_name: 'PAG.uk',
    description: 'Paragon Banking Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/penske-automotive-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PSN.uk': {
    symbol: 'PSN.uk',
    path: 'EU Shares\\LSE\\PSN.uk',
    full_name: 'PSN.uk',
    description: 'Persimmon Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/parsons-corporation.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'PSON.uk': {
    symbol: 'PSON.uk',
    path: 'EU Shares\\LSE\\PSON.uk',
    full_name: 'PSON.uk',
    description: 'Pearson Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pearson.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'REL.uk': {
    symbol: 'REL.uk',
    path: 'EU Shares\\LSE\\REL.uk',
    full_name: 'REL.uk',
    description: 'Relx PLC [LSE]',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/relx.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'RIO.uk': {
    symbol: 'RIO.uk',
    path: 'EU Shares\\LSE\\RIO.uk',
    full_name: 'RIO.uk',
    description: 'Rio Tinto PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rio-tinto.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'RKT.uk': {
    symbol: 'RKT.uk',
    path: 'EU Shares\\LSE\\RKT.uk',
    full_name: 'RKT.uk',
    description: 'Reckitt Benckiser Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rocket-companies.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'RMV.uk': {
    symbol: 'RMV.uk',
    path: 'EU Shares\\LSE\\RMV.uk',
    full_name: 'RMV.uk',
    description: 'Rightmove Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rightmove-plc-ord-0-1p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ROO.uk': {
    symbol: 'ROO.uk',
    path: 'EU Shares\\LSE\\ROO.uk',
    full_name: 'ROO.uk',
    description: 'Deliveroo Holdings Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deliveroo.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ROR.uk': {
    symbol: 'ROR.uk',
    path: 'EU Shares\\LSE\\ROR.uk',
    full_name: 'ROR.uk',
    description: 'Rotork PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rotork-plc-ord-0-5p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'RR.uk': {
    symbol: 'RR.uk',
    path: 'EU Shares\\LSE\\RR.uk',
    full_name: 'RR.uk',
    description: 'Rolls-Royce Holdings PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/recharge-resources-ltd.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SBRY.uk': {
    symbol: 'SBRY.uk',
    path: 'EU Shares\\LSE\\SBRY.uk',
    full_name: 'SBRY.uk',
    description: 'Sainsbury (J) Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sainsbury-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SDR.uk': {
    symbol: 'SDR.uk',
    path: 'EU Shares\\LSE\\SDR.uk',
    full_name: 'SDR.uk',
    description: 'Schroders Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/schroders-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SFOR.uk': {
    symbol: 'SFOR.uk',
    path: 'EU Shares\\LSE\\SFOR.uk',
    full_name: 'SFOR.uk',
    description: 'S4 Capital PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/s4-capital.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SGE.uk': {
    symbol: 'SGE.uk',
    path: 'EU Shares\\LSE\\SGE.uk',
    full_name: 'SGE.uk',
    description: 'Sage Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/strong-global-entertainment.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SGRO.uk': {
    symbol: 'SGRO.uk',
    path: 'EU Shares\\LSE\\SGRO.uk',
    full_name: 'SGRO.uk',
    description: 'SEGRO Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/segro-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SHEL.uk': {
    symbol: 'SHEL.uk',
    path: 'EU Shares\\LSE\\SHEL.uk',
    full_name: 'SHEL.uk',
    description: 'Shell PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/shell.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SHI.uk': {
    symbol: 'SHI.uk',
    path: 'EU Shares\\LSE\\SHI.uk',
    full_name: 'SHI.uk',
    description: 'SIG PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sig-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SKG.uk': {
    symbol: 'SKG.uk',
    path: 'EU Shares\\LSE\\SKG.uk',
    full_name: 'SKG.uk',
    description: 'Smurfit Kappa Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smurfit-kappa.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'RTO.uk': {
    symbol: 'RTO.uk',
    path: 'EU Shares\\LSE\\RTO.uk',
    full_name: 'RTO.uk',
    description: 'Rentokil Initial Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rentokil-initial.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SMIN.uk': {
    symbol: 'SMIN.uk',
    path: 'EU Shares\\LSE\\SMIN.uk',
    full_name: 'SMIN.uk',
    description: 'Smiths Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ishares.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SMT.uk': {
    symbol: 'SMT.uk',
    path: 'EU Shares\\LSE\\SMT.uk',
    full_name: 'SMT.uk',
    description: 'Scottish Mortgage Inv Tr Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stars-microelectronics.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SN.uk': {
    symbol: 'SN.uk',
    path: 'EU Shares\\LSE\\SN.uk',
    full_name: 'SN.uk',
    description: 'Smith & Nephew Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sharkninja.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SPT.uk': {
    symbol: 'SPT.uk',
    path: 'EU Shares\\LSE\\SPT.uk',
    full_name: 'SPT.uk',
    description: 'Spirent Communications PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sprout-social.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SPX.uk': {
    symbol: 'SPX.uk',
    path: 'EU Shares\\LSE\\SPX.uk',
    full_name: 'SPX.uk',
    description: 'Spirax-Sarco Engineering Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/s-and-p-500.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SSE.uk': {
    symbol: 'SSE.uk',
    path: 'EU Shares\\LSE\\SSE.uk',
    full_name: 'SSE.uk',
    description: 'SSE Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sse-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'STAN.uk': {
    symbol: 'STAN.uk',
    path: 'EU Shares\\LSE\\STAN.uk',
    full_name: 'STAN.uk',
    description: 'Standard Chartered PLC (LSE)',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/standard-chartered.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'STJ.uk': {
    symbol: 'STJ.uk',
    path: 'EU Shares\\LSE\\STJ.uk',
    full_name: 'STJ.uk',
    description: "St James's Place Plc",
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/st-james-s-place-plc-ord-15p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SMDS.uk': {
    symbol: 'SMDS.uk',
    path: 'EU Shares\\LSE\\SMDS.uk',
    full_name: 'SMDS.uk',
    description: 'DS Smith Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/smith-ds-plc-ord-10p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'THG.uk': {
    symbol: 'THG.uk',
    path: 'EU Shares\\LSE\\THG.uk',
    full_name: 'THG.uk',
    description: 'THG PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hanover-insurance-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TLPR.uk': {
    symbol: 'TLPR.uk',
    path: 'EU Shares\\LSE\\TLPR.uk',
    full_name: 'TLPR.uk',
    description: 'TP ICAP Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tp-icap-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TLW.uk': {
    symbol: 'TLW.uk',
    path: 'EU Shares\\LSE\\TLW.uk',
    full_name: 'TLW.uk',
    description: 'Tullow Oil PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tullow-oil-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TPK.uk': {
    symbol: 'TPK.uk',
    path: 'EU Shares\\LSE\\TPK.uk',
    full_name: 'TPK.uk',
    description: 'Travis Perkins PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/travis-perkins-plc-ord-1205105.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TRST.uk': {
    symbol: 'TRST.uk',
    path: 'EU Shares\\LSE\\TRST.uk',
    full_name: 'TRST.uk',
    description: 'Trustpilot Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/trustco-bank-ny.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TSCO.uk': {
    symbol: 'TSCO.uk',
    path: 'EU Shares\\LSE\\TSCO.uk',
    full_name: 'TSCO.uk',
    description: 'Tesco PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tractor-supply.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TUI.uk': {
    symbol: 'TUI.uk',
    path: 'EU Shares\\LSE\\TUI.uk',
    full_name: 'TUI.uk',
    description: 'TUI AG',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tui.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'TW.uk': {
    symbol: 'TW.uk',
    path: 'EU Shares\\LSE\\TW.uk',
    full_name: 'TW.uk',
    description: 'Taylor Wimpey Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tradeweb.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'SVT.uk': {
    symbol: 'SVT.uk',
    path: 'EU Shares\\LSE\\SVT.uk',
    full_name: 'SVT.uk',
    description: 'Severn Trent Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/servotronics.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'UU.uk': {
    symbol: 'UU.uk',
    path: 'EU Shares\\LSE\\UU.uk',
    full_name: 'UU.uk',
    description: 'United Utilities Group Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-utilities-group-plc-ord-5p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'VCT.uk': {
    symbol: 'VCT.uk',
    path: 'EU Shares\\LSE\\VCT.uk',
    full_name: 'VCT.uk',
    description: 'Victrex PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/victrex-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'VOD.uk': {
    symbol: 'VOD.uk',
    path: 'EU Shares\\LSE\\VOD.uk',
    full_name: 'VOD.uk',
    description: 'Vodafone Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vodafone.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'VSVS.uk': {
    symbol: 'VSVS.uk',
    path: 'EU Shares\\LSE\\VSVS.uk',
    full_name: 'VSVS.uk',
    description: 'Vesuvius PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vesuvius-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'VTY.uk': {
    symbol: 'VTY.uk',
    path: 'EU Shares\\LSE\\VTY.uk',
    full_name: 'VTY.uk',
    description: 'Vistry Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vistry-group-plc-ord-50p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'WEIR.uk': {
    symbol: 'WEIR.uk',
    path: 'EU Shares\\LSE\\WEIR.uk',
    full_name: 'WEIR.uk',
    description: 'Weir Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/weir-group-plc-ord-12-5p.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'WG.uk': {
    symbol: 'WG.uk',
    path: 'EU Shares\\LSE\\WG.uk',
    full_name: 'WG.uk',
    description: 'John Wood Group PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/westward-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'WIZZ.uk': {
    symbol: 'WIZZ.uk',
    path: 'EU Shares\\LSE\\WIZZ.uk',
    full_name: 'WIZZ.uk',
    description: 'Wizz Air Holdings PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wizz-air-holdings-plc-ord-gbp0-0001.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ULVR.uk': {
    symbol: 'ULVR.uk',
    path: 'EU Shares\\LSE\\ULVR.uk',
    full_name: 'ULVR.uk',
    description: 'Unilever PLC',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unilever.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'WTB.uk': {
    symbol: 'WTB.uk',
    path: 'EU Shares\\LSE\\WTB.uk',
    full_name: 'WTB.uk',
    description: 'Whitbread Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/whitbread.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'WPP.uk': {
    symbol: 'WPP.uk',
    path: 'EU Shares\\LSE\\WPP.uk',
    full_name: 'WPP.uk',
    description: 'WPP Plc',
    type: 'uk stocks',
    session: '1;0700-1530:2|0700-1530:3|0700-1530:4|0700-1530:5|0700-1530:6',
    decimals: 2,
    qty: { min: 1, max: 1000000, step: 1, default: 1 },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wpp.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
};

export const eu_stocks = {
  '1COV.de': {
    symbol: '1COV.de',
    path: 'EU Shares\\DE\\1COV.de',
    full_name: '1COV.de',
    description: 'Covestro AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/covestro.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  '8TRA.de': {
    symbol: '8TRA.de',
    path: 'EU Shares\\DE\\8TRA.de',
    full_name: '8TRA.de',
    description: 'Traton SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/traton-se-inh-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'A2A.it': {
    symbol: 'A2A.it',
    path: 'EU Shares\\MTA\\A2A.it',
    full_name: 'A2A.it',
    description: 'A2A SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/a2a.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AALB.nl': {
    symbol: 'AALB.nl',
    path: 'EU Shares\\AMS\\AALB.nl',
    full_name: 'AALB.nl',
    description: 'Aalberts NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aalberts-n-v.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ABI.be': {
    symbol: 'ABI.be',
    path: 'EU Shares\\BRU\\ABI.be',
    full_name: 'ABI.be',
    description: 'AB InBev SA/NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ABN.nl': {
    symbol: 'ABN.nl',
    path: 'EU Shares\\AMS\\ABN.nl',
    full_name: 'ABN.nl',
    description: 'ABN AMRO Bank NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/abn-amro.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AC.fr': {
    symbol: 'AC.fr',
    path: 'EU Shares\\ENX\\AC.fr',
    full_name: 'AC.fr',
    description: 'Accor SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/air-canada.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ACA.fr': {
    symbol: 'ACA.fr',
    path: 'EU Shares\\ENX\\ACA.fr',
    full_name: 'ACA.fr',
    description: 'Credit Agricole SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/credit-agricole.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ACS.es': {
    symbol: 'ACS.es',
    path: 'EU Shares\\ES\\ACS.es',
    full_name: 'ACS.es',
    description: 'ACS Actividades Cons y Serv SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/acs-actividades-de-construccion-y-servicios.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AD.nl': {
    symbol: 'AD.nl',
    path: 'EU Shares\\AMS\\AD.nl',
    full_name: 'AD.nl',
    description: 'Koninklijke Ahold Delhaize NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ahold-delhaize.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ADP.fr': {
    symbol: 'ADP.fr',
    path: 'EU Shares\\ENX\\ADP.fr',
    full_name: 'ADP.fr',
    description: 'Groupe ADP (Aeroports de Paris)',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/automatic-data-processing.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ADS.de': {
    symbol: 'ADS.de',
    path: 'EU Shares\\DE\\ADS.de',
    full_name: 'ADS.de',
    description: 'adidas AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adidas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ADYEN.nl': {
    symbol: 'ADYEN.nl',
    path: 'EU Shares\\AMS\\ADYEN.nl',
    full_name: 'ADYEN.nl',
    description: 'Adyen NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/adyen.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AENA.es': {
    symbol: 'AENA.es',
    path: 'EU Shares\\ES\\AENA.es',
    full_name: 'AENA.es',
    description: 'Aena SME SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aena.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AF.fr': {
    symbol: 'AF.fr',
    path: 'EU Shares\\ENX\\AF.fr',
    full_name: 'AF.fr',
    description: 'Air France-KLM SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/air-france-klm.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AFX.de': {
    symbol: 'AFX.de',
    path: 'EU Shares\\DE\\AFX.de',
    full_name: 'AFX.de',
    description: 'Carl Zeiss Meditec AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carl-zeiss-meditec.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AGN.nl': {
    symbol: 'AGN.nl',
    path: 'EU Shares\\AMS\\AGN.nl',
    full_name: 'AGN.nl',
    description: 'Aegon NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/algernon-pharmaceuticals.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AI.fr': {
    symbol: 'AI.fr',
    path: 'EU Shares\\ENX\\AI.fr',
    full_name: 'AI.fr',
    description: 'Air Liquide SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/c3-ai.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AIR.de': {
    symbol: 'AIR.de',
    path: 'EU Shares\\DE\\AIR.de',
    full_name: 'AIR.de',
    description: 'Airbus SE.',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/airbus.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AIR.fr': {
    symbol: 'AIR.fr',
    path: 'EU Shares\\ENX\\AIR.fr',
    full_name: 'AIR.fr',
    description: 'Airbus SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/airbus.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AIXA.de': {
    symbol: 'AIXA.de',
    path: 'EU Shares\\DE\\AIXA.de',
    full_name: 'AIXA.de',
    description: 'Aixtron SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aixtron.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AKE.fr': {
    symbol: 'AKE.fr',
    path: 'EU Shares\\ENX\\AKE.fr',
    full_name: 'AKE.fr',
    description: 'ARKEMA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arkema.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AKRBP.no': {
    symbol: 'AKRBP.no',
    path: 'EU Shares\\OSL\\AKRBP.no',
    full_name: 'AKRBP.no',
    description: 'Aker BP ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aker-bp-asa.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'AKSO.no': {
    symbol: 'AKSO.no',
    path: 'EU Shares\\OSL\\AKSO.no',
    full_name: 'AKSO.no',
    description: 'Aker Solutions ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aker-solutions-asa.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'AKZA.nl': {
    symbol: 'AKZA.nl',
    path: 'EU Shares\\AMS\\AKZA.nl',
    full_name: 'AKZA.nl',
    description: 'Akzo Nobel NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/akzo-nobel.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ALFA.se': {
    symbol: 'ALFA.se',
    path: 'EU Shares\\STO\\ALFA.se',
    full_name: 'ALFA.se',
    description: 'Alfa Laval AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alfa-financial-software-hold.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'ALM.es': {
    symbol: 'ALM.es',
    path: 'EU Shares\\ES\\ALM.es',
    full_name: 'ALM.es',
    description: 'Almirall SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/almirall.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ALO.fr': {
    symbol: 'ALO.fr',
    path: 'EU Shares\\ENX\\ALO.fr',
    full_name: 'ALO.fr',
    description: 'Alstom SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/alstom.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ALV.de': {
    symbol: 'ALV.de',
    path: 'EU Shares\\DE\\ALV.de',
    full_name: 'ALV.de',
    description: 'Allianz SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/allianz.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AMS.es': {
    symbol: 'AMS.es',
    path: 'EU Shares\\ES\\AMS.es',
    full_name: 'AMS.es',
    description: 'Amadeus IT Group SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/american-shared-hospital-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AMUN.fr': {
    symbol: 'AMUN.fr',
    path: 'EU Shares\\ENX\\AMUN.fr',
    full_name: 'AMUN.fr',
    description: 'Amundi SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/amundi.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ANA.es': {
    symbol: 'ANA.es',
    path: 'EU Shares\\ES\\ANA.es',
    full_name: 'ANA.es',
    description: 'Acciona SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/acciona.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ARCAD.nl': {
    symbol: 'ARCAD.nl',
    path: 'EU Shares\\AMS\\ARCAD.nl',
    full_name: 'ARCAD.nl',
    description: 'Arcadis NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arcadis.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ASM.nl': {
    symbol: 'ASM.nl',
    path: 'EU Shares\\AMS\\ASM.nl',
    full_name: 'ASM.nl',
    description: 'ASM International NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/avino-silver-and-gold-mines.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ASML.nl': {
    symbol: 'ASML.nl',
    path: 'EU Shares\\AMS\\ASML.nl',
    full_name: 'ASML.nl',
    description: 'ASML Holding NV (AEX)',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/asml.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ASRNL.nl': {
    symbol: 'ASRNL.nl',
    path: 'EU Shares\\AMS\\ASRNL.nl',
    full_name: 'ASRNL.nl',
    description: 'ASR Nederland NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/asr-nederland.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ASSAB.se': {
    symbol: 'ASSAB.se',
    path: 'EU Shares\\STO\\ASSAB.se',
    full_name: 'ASSAB.se',
    description: 'Assa Abloy AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/assa-abloy-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'AT1.de': {
    symbol: 'AT1.de',
    path: 'EU Shares\\DE\\AT1.de',
    full_name: 'AT1.de',
    description: 'Aroundtown SA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/invesco.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ATO.fr': {
    symbol: 'ATO.fr',
    path: 'EU Shares\\ENX\\ATO.fr',
    full_name: 'ATO.fr',
    description: 'Atos SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/atos.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'AZM.it': {
    symbol: 'AZM.it',
    path: 'EU Shares\\MTA\\AZM.it',
    full_name: 'AZM.it',
    description: 'Azimut Holding SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/microsoft.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'B4B.de': {
    symbol: 'B4B.de',
    path: 'EU Shares\\DE\\B4B.de',
    full_name: 'B4B.de',
    description: 'Metro AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metro-ag-st-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BAMI.it': {
    symbol: 'BAMI.it',
    path: 'EU Shares\\MTA\\BAMI.it',
    full_name: 'BAMI.it',
    description: 'Banco BPM SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/banco-bpm.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BAMNB.nl': {
    symbol: 'BAMNB.nl',
    path: 'EU Shares\\AMS\\BAMNB.nl',
    full_name: 'BAMNB.nl',
    description: 'Koninklijke BAM Groep NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/koninklijke-bam-groep-n-v.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BAS.de': {
    symbol: 'BAS.de',
    path: 'EU Shares\\DE\\BAS.de',
    full_name: 'BAS.de',
    description: 'BASF SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/basf.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BAYN.de': {
    symbol: 'BAYN.de',
    path: 'EU Shares\\DE\\BAYN.de',
    full_name: 'BAYN.de',
    description: 'Bayer AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bayer.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BB.fr': {
    symbol: 'BB.fr',
    path: 'EU Shares\\ENX\\BB.fr',
    full_name: 'BB.fr',
    description: 'Societe BiC SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackberry.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BBVA.es': {
    symbol: 'BBVA.es',
    path: 'EU Shares\\ES\\BBVA.es',
    full_name: 'BBVA.es',
    description: 'BBVA SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/banco-bilbao-vizcaya-argentaria.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BC8.de': {
    symbol: 'BC8.de',
    path: 'EU Shares\\DE\\BC8.de',
    full_name: 'BC8.de',
    description: 'Bechtle AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bechtle-ag-inhaber-aktien-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BEI.de': {
    symbol: 'BEI.de',
    path: 'EU Shares\\DE\\BEI.de',
    full_name: 'BEI.de',
    description: 'Beiersdorf AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/beiersdorf.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BGN.it': {
    symbol: 'BGN.it',
    path: 'EU Shares\\MTA\\BGN.it',
    full_name: 'BGN.it',
    description: 'Banca Generali SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/banca-generali.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BILL.se': {
    symbol: 'BILL.se',
    path: 'EU Shares\\STO\\BILL.se',
    full_name: 'BILL.se',
    description: 'BillerudKorsnas AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bill-com.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'BIM.fr': {
    symbol: 'BIM.fr',
    path: 'EU Shares\\ENX\\BIM.fr',
    full_name: 'BIM.fr',
    description: 'bioMérieux SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sector/health-care.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BKT.es': {
    symbol: 'BKT.es',
    path: 'EU Shares\\ES\\BKT.es',
    full_name: 'BKT.es',
    description: 'Bankinter SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackrock.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BMPS.it': {
    symbol: 'BMPS.it',
    path: 'EU Shares\\MTA\\BMPS.it',
    full_name: 'BMPS.it',
    description: 'Banca Monte dei Paschi di Siena SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bca-mps.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BMW.de': {
    symbol: 'BMW.de',
    path: 'EU Shares\\DE\\BMW.de',
    full_name: 'BMW.de',
    description: 'Bayerische Motoren Werke AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bmw.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BN.fr': {
    symbol: 'BN.fr',
    path: 'EU Shares\\ENX\\BN.fr',
    full_name: 'BN.fr',
    description: 'Danone SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brookfield.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BNP.fr': {
    symbol: 'BNP.fr',
    path: 'EU Shares\\ENX\\BNP.fr',
    full_name: 'BNP.fr',
    description: 'BNP Paribas SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/paribas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BNR.de': {
    symbol: 'BNR.de',
    path: 'EU Shares\\DE\\BNR.de',
    full_name: 'BNR.de',
    description: 'Brenntag AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brenntag-se-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BOL.fr': {
    symbol: 'BOL.fr',
    path: 'EU Shares\\ENX\\BOL.fr',
    full_name: 'BOL.fr',
    description: 'Bolloré SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/boliden-ab.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BOSS.de': {
    symbol: 'BOSS.de',
    path: 'EU Shares\\DE\\BOSS.de',
    full_name: 'BOSS.de',
    description: 'Hugo Boss AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hugo-boss.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BPOST.be': {
    symbol: 'BPOST.be',
    path: 'EU Shares\\BRU\\BPOST.be',
    full_name: 'BPOST.be',
    description: 'bpost SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/bpost.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BRE.it': {
    symbol: 'BRE.it',
    path: 'EU Shares\\MTA\\BRE.it',
    full_name: 'BRE.it',
    description: 'Brembo SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/brembo.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BVB.de': {
    symbol: 'BVB.de',
    path: 'EU Shares\\DE\\BVB.de',
    full_name: 'BVB.de',
    description: 'Borussia Dortmund GmbH & Co',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/borussia-dortmund.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BZU.it': {
    symbol: 'BZU.it',
    path: 'EU Shares\\MTA\\BZU.it',
    full_name: 'BZU.it',
    description: 'Buzzi Unicem SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/buzzi-unicem.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CA.fr': {
    symbol: 'CA.fr',
    path: 'EU Shares\\ENX\\CA.fr',
    full_name: 'CA.fr',
    description: 'Carrefour SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dws.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CABK.es': {
    symbol: 'CABK.es',
    path: 'EU Shares\\ES\\CABK.es',
    full_name: 'CABK.es',
    description: 'CaixaBank SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/caixabank.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CAI.at': {
    symbol: 'CAI.at',
    path: 'EU Shares\\WBO\\CAI.at',
    full_name: 'CAI.at',
    description: 'CA Immobilien Anlagen AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cairo-communications-spa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CAP.fr': {
    symbol: 'CAP.fr',
    path: 'EU Shares\\ENX\\CAP.fr',
    full_name: 'CAP.fr',
    description: 'Capgemini SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cap-sa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CARLB.dk': {
    symbol: 'CARLB.dk',
    path: 'EU Shares\\CSE\\CARLB.dk',
    full_name: 'CARLB.dk',
    description: 'Carlsberg A/S - Class B',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/carlsberg-b-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'CAST.se': {
    symbol: 'CAST.se',
    path: 'EU Shares\\STO\\CAST.se',
    full_name: 'CAST.se',
    description: 'Castellum AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/castellum-ab.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'CBK.de': {
    symbol: 'CBK.de',
    path: 'EU Shares\\DE\\CBK.de',
    full_name: 'CBK.de',
    description: 'Commerzbank AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commerzbank.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CDI.fr': {
    symbol: 'CDI.fr',
    path: 'EU Shares\\ENX\\CDI.fr',
    full_name: 'CDI.fr',
    description: 'Christian Dior SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dior.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CEC.de': {
    symbol: 'CEC.de',
    path: 'EU Shares\\DE\\CEC.de',
    full_name: 'CEC.de',
    description: 'Ceconomy AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ceconomy-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CGG.fr': {
    symbol: 'CGG.fr',
    path: 'EU Shares\\ENX\\CGG.fr',
    full_name: 'CGG.fr',
    description: 'CGG SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/china-gold-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CIE.es': {
    symbol: 'CIE.es',
    path: 'EU Shares\\ES\\CIE.es',
    full_name: 'CIE.es',
    description: 'CIE Automotive SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cie-automotive.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CLNX.es': {
    symbol: 'CLNX.es',
    path: 'EU Shares\\ES\\CLNX.es',
    full_name: 'CLNX.es',
    description: 'Cellnex Telecom SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cellnex-telecom.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CO.fr': {
    symbol: 'CO.fr',
    path: 'EU Shares\\ENX\\CO.fr',
    full_name: 'CO.fr',
    description: 'Casino Guichard Perrachon SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/copper.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'COL.es': {
    symbol: 'COL.es',
    path: 'EU Shares\\ES\\COL.es',
    full_name: 'COL.es',
    description: 'Inmobiliaria Colonial SOCIMI',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/inmobiliaria-colonial.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CON.de': {
    symbol: 'CON.de',
    path: 'EU Shares\\DE\\CON.de',
    full_name: 'CON.de',
    description: 'Continental AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/continental-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'COV.fr': {
    symbol: 'COV.fr',
    path: 'EU Shares\\ENX\\COV.fr',
    full_name: 'COV.fr',
    description: 'COVIVIO',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/covivio.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CRBN.nl': {
    symbol: 'CRBN.nl',
    path: 'EU Shares\\AMS\\CRBN.nl',
    full_name: 'CRBN.nl',
    description: 'Corbion NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ishares.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CS.fr': {
    symbol: 'CS.fr',
    path: 'EU Shares\\ENX\\CS.fr',
    full_name: 'CS.fr',
    description: 'AXA SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/axa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'CTT.pt': {
    symbol: 'CTT.pt',
    path: 'EU Shares\\LIS\\CTT.pt',
    full_name: 'CTT.pt',
    description: 'CTT-Correios De Portugal SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ctt-correios-de-portugal-sa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DANSK.dk': {
    symbol: 'DANSK.dk',
    path: 'EU Shares\\CSE\\DANSK.dk',
    full_name: 'DANSK.dk',
    description: 'Danske Bank A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 2,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/danske-bank-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'DB1.de': {
    symbol: 'DB1.de',
    path: 'EU Shares\\DE\\DB1.de',
    full_name: 'DB1.de',
    description: 'Deutsche Boerse AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-boerse-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DBK.de': {
    symbol: 'DBK.de',
    path: 'EU Shares\\DE\\DBK.de',
    full_name: 'DBK.de',
    description: 'Deutsche Bank AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-bank-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DEC.fr': {
    symbol: 'DEC.fr',
    path: 'EU Shares\\ENX\\DEC.fr',
    full_name: 'DEC.fr',
    description: 'JCDecaux SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diversified-energy-co-plc.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DEMAN.dk': {
    symbol: 'DEMAN.dk',
    path: 'EU Shares\\CSE\\DEMAN.dk',
    full_name: 'DEMAN.dk',
    description: 'Demant A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 3,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/demant-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'DEQ.de': {
    symbol: 'DEQ.de',
    path: 'EU Shares\\DE\\DEQ.de',
    full_name: 'DEQ.de',
    description: 'Deutsche EuroShop AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-euroshop.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DG.fr': {
    symbol: 'DG.fr',
    path: 'EU Shares\\ENX\\DG.fr',
    full_name: 'DG.fr',
    description: 'VINCI SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dollar-general.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DHER.de': {
    symbol: 'DHER.de',
    path: 'EU Shares\\DE\\DHER.de',
    full_name: 'DHER.de',
    description: 'Delivery Hero SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/delivery-hero.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DHL.de': {
    symbol: 'DHL.de',
    path: 'EU Shares\\DE\\DHL.de',
    full_name: 'DHL.de',
    description: 'DHL Group',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-post-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DIM.fr': {
    symbol: 'DIM.fr',
    path: 'EU Shares\\ENX\\DIM.fr',
    full_name: 'DIM.fr',
    description: 'Sartorius Stedim Biotech SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wisdomtree.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DSY.fr': {
    symbol: 'DSY.fr',
    path: 'EU Shares\\ENX\\DSY.fr',
    full_name: 'DSY.fr',
    description: 'Dassault Systemes SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dassault-systemes.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DTE.de': {
    symbol: 'DTE.de',
    path: 'EU Shares\\DE\\DTE.de',
    full_name: 'DTE.de',
    description: 'Deutsche Telekom AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-telekom.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DUE.de': {
    symbol: 'DUE.de',
    path: 'EU Shares\\DE\\DUE.de',
    full_name: 'DUE.de',
    description: 'Duerr AG / Dürr',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/duerr-ag-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DWNI.de': {
    symbol: 'DWNI.de',
    path: 'EU Shares\\DE\\DWNI.de',
    full_name: 'DWNI.de',
    description: 'Deutsche Wohnen SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/deutsche-wohnen-se-inh.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'DWS.de': {
    symbol: 'DWS.de',
    path: 'EU Shares\\DE\\DWS.de',
    full_name: 'DWS.de',
    description: 'DWS Group GmbH & Co KGaA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dws-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EBRO.es': {
    symbol: 'EBRO.es',
    path: 'EU Shares\\ES\\EBRO.es',
    full_name: 'EBRO.es',
    description: 'EBRO FOODS SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ebro-foods.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EDEN.fr': {
    symbol: 'EDEN.fr',
    path: 'EU Shares\\ENX\\EDEN.fr',
    full_name: 'EDEN.fr',
    description: 'EDENRED',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ishares.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EDPR.pt': {
    symbol: 'EDPR.pt',
    path: 'EU Shares\\LIS\\EDPR.pt',
    full_name: 'EDPR.pt',
    description: 'EDP Renovaveis SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/edp-renovaveis.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EKTAB.se': {
    symbol: 'EKTAB.se',
    path: 'EU Shares\\STO\\EKTAB.se',
    full_name: 'EKTAB.se',
    description: 'Elekta AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/elekta-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'EL.fr': {
    symbol: 'EL.fr',
    path: 'EU Shares\\ENX\\EL.fr',
    full_name: 'EL.fr',
    description: 'EssilorLuxottica SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/estee-lauder.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ELE.es': {
    symbol: 'ELE.es',
    path: 'EU Shares\\ES\\ELE.es',
    full_name: 'ELE.es',
    description: 'Endesa SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/endesa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EN.fr': {
    symbol: 'EN.fr',
    path: 'EU Shares\\ENX\\EN.fr',
    full_name: 'EN.fr',
    description: 'Bouygues SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/petrochemicals.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENC.es': {
    symbol: 'ENC.es',
    path: 'EU Shares\\ES\\ENC.es',
    full_name: 'ENC.es',
    description: 'ENCE Energia y Celulosa SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/emce-energia-celulo.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENEL.it': {
    symbol: 'ENEL.it',
    path: 'EU Shares\\MTA\\ENEL.it',
    full_name: 'ENEL.it',
    description: 'Enel SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enel.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENG.es': {
    symbol: 'ENG.es',
    path: 'EU Shares\\ES\\ENG.es',
    full_name: 'ENG.es',
    description: 'Enagas SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/enagas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENGI.fr': {
    symbol: 'ENGI.fr',
    path: 'EU Shares\\ENX\\ENGI.fr',
    full_name: 'ENGI.fr',
    description: 'ENGIE SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/engie.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENI.it': {
    symbol: 'ENI.it',
    path: 'EU Shares\\MTA\\ENI.it',
    full_name: 'ENI.it',
    description: 'Eni SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eni.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ENR.de': {
    symbol: 'ENR.de',
    path: 'EU Shares\\DE\\ENR.de',
    full_name: 'ENR.de',
    description: 'Siemens Energy Ag',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/siemens-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EOAN.de': {
    symbol: 'EOAN.de',
    path: 'EU Shares\\DE\\EOAN.de',
    full_name: 'EOAN.de',
    description: 'E.ON SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/e-on.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ERICB.se': {
    symbol: 'ERICB.se',
    path: 'EU Shares\\STO\\ERICB.se',
    full_name: 'ERICB.se',
    description: 'Ericsson LM - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ericsson.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'ETL.fr': {
    symbol: 'ETL.fr',
    path: 'EU Shares\\ENX\\ETL.fr',
    full_name: 'ETL.fr',
    description: 'Eutelsat Communications SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/eutelsat-communic.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EVK.de': {
    symbol: 'EVK.de',
    path: 'EU Shares\\DE\\EVK.de',
    full_name: 'EVK.de',
    description: 'Evonik Industries AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/evonik-industries-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'EVT.de': {
    symbol: 'EVT.de',
    path: 'EU Shares\\DE\\EVT.de',
    full_name: 'EVT.de',
    description: 'Evotec SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/evotec.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FIE.de': {
    symbol: 'FIE.de',
    path: 'EU Shares\\DE\\FIE.de',
    full_name: 'FIE.de',
    description: 'Fielmann AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fielmann-ag-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FLS.dk': {
    symbol: 'FLS.dk',
    path: 'EU Shares\\CSE\\FLS.dk',
    full_name: 'FLS.dk',
    description: 'FLSmidth & Co A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 4,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flowserve.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'FME.de': {
    symbol: 'FME.de',
    path: 'EU Shares\\DE\\FME.de',
    full_name: 'FME.de',
    description: 'Fresenius Medical Care AG & Co KGaA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/future-metals-nl.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FNTN.de': {
    symbol: 'FNTN.de',
    path: 'EU Shares\\DE\\FNTN.de',
    full_name: 'FNTN.de',
    description: 'freenet AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/freenet-ag-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FR.fr': {
    symbol: 'FR.fr',
    path: 'EU Shares\\ENX\\FR.fr',
    full_name: 'FR.fr',
    description: 'Valeo SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/first-industrial-realty-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FRA.de': {
    symbol: 'FRA.de',
    path: 'EU Shares\\DE\\FRA.de',
    full_name: 'FRA.de',
    description: 'Fraport AG Frankfurt Airport',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/blackrock.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FRE.de': {
    symbol: 'FRE.de',
    path: 'EU Shares\\DE\\FRE.de',
    full_name: 'FRE.de',
    description: 'Fresenius SE & Co KGaA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/fresenius-medical-care.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FTK.de': {
    symbol: 'FTK.de',
    path: 'EU Shares\\DE\\FTK.de',
    full_name: 'FTK.de',
    description: 'Flatexdegiro Ag',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/flotek.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'FUR.nl': {
    symbol: 'FUR.nl',
    path: 'EU Shares\\AMS\\FUR.nl',
    full_name: 'FUR.nl',
    description: 'FUGRO NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/furukawa-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'G.it': {
    symbol: 'G.it',
    path: 'EU Shares\\MTA\\G.it',
    full_name: 'G.it',
    description: 'Assicurazioni Generali SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/genpact.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'G1A.de': {
    symbol: 'G1A.de',
    path: 'EU Shares\\DE\\G1A.de',
    full_name: 'G1A.de',
    description: 'GEA Group AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gea-group-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'G24.de': {
    symbol: 'G24.de',
    path: 'EU Shares\\DE\\G24.de',
    full_name: 'G24.de',
    description: 'Scout24 AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/scout24-se-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GALP.pt': {
    symbol: 'GALP.pt',
    path: 'EU Shares\\LIS\\GALP.pt',
    full_name: 'GALP.pt',
    description: 'Galp Energia SGPS SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/galp-energia-nom.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GBF.de': {
    symbol: 'GBF.de',
    path: 'EU Shares\\DE\\GBF.de',
    full_name: 'GBF.de',
    description: 'Bilfinger SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ishares.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GET.fr': {
    symbol: 'GET.fr',
    path: 'EU Shares\\ENX\\GET.fr',
    full_name: 'GET.fr',
    description: 'Getlink SE (fka Groupe Eurotunnel)',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/chargeurs.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GETIB.se': {
    symbol: 'GETIB.se',
    path: 'EU Shares\\STO\\GETIB.se',
    full_name: 'GETIB.se',
    description: 'Getinge AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/getinge-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'GJF.no': {
    symbol: 'GJF.no',
    path: 'EU Shares\\OSL\\GJF.no',
    full_name: 'GJF.no',
    description: 'Gjensidige Forsikring ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gjensidige-forsikring-asa.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'GLE.fr': {
    symbol: 'GLE.fr',
    path: 'EU Shares\\ENX\\GLE.fr',
    full_name: 'GLE.fr',
    description: 'Societe Generale SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/societe-generale.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GLPG.nl': {
    symbol: 'GLPG.nl',
    path: 'EU Shares\\AMS\\GLPG.nl',
    full_name: 'GLPG.nl',
    description: 'Galapagos NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/galapagos.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GN.dk': {
    symbol: 'GN.dk',
    path: 'EU Shares\\CSE\\GN.dk',
    full_name: 'GN.dk',
    description: 'GN Store Nord A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 5,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gn-store-nord-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'GRF.es': {
    symbol: 'GRF.es',
    path: 'EU Shares\\ES\\GRF.es',
    full_name: 'GRF.es',
    description: 'Grifols SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/grifols.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'GXI.de': {
    symbol: 'GXI.de',
    path: 'EU Shares\\DE\\GXI.de',
    full_name: 'GXI.de',
    description: 'Gerresheimer AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/gerresheimer-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HEI.de': {
    symbol: 'HEI.de',
    path: 'EU Shares\\DE\\HEI.de',
    full_name: 'HEI.de',
    description: 'HeidelbergCement AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/heidelbergcement-i.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HEIA.nl': {
    symbol: 'HEIA.nl',
    path: 'EU Shares\\AMS\\HEIA.nl',
    full_name: 'HEIA.nl',
    description: 'Heineken NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/heineken.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HEN3.de': {
    symbol: 'HEN3.de',
    path: 'EU Shares\\DE\\HEN3.de',
    full_name: 'HEN3.de',
    description: 'Henkel AG & Co KgaA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/henkel.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HFG.de': {
    symbol: 'HFG.de',
    path: 'EU Shares\\DE\\HFG.de',
    full_name: 'HFG.de',
    description: 'HelloFresh SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hellofresh.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HLAG.de': {
    symbol: 'HLAG.de',
    path: 'EU Shares\\DE\\HLAG.de',
    full_name: 'HLAG.de',
    description: 'Hapag-Lloyd AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hapag-lloyd-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HLUNB.dk': {
    symbol: 'HLUNB.dk',
    path: 'EU Shares\\CSE\\HLUNB.dk',
    full_name: 'HLUNB.dk',
    description: 'H. Lundbeck A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 6,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/h-lundbeck-a-s-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'HMB.se': {
    symbol: 'HMB.se',
    path: 'EU Shares\\STO\\HMB.se',
    full_name: 'HMB.se',
    description: 'Hennes & Mauritz AB / H&M - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/habib-metropolitan-bank.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'HNR1.de': {
    symbol: 'HNR1.de',
    path: 'EU Shares\\DE\\HNR1.de',
    full_name: 'HNR1.de',
    description: 'Hannover Rueck SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hannover-rueck.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HO.fr': {
    symbol: 'HO.fr',
    path: 'EU Shares\\ENX\\HO.fr',
    full_name: 'HO.fr',
    description: 'Thales SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/diesel.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HOT.de': {
    symbol: 'HOT.de',
    path: 'EU Shares\\DE\\HOT.de',
    full_name: 'HOT.de',
    description: 'Hochtief AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hochtief.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HUFVA.se': {
    symbol: 'HUFVA.se',
    path: 'EU Shares\\STO\\HUFVA.se',
    full_name: 'HUFVA.se',
    description: 'Hufvudstaden AB - Class A',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hufvudstaden-ab-ser-a.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'HUH1V.fi': {
    symbol: 'HUH1V.fi',
    path: 'EU Shares\\HEL\\HUH1V.fi',
    full_name: 'HUH1V.fi',
    description: 'Huhtamaki Oyj',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/huhtamaki-oyj.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'HUSQB.se': {
    symbol: 'HUSQB.se',
    path: 'EU Shares\\STO\\HUSQB.se',
    full_name: 'HUSQB.se',
    description: 'Husqvarna AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/husqvarna-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'IBE.es': {
    symbol: 'IBE.es',
    path: 'EU Shares\\ES\\IBE.es',
    full_name: 'IBE.es',
    description: 'Iberdrola SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iberdrola.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'IDR.es': {
    symbol: 'IDR.es',
    path: 'EU Shares\\ES\\IDR.es',
    full_name: 'IDR.es',
    description: 'Indra Sistemas SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/idaho-strategic-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'IFX.de': {
    symbol: 'IFX.de',
    path: 'EU Shares\\DE\\IFX.de',
    full_name: 'IFX.de',
    description: 'Infineon AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/infineon-technologies.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'IMCD.nl': {
    symbol: 'IMCD.nl',
    path: 'EU Shares\\AMS\\IMCD.nl',
    full_name: 'IMCD.nl',
    description: 'IMCD NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/imcd.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'INGA.nl': {
    symbol: 'INGA.nl',
    path: 'EU Shares\\AMS\\INGA.nl',
    full_name: 'INGA.nl',
    description: 'ING Groep NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ing.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'INTRU.se': {
    symbol: 'INTRU.se',
    path: 'EU Shares\\STO\\INTRU.se',
    full_name: 'INTRU.se',
    description: 'Intrum AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intrum-ab.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'IPN.fr': {
    symbol: 'IPN.fr',
    path: 'EU Shares\\ENX\\IPN.fr',
    full_name: 'IPN.fr',
    description: 'Ipsen SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ipsen.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ISP.it': {
    symbol: 'ISP.it',
    path: 'EU Shares\\MTA\\ISP.it',
    full_name: 'ISP.it',
    description: 'Intesa Sanpaolo SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/intesa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ISS.dk': {
    symbol: 'ISS.dk',
    path: 'EU Shares\\CSE\\ISS.dk',
    full_name: 'ISS.dk',
    description: 'ISS A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 7,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/iss.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'ITX.es': {
    symbol: 'ITX.es',
    path: 'EU Shares\\ES\\ITX.es',
    full_name: 'ITX.es',
    description: 'Inditex SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/inditex.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'JMT.pt': {
    symbol: 'JMT.pt',
    path: 'EU Shares\\LIS\\JMT.pt',
    full_name: 'JMT.pt',
    description: 'Jeronimo Martins SGPS SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jmt-network-services.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'JUVE.it': {
    symbol: 'JUVE.it',
    path: 'EU Shares\\MTA\\JUVE.it',
    full_name: 'JUVE.it',
    description: 'Juventus Football Club SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/juventus-fc.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'JYSK.dk': {
    symbol: 'JYSK.dk',
    path: 'EU Shares\\CSE\\JYSK.dk',
    full_name: 'JYSK.dk',
    description: 'Jyske Bank A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 8,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/jyske-bank-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'KBC.be': {
    symbol: 'KBC.be',
    path: 'EU Shares\\BRU\\KBC.be',
    full_name: 'KBC.be',
    description: 'KBC Group NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kinh-bac-city.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KBX.de': {
    symbol: 'KBX.de',
    path: 'EU Shares\\DE\\KBX.de',
    full_name: 'KBX.de',
    description: 'Knorr-Bremse AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/knorr-bremse-ag-inh-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KCO.de': {
    symbol: 'KCO.de',
    path: 'EU Shares\\DE\\KCO.de',
    full_name: 'KCO.de',
    description: 'KLOECKNER & CO SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kloeckner-and-co-se.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KER.fr': {
    symbol: 'KER.fr',
    path: 'EU Shares\\ENX\\KER.fr',
    full_name: 'KER.fr',
    description: 'Kering SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kering.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KESKB.fi': {
    symbol: 'KESKB.fi',
    path: 'EU Shares\\HEL\\KESKB.fi',
    full_name: 'KESKB.fi',
    description: 'Kesko Oyj - Class B',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kesko-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KGX.de': {
    symbol: 'KGX.de',
    path: 'EU Shares\\DE\\KGX.de',
    full_name: 'KGX.de',
    description: 'KION Group AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kion-group-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KNEBV.fi': {
    symbol: 'KNEBV.fi',
    path: 'EU Shares\\HEL\\KNEBV.fi',
    full_name: 'KNEBV.fi',
    description: 'Kone Oyj - Class B',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kone.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KPN.nl': {
    symbol: 'KPN.nl',
    path: 'EU Shares\\AMS\\KPN.nl',
    full_name: 'KPN.nl',
    description: 'Koninklijke KPN NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/kpn-kon.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'KRN.de': {
    symbol: 'KRN.de',
    path: 'EU Shares\\DE\\KRN.de',
    full_name: 'KRN.de',
    description: 'Krones AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/krones-ag-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LDO.it': {
    symbol: 'LDO.it',
    path: 'EU Shares\\MTA\\LDO.it',
    full_name: 'LDO.it',
    description: 'Leonardo SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/leonardo.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LEG.de': {
    symbol: 'LEG.de',
    path: 'EU Shares\\DE\\LEG.de',
    full_name: 'LEG.de',
    description: 'LEG Immobilien AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/legget-platt.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LHA.de': {
    symbol: 'LHA.de',
    path: 'EU Shares\\DE\\LHA.de',
    full_name: 'LHA.de',
    description: 'Lufthansa AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lufthansa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LI.fr': {
    symbol: 'LI.fr',
    path: 'EU Shares\\ENX\\LI.fr',
    full_name: 'LI.fr',
    description: 'Klépierre SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/li.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LR.fr': {
    symbol: 'LR.fr',
    path: 'EU Shares\\ENX\\LR.fr',
    full_name: 'LR.fr',
    description: 'Legrand SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/legrand.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LXS.de': {
    symbol: 'LXS.de',
    path: 'EU Shares\\DE\\LXS.de',
    full_name: 'LXS.de',
    description: 'Lanxess AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lanxess-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MAP.es': {
    symbol: 'MAP.es',
    path: 'EU Shares\\ES\\MAP.es',
    full_name: 'MAP.es',
    description: 'Mapfre SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 4,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mapfre.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MB.it': {
    symbol: 'MB.it',
    path: 'EU Shares\\MTA\\MB.it',
    full_name: 'MB.it',
    description: 'Mediobanca SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mediobanca.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MBG.de': {
    symbol: 'MBG.de',
    path: 'EU Shares\\DE\\MBG.de',
    full_name: 'MBG.de',
    description: 'Mercedes-Benz Group AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/daimler.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MC.fr': {
    symbol: 'MC.fr',
    path: 'EU Shares\\ENX\\MC.fr',
    full_name: 'MC.fr',
    description: 'LVMH Group SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lvmh.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MEL.es': {
    symbol: 'MEL.es',
    path: 'EU Shares\\ES\\MEL.es',
    full_name: 'MEL.es',
    description: 'Melia Hotels International SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/melia-hotels-international.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MERY.fr': {
    symbol: 'MERY.fr',
    path: 'EU Shares\\ENX\\MERY.fr',
    full_name: 'MERY.fr',
    description: 'MERCIALYS',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mercialys.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ML.fr': {
    symbol: 'ML.fr',
    path: 'EU Shares\\ENX\\ML.fr',
    full_name: 'ML.fr',
    description: 'Michelin (CGDE)',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/moneylion.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MMB.fr': {
    symbol: 'MMB.fr',
    path: 'EU Shares\\ENX\\MMB.fr',
    full_name: 'MMB.fr',
    description: 'Lagardère SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/lagardere-sa.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MOWI.no': {
    symbol: 'MOWI.no',
    path: 'EU Shares\\OSL\\MOWI.no',
    full_name: 'MOWI.no',
    description: 'Mowi ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/mowi.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'MRK.de': {
    symbol: 'MRK.de',
    path: 'EU Shares\\DE\\MRK.de',
    full_name: 'MRK.de',
    description: 'Merck KGaA',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/merck-and-co.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MRL.es': {
    symbol: 'MRL.es',
    path: 'EU Shares\\ES\\MRL.es',
    full_name: 'MRL.es',
    description: 'Merlin Properties Socimi SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/merlin-properties.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MRSKB.dk': {
    symbol: 'MRSKB.dk',
    path: 'EU Shares\\CSE\\MRSKB.dk',
    full_name: 'MRSKB.dk',
    description: 'AP Moller-Maersk A/S - Class B',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 9,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/a-p-moller-maersk.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'MTS.es': {
    symbol: 'MTS.es',
    path: 'EU Shares\\ES\\MTS.es',
    full_name: 'MTS.es',
    description: 'ArcelorMittal SA [MAD]',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/arcelormittal.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MTX.de': {
    symbol: 'MTX.de',
    path: 'EU Shares\\DE\\MTX.de',
    full_name: 'MTX.de',
    description: 'MTU Aero Engines AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/minerals-technologies-inc.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'MUV2.de': {
    symbol: 'MUV2.de',
    path: 'EU Shares\\DE\\MUV2.de',
    full_name: 'MUV2.de',
    description: 'Muenchener Rueckversicherungs-Gesellschaft AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/munichre.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NAS.no': {
    symbol: 'NAS.no',
    path: 'EU Shares\\OSL\\NAS.no',
    full_name: 'NAS.no',
    description: 'Norwegian Air Shuttle ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/norwegian-air-shuttle.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'NDA.de': {
    symbol: 'NDA.de',
    path: 'EU Shares\\DE\\NDA.de',
    full_name: 'NDA.de',
    description: 'Aurubis AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/aurubis-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NDX1.de': {
    symbol: 'NDX1.de',
    path: 'EU Shares\\DE\\NDX1.de',
    full_name: 'NDX1.de',
    description: 'Nordex SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nordex.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NEM.de': {
    symbol: 'NEM.de',
    path: 'EU Shares\\DE\\NEM.de',
    full_name: 'NEM.de',
    description: 'Nemetschek SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/newmont.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NESTE.fi': {
    symbol: 'NESTE.fi',
    path: 'EU Shares\\HEL\\NESTE.fi',
    full_name: 'NESTE.fi',
    description: 'Neste Oyj',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/neste.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NEX.fr': {
    symbol: 'NEX.fr',
    path: 'EU Shares\\ENX\\NEX.fr',
    full_name: 'NEX.fr',
    description: 'Nexans SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nex-point.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NHY.no': {
    symbol: 'NHY.no',
    path: 'EU Shares\\OSL\\NHY.no',
    full_name: 'NHY.no',
    description: 'Norsk Hydro ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/norsk-hydro.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'NK.fr': {
    symbol: 'NK.fr',
    path: 'EU Shares\\ENX\\NK.fr',
    full_name: 'NK.fr',
    description: 'Imerys SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/nikkei-225.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NKT.dk': {
    symbol: 'NKT.dk',
    path: 'EU Shares\\CSE\\NKT.dk',
    full_name: 'NKT.dk',
    description: 'NKT A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 10,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nkt-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'NN.nl': {
    symbol: 'NN.nl',
    path: 'EU Shares\\AMS\\NN.nl',
    full_name: 'NN.nl',
    description: 'NN Group NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/nextnav.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NOEJ.de': {
    symbol: 'NOEJ.de',
    path: 'EU Shares\\DE\\NOEJ.de',
    full_name: 'NOEJ.de',
    description: 'Norma Group SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/norma-se.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'NTGY.es': {
    symbol: 'NTGY.es',
    path: 'EU Shares\\ES\\NTGY.es',
    full_name: 'NTGY.es',
    description: 'Naturgy Energy Group SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/naturgy.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'O2D.de': {
    symbol: 'O2D.de',
    path: 'EU Shares\\DE\\O2D.de',
    full_name: 'O2D.de',
    description: 'Telefonica Deutschland Holdings',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 3,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telefonica.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'OMV.at': {
    symbol: 'OMV.at',
    path: 'EU Shares\\WBO\\OMV.at',
    full_name: 'OMV.at',
    description: 'OMV AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/omv-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'OR.fr': {
    symbol: 'OR.fr',
    path: 'EU Shares\\ENX\\OR.fr',
    full_name: 'OR.fr',
    description: "L'Oreal SA",
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ptt-oil-and-retail.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ORA.fr': {
    symbol: 'ORA.fr',
    path: 'EU Shares\\ENX\\ORA.fr',
    full_name: 'ORA.fr',
    description: 'Orange SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/orange.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ORNBV.fi': {
    symbol: 'ORNBV.fi',
    path: 'EU Shares\\HEL\\ORNBV.fi',
    full_name: 'ORNBV.fi',
    description: 'Orion Oyj-Class B',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/orion.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'P911.de': {
    symbol: 'P911.de',
    path: 'EU Shares\\DE\\P911.de',
    full_name: 'P911.de',
    description: 'Dr ING HC F Porsche AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/porsche-automobil-holding.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PAH3.de': {
    symbol: 'PAH3.de',
    path: 'EU Shares\\DE\\PAH3.de',
    full_name: 'PAH3.de',
    description: 'Porsche Automobil Hldg (Prf)',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/porsche-automobil-holding.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PBB.de': {
    symbol: 'PBB.de',
    path: 'EU Shares\\DE\\PBB.de',
    full_name: 'PBB.de',
    description: 'Deutsche Pfandbriefbank AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/dt-pfandbriefbk-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PHIA.nl': {
    symbol: 'PHIA.nl',
    path: 'EU Shares\\AMS\\PHIA.nl',
    full_name: 'PHIA.nl',
    description: 'Koninklijke Philips NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/philips.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PIRC.it': {
    symbol: 'PIRC.it',
    path: 'EU Shares\\MTA\\PIRC.it',
    full_name: 'PIRC.it',
    description: 'Pirelli & C. SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pirelli-and-c.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PNDR.dk': {
    symbol: 'PNDR.dk',
    path: 'EU Shares\\CSE\\PNDR.dk',
    full_name: 'PNDR.dk',
    description: 'Pandora A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 11,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pandora-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'PNL.nl': {
    symbol: 'PNL.nl',
    path: 'EU Shares\\AMS\\PNL.nl',
    full_name: 'PNL.nl',
    description: 'PostNL NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/personal-assets-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'POM.fr': {
    symbol: 'POM.fr',
    path: 'EU Shares\\ENX\\POM.fr',
    full_name: 'POM.fr',
    description: 'Plastic Omnium',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/plastic-omnium.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PRX.nl': {
    symbol: 'PRX.nl',
    path: 'EU Shares\\AMS\\PRX.nl',
    full_name: 'PRX.nl',
    description: 'Prosus NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prosus.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PRY.it': {
    symbol: 'PRY.it',
    path: 'EU Shares\\MTA\\PRY.it',
    full_name: 'PRY.it',
    description: 'Prysmian SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prysmian.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PSM.de': {
    symbol: 'PSM.de',
    path: 'EU Shares\\DE\\PSM.de',
    full_name: 'PSM.de',
    description: 'Prosiebensat.1 Media SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/prosiebensat-1-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PST.it': {
    symbol: 'PST.it',
    path: 'EU Shares\\MTA\\PST.it',
    full_name: 'PST.it',
    description: 'Poste Italiane SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/proshares.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PUB.fr': {
    symbol: 'PUB.fr',
    path: 'EU Shares\\ENX\\PUB.fr',
    full_name: 'PUB.fr',
    description: 'Publicis Groupe',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pancontinental-energy-nl.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'PUM.de': {
    symbol: 'PUM.de',
    path: 'EU Shares\\DE\\PUM.de',
    full_name: 'PUM.de',
    description: 'Puma SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/puma.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'QIA.de': {
    symbol: 'QIA.de',
    path: 'EU Shares\\DE\\QIA.de',
    full_name: 'QIA.de',
    description: 'QIAGEN NV',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/qiagen-nv.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RAA.de': {
    symbol: 'RAA.de',
    path: 'EU Shares\\DE\\RAA.de',
    full_name: 'RAA.de',
    description: 'Rational AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rational-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RAND.nl': {
    symbol: 'RAND.nl',
    path: 'EU Shares\\AMS\\RAND.nl',
    full_name: 'RAND.nl',
    description: 'Randstad NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rand-capital.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'REC.it': {
    symbol: 'REC.it',
    path: 'EU Shares\\MTA\\REC.it',
    full_name: 'REC.it',
    description: 'Recordati Industria Chimica e Farma SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/recordati-ord.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RECSI.no': {
    symbol: 'RECSI.no',
    path: 'EU Shares\\OSL\\RECSI.no',
    full_name: 'RECSI.no',
    description: 'REC Silicon ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rec-silicon.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'RED.es': {
    symbol: 'RED.es',
    path: 'EU Shares\\ES\\RED.es',
    full_name: 'RED.es',
    description: 'Red Electrica Corporacion SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/red-electrica.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'REL.nl': {
    symbol: 'REL.nl',
    path: 'EU Shares\\AMS\\REL.nl',
    full_name: 'REL.nl',
    description: 'Relx PLC [AEX]',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/relx.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'REP.es': {
    symbol: 'REP.es',
    path: 'EU Shares\\ES\\REP.es',
    full_name: 'REP.es',
    description: 'Repsol SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/repsol.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RHK.de': {
    symbol: 'RHK.de',
    path: 'EU Shares\\DE\\RHK.de',
    full_name: 'RHK.de',
    description: 'RHOEN-KLINIKUM AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rhoen-klinikum-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RHM.de': {
    symbol: 'RHM.de',
    path: 'EU Shares\\DE\\RHM.de',
    full_name: 'RHM.de',
    description: 'Rheinmetall AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rheinmetall-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RI.fr': {
    symbol: 'RI.fr',
    path: 'EU Shares\\ENX\\RI.fr',
    full_name: 'RI.fr',
    description: 'Pernod Ricard SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/rtsi.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RMS.fr': {
    symbol: 'RMS.fr',
    path: 'EU Shares\\ENX\\RMS.fr',
    full_name: 'RMS.fr',
    description: 'Hermes International SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/hermes.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RNO.fr': {
    symbol: 'RNO.fr',
    path: 'EU Shares\\ENX\\RNO.fr',
    full_name: 'RNO.fr',
    description: 'Renault SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/renault.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RRTL.de': {
    symbol: 'RRTL.de',
    path: 'EU Shares\\DE\\RRTL.de',
    full_name: 'RRTL.de',
    description: 'RTL Group',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rtlgroup.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RUI.fr': {
    symbol: 'RUI.fr',
    path: 'EU Shares\\ENX\\RUI.fr',
    full_name: 'RUI.fr',
    description: 'Rubis',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rubis.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RWE.de': {
    symbol: 'RWE.de',
    path: 'EU Shares\\DE\\RWE.de',
    full_name: 'RWE.de',
    description: 'RWE AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/rwe.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'RXL.fr': {
    symbol: 'RXL.fr',
    path: 'EU Shares\\ENX\\RXL.fr',
    full_name: 'RXL.fr',
    description: 'Rexel SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/proshares.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'S92.de': {
    symbol: 'S92.de',
    path: 'EU Shares\\DE\\S92.de',
    full_name: 'S92.de',
    description: 'SMA Solar Technology AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sma-solar-technology.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SAB.es': {
    symbol: 'SAB.es',
    path: 'EU Shares\\ES\\SAB.es',
    full_name: 'SAB.es',
    description: 'Banco de Sabadell SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 4,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/banco-de-sabadell.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SAF.fr': {
    symbol: 'SAF.fr',
    path: 'EU Shares\\ENX\\SAF.fr',
    full_name: 'SAF.fr',
    description: 'Safran SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/safran.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SAN.es': {
    symbol: 'SAN.es',
    path: 'EU Shares\\ES\\SAN.es',
    full_name: 'SAN.es',
    description: 'Banco Santander SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 4,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/santander.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SAN.fr': {
    symbol: 'SAN.fr',
    path: 'EU Shares\\ENX\\SAN.fr',
    full_name: 'SAN.fr',
    description: 'Sanofi SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/santander.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SAND.se': {
    symbol: 'SAND.se',
    path: 'EU Shares\\STO\\SAND.se',
    full_name: 'SAND.se',
    description: 'Sandvik AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sandstorm-gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'SAP.de': {
    symbol: 'SAP.de',
    path: 'EU Shares\\DE\\SAP.de',
    full_name: 'SAP.de',
    description: 'SAP SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sap.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SCR.fr': {
    symbol: 'SCR.fr',
    path: 'EU Shares\\ENX\\SCR.fr',
    full_name: 'SCR.fr',
    description: 'SCOR SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/pipestone-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SCYR.es': {
    symbol: 'SCYR.es',
    path: 'EU Shares\\ES\\SCYR.es',
    full_name: 'SCYR.es',
    description: 'Sacyr SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sacyr.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SDF.de': {
    symbol: 'SDF.de',
    path: 'EU Shares\\DE\\SDF.de',
    full_name: 'SDF.de',
    description: 'K+S AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/k-s-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SEBA.se': {
    symbol: 'SEBA.se',
    path: 'EU Shares\\STO\\SEBA.se',
    full_name: 'SEBA.se',
    description: 'Skandinaviska Enskilda Banken AB / SEB - Class A',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/skandinaviska-enskilda-banken-ser-a.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'SESG.fr': {
    symbol: 'SESG.fr',
    path: 'EU Shares\\ENX\\SESG.fr',
    full_name: 'SESG.fr',
    description: 'SES SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ses.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SFER.it': {
    symbol: 'SFER.it',
    path: 'EU Shares\\MTA\\SFER.it',
    full_name: 'SFER.it',
    description: 'Salvatore Ferragamo SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/salvatore-ferragamo.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SGL.de': {
    symbol: 'SGL.de',
    path: 'EU Shares\\DE\\SGL.de',
    full_name: 'SGL.de',
    description: 'SGL CARBON SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sgl.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SGO.fr': {
    symbol: 'SGO.fr',
    path: 'EU Shares\\ENX\\SGO.fr',
    full_name: 'SGO.fr',
    description: 'Compagnie de Saint-Gobain SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/saint-gobain.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SHBA.se': {
    symbol: 'SHBA.se',
    path: 'EU Shares\\STO\\SHBA.se',
    full_name: 'SHBA.se',
    description: 'Svenska Handelsbanken AB - Class A',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/svenska-handelsbanken-ser-a.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'SHL.de': {
    symbol: 'SHL.de',
    path: 'EU Shares\\DE\\SHL.de',
    full_name: 'SHL.de',
    description: 'Siemens Healthineers AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/siemens-health-ag-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SIE.de': {
    symbol: 'SIE.de',
    path: 'EU Shares\\DE\\SIE.de',
    full_name: 'SIE.de',
    description: 'Siemens AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/siemens.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SMHN.de': {
    symbol: 'SMHN.de',
    path: 'EU Shares\\DE\\SMHN.de',
    full_name: 'SMHN.de',
    description: 'SUESS MICROTEC SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/suess-microtec-se.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SON.pt': {
    symbol: 'SON.pt',
    path: 'EU Shares\\LIS\\SON.pt',
    full_name: 'SON.pt',
    description: 'Sonae SGPS SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sonoco.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SOW.de': {
    symbol: 'SOW.de',
    path: 'EU Shares\\DE\\SOW.de',
    full_name: 'SOW.de',
    description: 'Software AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/software-ag-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SRG.it': {
    symbol: 'SRG.it',
    path: 'EU Shares\\MTA\\SRG.it',
    full_name: 'SRG.it',
    description: 'Snam SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/seritage-growth-properties.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SRT3.de': {
    symbol: 'SRT3.de',
    path: 'EU Shares\\DE\\SRT3.de',
    full_name: 'SRT3.de',
    description: 'Sartorius AG (Pfd)',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sartorius.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'STLA.fr': {
    symbol: 'STLA.fr',
    path: 'EU Shares\\ENX\\STLA.fr',
    full_name: 'STLA.fr',
    description: 'Stellantis NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stellantis.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'STMPA.fr': {
    symbol: 'STMPA.fr',
    path: 'EU Shares\\ENX\\STMPA.fr',
    full_name: 'STMPA.fr',
    description: 'STMicroelectronics NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/stmicroelectronics.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SU.fr': {
    symbol: 'SU.fr',
    path: 'EU Shares\\ENX\\SU.fr',
    full_name: 'SU.fr',
    description: 'Schneider Electric SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/suncor-energy.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SUBC.no': {
    symbol: 'SUBC.no',
    path: 'EU Shares\\OSL\\SUBC.no',
    full_name: 'SUBC.no',
    description: 'Subsea 7 SA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/subsea-7.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'SW.fr': {
    symbol: 'SW.fr',
    path: 'EU Shares\\ENX\\SW.fr',
    full_name: 'SW.fr',
    description: 'Sodexo SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sodexo.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SWEDA.se': {
    symbol: 'SWEDA.se',
    path: 'EU Shares\\STO\\SWEDA.se',
    full_name: 'SWEDA.se',
    description: 'Swedbank AB',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/swedbank-ab-ser-a.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'SY1.de': {
    symbol: 'SY1.de',
    path: 'EU Shares\\DE\\SY1.de',
    full_name: 'SY1.de',
    description: 'Symrise AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/symrise-ag-inh-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SYDB.dk': {
    symbol: 'SYDB.dk',
    path: 'EU Shares\\CSE\\SYDB.dk',
    full_name: 'SYDB.dk',
    description: 'Sydbank A/S',
    type: 'eu stocks',
    session: '1;0702-1455:2|0702-1455:3|0702-1455:4|0702-1455:5|0702-1455:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 12,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/sydbank-a-s.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'SZG.de': {
    symbol: 'SZG.de',
    path: 'EU Shares\\DE\\SZG.de',
    full_name: 'SZG.de',
    description: 'Salzgitter AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/salzgitter-ag-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'SZU.de': {
    symbol: 'SZU.de',
    path: 'EU Shares\\DE\\SZU.de',
    full_name: 'SZU.de',
    description: 'Suedzucker AG / Südzucker',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/suedzucker-ag-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TEF.es': {
    symbol: 'TEF.es',
    path: 'EU Shares\\ES\\TEF.es',
    full_name: 'TEF.es',
    description: 'Telefonica SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 4,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telefonica.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TEG.de': {
    symbol: 'TEG.de',
    path: 'EU Shares\\DE\\TEG.de',
    full_name: 'TEG.de',
    description: 'TAG Immobilien AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tag-immobilien-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TEL2B.se': {
    symbol: 'TEL2B.se',
    path: 'EU Shares\\STO\\TEL2B.se',
    full_name: 'TEL2B.se',
    description: 'Tele2 AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tele2-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'TEN.it': {
    symbol: 'TEN.it',
    path: 'EU Shares\\MTA\\TEN.it',
    full_name: 'TEN.it',
    description: 'Tenaris SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tenaris.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TEP.fr': {
    symbol: 'TEP.fr',
    path: 'EU Shares\\ENX\\TEP.fr',
    full_name: 'TEP.fr',
    description: 'TELEPERFORMANCE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/teleperformance.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TFI.fr': {
    symbol: 'TFI.fr',
    path: 'EU Shares\\ENX\\TFI.fr',
    full_name: 'TFI.fr',
    description: 'TELEVISION FRANCAISE (T.F.1)',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/spdr-sandp500-etf-tr.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TGS.no': {
    symbol: 'TGS.no',
    path: 'EU Shares\\OSL\\TGS.no',
    full_name: 'TGS.no',
    description: 'TGS ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/transport-gas-sur.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'TIETO.fi': {
    symbol: 'TIETO.fi',
    path: 'EU Shares\\HEL\\TIETO.fi',
    full_name: 'TIETO.fi',
    description: 'TietoEVRY Oyj',
    type: 'eu stocks',
    session: '1;0802-1625:2|0802-1625:3|0802-1625:4|0802-1625:5|0802-1625:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tietoevry.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TIGO.se': {
    symbol: 'TIGO.se',
    path: 'EU Shares\\STO\\TIGO.se',
    full_name: 'TIGO.se',
    description: 'Millicom Intl Cellular SA',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/millicom.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'TIT.it': {
    symbol: 'TIT.it',
    path: 'EU Shares\\MTA\\TIT.it',
    full_name: 'TIT.it',
    description: 'Telecom Italia SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telecom-italia.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TKA.at': {
    symbol: 'TKA.at',
    path: 'EU Shares\\WBO\\TKA.at',
    full_name: 'TKA.at',
    description: 'Telekom Austria AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/thyssenkrupp.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TKA.de': {
    symbol: 'TKA.de',
    path: 'EU Shares\\DE\\TKA.de',
    full_name: 'TKA.de',
    description: 'thyssenkrupp AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/thyssenkrupp.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TKWY.nl': {
    symbol: 'TKWY.nl',
    path: 'EU Shares\\AMS\\TKWY.nl',
    full_name: 'TKWY.nl',
    description: 'Takeaway.com NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/just-eat-takeaway.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TLX.de': {
    symbol: 'TLX.de',
    path: 'EU Shares\\DE\\TLX.de',
    full_name: 'TLX.de',
    description: 'Talanx AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telix-pharmaceuticals-limited.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TOM2.nl': {
    symbol: 'TOM2.nl',
    path: 'EU Shares\\AMS\\TOM2.nl',
    full_name: 'TOM2.nl',
    description: 'TomTom NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tomtom.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TRE.es': {
    symbol: 'TRE.es',
    path: 'EU Shares\\ES\\TRE.es',
    full_name: 'TRE.es',
    description: 'Tecnicas Reunidas SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tecnicas-reunidas.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TRN.it': {
    symbol: 'TRN.it',
    path: 'EU Shares\\MTA\\TRN.it',
    full_name: 'TRN.it',
    description: 'Terna SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/terna.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TTE.fr': {
    symbol: 'TTE.fr',
    path: 'EU Shares\\ENX\\TTE.fr',
    full_name: 'TTE.fr',
    description: 'TotalEnergies SE',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/total.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'TUI1.de': {
    symbol: 'TUI1.de',
    path: 'EU Shares\\DE\\TUI1.de',
    full_name: 'TUI1.de',
    description: 'TUI AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/tui.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UBI.fr': {
    symbol: 'UBI.fr',
    path: 'EU Shares\\ENX\\UBI.fr',
    full_name: 'UBI.fr',
    description: 'Ubisoft Entertainment SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/ubisoft.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UCB.be': {
    symbol: 'UCB.be',
    path: 'EU Shares\\BRU\\UCB.be',
    full_name: 'UCB.be',
    description: 'UCB SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/united-commercial-bank-ltd-ucbl.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UCG.it': {
    symbol: 'UCG.it',
    path: 'EU Shares\\MTA\\UCG.it',
    full_name: 'UCG.it',
    description: 'Unicredit SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unicredit.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UN01.de': {
    symbol: 'UN01.de',
    path: 'EU Shares\\DE\\UN01.de',
    full_name: 'UN01.de',
    description: 'Uniper SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/uniper-se-na-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UNA.nl': {
    symbol: 'UNA.nl',
    path: 'EU Shares\\AMS\\UNA.nl',
    full_name: 'UNA.nl',
    description: 'Unilever NV [AEX]',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unilever.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UQA.at': {
    symbol: 'UQA.at',
    path: 'EU Shares\\WBO\\UQA.at',
    full_name: 'UQA.at',
    description: 'UNIQA Insurance Group AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/uniqa-insurance-group-ag.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'URW.fr': {
    symbol: 'URW.fr',
    path: 'EU Shares\\ENX\\URW.fr',
    full_name: 'URW.fr',
    description: 'Unibail-Rodamco-Westfield',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unibail-rodamco-westfield.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'US.it': {
    symbol: 'US.it',
    path: 'EU Shares\\MTA\\US.it',
    full_name: 'US.it',
    description: 'UnipolSai Assicurazioni SpA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/unipolsai.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'UTDI.de': {
    symbol: 'UTDI.de',
    path: 'EU Shares\\DE\\UTDI.de',
    full_name: 'UTDI.de',
    description: 'United Internet AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/utd-internet-ag-na.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VAR1.de': {
    symbol: 'VAR1.de',
    path: 'EU Shares\\DE\\VAR1.de',
    full_name: 'VAR1.de',
    description: 'VARTA AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/varta.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VER.at': {
    symbol: 'VER.at',
    path: 'EU Shares\\WBO\\VER.at',
    full_name: 'VER.at',
    description: 'Verbund AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/verbund-ag-inh-a.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VIE.fr': {
    symbol: 'VIE.fr',
    path: 'EU Shares\\ENX\\VIE.fr',
    full_name: 'VIE.fr',
    description: 'Veolia Environnement SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/veolia-environnement.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VIS.es': {
    symbol: 'VIS.es',
    path: 'EU Shares\\ES\\VIS.es',
    full_name: 'VIS.es',
    description: 'Viscofan SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vanguard.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VIV.fr': {
    symbol: 'VIV.fr',
    path: 'EU Shares\\ENX\\VIV.fr',
    full_name: 'VIV.fr',
    description: 'Vivendi SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/telefonica.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VK.fr': {
    symbol: 'VK.fr',
    path: 'EU Shares\\ENX\\VK.fr',
    full_name: 'VK.fr',
    description: 'VALLOUREC SA',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vallourec.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VNA.de': {
    symbol: 'VNA.de',
    path: 'EU Shares\\DE\\VNA.de',
    full_name: 'VNA.de',
    description: 'Vonovia SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vonovia.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VOE.at': {
    symbol: 'VOE.at',
    path: 'EU Shares\\WBO\\VOE.at',
    full_name: 'VOE.at',
    description: 'voestalpine AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vanguard.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VOLVB.se': {
    symbol: 'VOLVB.se',
    path: 'EU Shares\\STO\\VOLVB.se',
    full_name: 'VOLVB.se',
    description: 'Volvo AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/volvo-car.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'VOW.de': {
    symbol: 'VOW.de',
    path: 'EU Shares\\DE\\VOW.de',
    full_name: 'VOW.de',
    description: 'Volkswagen AG (Ord)',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/volkswagen.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VOW3.de': {
    symbol: 'VOW3.de',
    path: 'EU Shares\\DE\\VOW3.de',
    full_name: 'VOW3.de',
    description: 'Volkswagen AG (Pfd)',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/volkswagen.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'VPK.nl': {
    symbol: 'VPK.nl',
    path: 'EU Shares\\AMS\\VPK.nl',
    full_name: 'VPK.nl',
    description: 'Koninklijke Vopak NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/vopak.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'WAF.de': {
    symbol: 'WAF.de',
    path: 'EU Shares\\DE\\WAF.de',
    full_name: 'WAF.de',
    description: 'Siltronic AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/west-african-resources.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'WALLB.se': {
    symbol: 'WALLB.se',
    path: 'EU Shares\\STO\\WALLB.se',
    full_name: 'WALLB.se',
    description: 'Wallenstam AB - Class B',
    type: 'eu stocks',
    session: '1;0702-1525:2|0702-1525:3|0702-1525:4|0702-1525:5|0702-1525:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wallenstam-ab-ser-b.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'WCH.de': {
    symbol: 'WCH.de',
    path: 'EU Shares\\DE\\WCH.de',
    full_name: 'WCH.de',
    description: 'Wacker Chemie AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wacker-chemie-o-n.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'WHA.nl': {
    symbol: 'WHA.nl',
    path: 'EU Shares\\AMS\\WHA.nl',
    full_name: 'WHA.nl',
    description: 'WERELDHAVE NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wha-group.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'WIE.at': {
    symbol: 'WIE.at',
    path: 'EU Shares\\WBO\\WIE.at',
    full_name: 'WIE.at',
    description: 'Wienerberger AG',
    type: 'eu stocks',
    session: '1;0703-1530:2|0703-1530:3|0703-1530:4|0703-1530:5|0703-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wienerberger.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'WKL.nl': {
    symbol: 'WKL.nl',
    path: 'EU Shares\\AMS\\WKL.nl',
    full_name: 'WKL.nl',
    description: 'Wolters Kluwer NV',
    type: 'eu stocks',
    session: '1;0702-1530:2|0702-1530:3|0702-1530:4|0702-1530:5|0702-1530:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/wolters-kluwer.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'YAR.no': {
    symbol: 'YAR.no',
    path: 'EU Shares\\OSL\\YAR.no',
    full_name: 'YAR.no',
    description: 'Yara International ASA',
    type: 'eu stocks',
    session: '1;0702-1420:2|0702-1420:3|0702-1420:4|0702-1420:5|0702-1420:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/americold-realty-trust.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'ZAL.de': {
    symbol: 'ZAL.de',
    path: 'EU Shares\\DE\\ZAL.de',
    full_name: 'ZAL.de',
    description: 'Zalando SE',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/zalando.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ZIL2.de': {
    symbol: 'ZIL2.de',
    path: 'EU Shares\\DE\\ZIL2.de',
    full_name: 'ZIL2.de',
    description: 'ElringKlinger AG',
    type: 'eu stocks',
    session: '1;0602-1428:2|0602-1428:3|0602-1428:4|0602-1428:5|0602-1428:6',
    decimals: 2,
    qty: {
      min: 11,
      max: 1000000,
      step: 1,
      default: 11,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/elringklinger-ag-na-on.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
};

export const crypto_symbols = {
  'ADAUSD.nx': {
    symbol: 'ADAUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ADAUSD.nx',
    full_name: 'ADAUSD.nx',
    description: 'Cardano',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCADA.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'ALGOUSD.nx': {
    symbol: 'ALGOUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ALGOUSD.nx',
    full_name: 'ALGOUSD.nx',
    description: 'Algorand',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCALGO.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'APEUSD.nx': {
    symbol: 'APEUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\APEUSD.nx',
    full_name: 'APEUSD.nx',
    description: 'ApeCoin / US dollar',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCAPE.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'ATOMUSD.nx': {
    symbol: 'ATOMUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ATOMUSD.nx',
    full_name: 'ATOMUSD.nx',
    description: 'Cosmos',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 3,
    qty: {
      min: 10,
      max: 100000000,
      step: 10,
      default: 10,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCATOM.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'AXSUSD.nx': {
    symbol: 'AXSUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\AXSUSD.nx',
    full_name: 'AXSUSD.nx',
    description: 'Axie infinity',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCAXS.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'BCHNUSD.nx': {
    symbol: 'BCHNUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\BCHNUSD.nx',
    full_name: 'BCHNUSD.nx',
    description: 'BITCOIN CASH NODE vs US Dollar',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBCHN.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'BTCEUR.nx': {
    symbol: 'BTCEUR.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\BTCEUR.nx',
    full_name: 'BTCEUR.nx',
    description: 'BITCOINS VS EURO',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'BTCGBP.nx': {
    symbol: 'BTCGBP.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\BTCGBP.nx',
    full_name: 'BTCGBP.nx',
    description: 'BITCOINS VS GBP',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'BTCJPY.nx': {
    symbol: 'BTCJPY.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\BTCJPY.nx',
    full_name: 'BTCJPY.nx',
    description: 'BITCOINS VS JPY',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'BTCUSD.nx': {
    symbol: 'BTCUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\BTCUSD.nx',
    full_name: 'BTCUSD.nx',
    description: 'BITCOINS VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'DASHUSD.nx': {
    symbol: 'DASHUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\DASHUSD.nx',
    full_name: 'DASHUSD.nx',
    description: 'DASH VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCDASH.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'DOGEUSD.nx': {
    symbol: 'DOGEUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\DOGEUSD.nx',
    full_name: 'DOGEUSD.nx',
    description: 'DOGECOIN VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCDOGE.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'DOTUSD.nx': {
    symbol: 'DOTUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\DOTUSD.nx',
    full_name: 'DOTUSD.nx',
    description: 'Polkadot',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCDOT.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'EOSUSD.nx': {
    symbol: 'EOSUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\EOSUSD.nx',
    full_name: 'EOSUSD.nx',
    description: 'EOS VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCEOS.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'ETHGBP.nx': {
    symbol: 'ETHGBP.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ETHGBP.nx',
    full_name: 'ETHGBP.nx',
    description: 'ETHEREUM VS GBP',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'ETHJPY.nx': {
    symbol: 'ETHJPY.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ETHJPY.nx',
    full_name: 'ETHJPY.nx',
    description: 'ETHEREUM VS JPY',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'ETHUSD.nx': {
    symbol: 'ETHUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ETHUSD.nx',
    full_name: 'ETHUSD.nx',
    description: 'ETHEREUM VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'FILUSD.nx': {
    symbol: 'FILUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\FILUSD.nx',
    full_name: 'FILUSD.nx',
    description: 'Filecoin',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCFIL.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'GALAUSD.nx': {
    symbol: 'GALAUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\GALAUSD.nx',
    full_name: 'GALAUSD.nx',
    description: 'Gala',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCGALA.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'IOTAUSD.nx': {
    symbol: 'IOTAUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\IOTAUSD.nx',
    full_name: 'IOTAUSD.nx',
    description: 'IOTA VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCMIOTA.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'LINKUSD.nx': {
    symbol: 'LINKUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\LINKUSD.nx',
    full_name: 'LINKUSD.nx',
    description: 'Chainlink',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLINK.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'LTCEUR.nx': {
    symbol: 'LTCEUR.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\LTCEUR.nx',
    full_name: 'LTCEUR.nx',
    description: 'LITECOIN VS EURO',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'ETHEUR.nx': {
    symbol: 'ETHEUR.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ETHEUR.nx',
    full_name: 'ETHEUR.nx',
    description: 'ETHEREUM VS EURO',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'LTCUSD.nx': {
    symbol: 'LTCUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\LTCUSD.nx',
    full_name: 'LTCUSD.nx',
    description: 'LITECOIN VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'MANAUSD.nx': {
    symbol: 'MANAUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\MANAUSD.nx',
    full_name: 'MANAUSD.nx',
    description: 'Decentraland',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCMANA.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'NEOUSD.nx': {
    symbol: 'NEOUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\NEOUSD.nx',
    full_name: 'NEOUSD.nx',
    description: 'NEO VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCNEO.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'OMGUSD.nx': {
    symbol: 'OMGUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\OMGUSD.nx',
    full_name: 'OMGUSD.nx',
    description: 'OmiseGO VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCOMG.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'QTUMUSD.nx': {
    symbol: 'QTUMUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\QTUMUSD.nx',
    full_name: 'QTUMUSD.nx',
    description: 'QTUM VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCQTUM.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'SANDUSD.nx': {
    symbol: 'SANDUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\SANDUSD.nx',
    full_name: 'SANDUSD.nx',
    description: 'Sandbox',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCSAND.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'SHBUSD.nx': {
    symbol: 'SHBUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\SHBUSD.nx',
    full_name: 'SHBUSD.nx',
    description: 'Shiba Inu',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCSHIB.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'SOLUSD.nx': {
    symbol: 'SOLUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\SOLUSD.nx',
    full_name: 'SOLUSD.nx',
    description: 'Solana',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCSOL.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'LTCJPY.nx': {
    symbol: 'LTCJPY.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\LTCJPY.nx',
    full_name: 'LTCJPY.nx',
    description: 'LITECOIN VS Japanese Yen',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLTC.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'UNIUSD.nx': {
    symbol: 'UNIUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\UNIUSD.nx',
    full_name: 'UNIUSD.nx',
    description: 'Uniswap',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCUNI.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'USDTUSD.nx': {
    symbol: 'USDTUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\USDTUSD.nx',
    full_name: 'USDTUSD.nx',
    description: 'Tether VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCUSDT.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XLMUSD.nx': {
    symbol: 'XLMUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XLMUSD.nx',
    full_name: 'XLMUSD.nx',
    description: 'STELLAR LUMENS VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXLM.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XMRUSD.nx': {
    symbol: 'XMRUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XMRUSD.nx',
    full_name: 'XMRUSD.nx',
    description: 'MONERO VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXMR.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XRPEUR.nx': {
    symbol: 'XRPEUR.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XRPEUR.nx',
    full_name: 'XRPEUR.nx',
    description: 'RIPPLE VS EURO',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXRP.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  'XRPJPY.nx': {
    symbol: 'XRPJPY.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XRPJPY.nx',
    full_name: 'XRPJPY.nx',
    description: 'Ripple VS JPY',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXRP.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'XRPUSD.nx': {
    symbol: 'XRPUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XRPUSD.nx',
    full_name: 'XRPUSD.nx',
    description: 'Ripple vs US Dollar',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXRP.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XTZUSD.nx': {
    symbol: 'XTZUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\XTZUSD.nx',
    full_name: 'XTZUSD.nx',
    description: 'Tezos',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 4,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXTZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'TRXUSD.nx': {
    symbol: 'TRXUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\TRXUSD.nx',
    full_name: 'TRXUSD.nx',
    description: 'TRON VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 5,
    qty: {
      min: 0.01,
      max: 100000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCTRX.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'ZECUSD.nx': {
    symbol: 'ZECUSD.nx',
    path: 'CRYPTOS/FIAT\\CRYPTO.nx\\ZECUSD.nx',
    full_name: 'ZECUSD.nx',
    description: 'ZCASH VS USD',
    type: 'crypto',
    session:
      '1;0000-0000:1|0000-0000:2|0000-0000:3|0000-0000:4|0000-0000:5|0000-0000:6|0000-0000:7',
    decimals: 2,
    qty: {
      min: 1,
      max: 100000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCZEC.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
};

export const futures_symbols = {
  ASX200DEC24: {
    symbol: 'ASX200DEC24',
    path: 'CFD\\CFD.f\\Asia&Australian Indices\\ASX200DEC24',
    full_name: 'ASX200DEC24',
    description: 'Australia 200 Future Index (expiration 19/12/2024)',
    type: 'futures',
    session:
      '1;2350-0000:1|0000-0630,0710-1959,2350-0000:2|0000-0630,0710-1959,2350-0000:3|0000-0630,0710-1959,2350-0000:4|0000-0630,0710-1959,2350-0000:5|0000-0630,0710-1959:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 25,
    currency_code: 'AUD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/asx-200.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  CAC40OCT24: {
    symbol: 'CAC40OCT24',
    path: 'CFD\\CFD.f\\European Indices\\CAC40OCT24',
    full_name: 'CAC40OCT24',
    description: 'France 40 Future Index',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 20,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/cac-40.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  COCOADEC24: {
    symbol: 'COCOADEC24',
    path: 'CFD\\CFD.f\\Commodities\\COCOADEC24',
    full_name: 'COCOADEC24',
    description: 'US Cocoa Future Contract Dec 24 (exp. 21/11/2024)',
    type: 'futures',
    session: '1;0845-1730:2|0845-1730:3|0845-1730:4|0845-1730:5|0845-1730:6',
    decimals: 1,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/cocoa.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COFFEEDEC24: {
    symbol: 'COFFEEDEC24',
    path: 'CFD\\CFD.f\\Commodities\\COFFEEDEC24',
    full_name: 'COFFEEDEC24',
    description: 'US Coffee Future (Expiration 21/11/2024)',
    type: 'futures',
    session: '1;0815-1730:2|0815-1730:3|0815-1730:4|0815-1730:5|0815-1730:6',
    decimals: 2,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commodity/coffee.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  COTTONDEC24: {
    symbol: 'COTTONDEC24',
    path: 'CFD\\CFD.f\\Commodities\\COTTONDEC24',
    full_name: 'COTTONDEC24',
    description: 'US Cotton Future (Expiration 21/11/2024)',
    type: 'futures',
    session: '1;0100-1820:2|0100-1820:3|0100-1820:4|0100-1820:5|0100-1820:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commodity/cotton.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DAX40DEC24: {
    symbol: 'DAX40DEC24',
    path: 'CFD\\CFD.f\\European Indices\\DAX40DEC24',
    full_name: 'DAX40DEC24',
    description: 'Germany 30 Future Index (expiration 20/12/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 25,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dax.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DAX40SEP24: {
    symbol: 'DAX40SEP24',
    path: 'CFD\\CFD.f\\European Indices\\DAX40SEP24',
    full_name: 'DAX40SEP24',
    description: 'Germany 30 Future (Expiration 20/09/2024) - close only',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 25,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dax.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  DJI30DEC24: {
    symbol: 'DJI30DEC24',
    path: 'CFD\\CFD.f\\USA Indices\\DJI30DEC24',
    full_name: 'DJI30DEC24',
    description: 'US Dow Jones 30 Future Index',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 5,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dow-30.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DJI30SEP24: {
    symbol: 'DJI30SEP24',
    path: 'CFD\\CFD.f\\USA Indices\\DJI30SEP24',
    full_name: 'DJI30SEP24',
    description: 'US Dow Jones 30 Future (Expiration 19/09/2024) - close only',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 5,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/dow-30.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  DXDEC24: {
    symbol: 'DXDEC24',
    path: 'CFD\\CFD.f\\USA Indices\\DXDEC24',
    full_name: 'DXDEC24',
    description: 'Dollar Index Future Contract (exp. 13/12/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/u-s-dollar-index.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  FTSE100DEC24: {
    symbol: 'FTSE100DEC24',
    path: 'CFD\\CFD.f\\European Indices\\FTSE100DEC24',
    full_name: 'FTSE100DEC24',
    description: 'UK 100 Future Index (expiration 20/12/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 10,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/uk-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  FTSE100SEP24: {
    symbol: 'FTSE100SEP24',
    path: 'CFD\\CFD.f\\European Indices\\FTSE100SEP24',
    full_name: 'FTSE100SEP24',
    description: 'UK 100 Future (Expiration 20/09/2024) - close only',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 10,
    currency_code: 'GBP',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/uk-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  GOLDDEC24: {
    symbol: 'GOLDDEC24',
    path: 'CFD\\CFD.f\\Metals\\GOLDDEC24',
    full_name: 'GOLDDEC24',
    description: 'Gold Future (Expiration 28/11/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 100,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  HK50SEP24: {
    symbol: 'HK50SEP24',
    path: 'CFD\\CFD.f\\Asia&Australian Indices\\HK50SEP24',
    full_name: 'HK50SEP24',
    description: 'Hong Kong 50 Future Index (expiration 27/09/2024)',
    type: 'futures',
    session:
      '1;0115-0400,0500-0830,0915-1900:2|0115-0400,0500-0830,0915-1900:3|0115-0400,0500-0830,0915-1900:4|0115-0400,0500-0830,0915-1900:5|0115-0400,0500-0830,0915-1900:6',
    decimals: 1,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 50,
    currency_code: 'HKD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  IBEX35OCT24: {
    symbol: 'IBEX35OCT24',
    path: 'CFD\\CFD.f\\European Indices\\IBEX35OCT24',
    full_name: 'IBEX35OCT24',
    description: 'Spain 35 Future Index (expiration 18/10/2024)',
    type: 'futures',
    session: '1;0600-1759:2|0600-1759:3|0600-1759:4|0600-1759:5|0600-1759:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 10,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/ibex-35.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  IBEX35SEP24: {
    symbol: 'IBEX35SEP24',
    path: 'CFD\\CFD.f\\European Indices\\IBEX35SEP24',
    full_name: 'IBEX35SEP24',
    description: 'Spain 35 Future (Expiration 20/09/2024) - close only',
    type: 'futures',
    session: '1;0600-1759:2|0600-1759:3|0600-1759:4|0600-1759:5|0600-1759:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 10,
    currency_code: 'EUR',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/ibex-35.svg',
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
    ],
  },
  NDQ100DEC24: {
    symbol: 'NDQ100DEC24',
    path: 'CFD\\CFD.f\\USA Indices\\NDQ100DEC24',
    full_name: 'NDQ100DEC24',
    description: 'US Nasdaq 100 Future Index',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 20,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NDQ100SEP24: {
    symbol: 'NDQ100SEP24',
    path: 'CFD\\CFD.f\\USA Indices\\NDQ100SEP24',
    full_name: 'NDQ100SEP24',
    description: 'US Nasdaq 100 Future (Expiration 19/09/2024) - close only',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 20,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/nasdaq-100.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  NGASOCT24: {
    symbol: 'NGASOCT24',
    path: 'CFD\\CFD.f\\Energy\\NGASOCT24',
    full_name: 'NGASOCT24',
    description: 'Natural Gas Future Contract Oct 24 (exp.25/09/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 4,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/natural-gas.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SP500DEC24: {
    symbol: 'SP500DEC24',
    path: 'CFD\\CFD.f\\USA Indices\\SP500DEC24',
    full_name: 'SP500DEC24',
    description: 'US S&P 500  Future Index',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 2,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 50,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/indices/s-and-p-500.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  SUGOCT24: {
    symbol: 'SUGOCT24',
    path: 'CFD\\CFD.f\\Commodities\\SUGOCT24',
    full_name: 'SUGOCT24',
    description: 'Sugar Future (Expiration 27/09/2024)',
    type: 'futures',
    session: '1;0730-1700:2|0730-1700:3|0730-1700:4|0730-1700:5|0730-1700:6',
    decimals: 3,
    qty: {
      min: 1,
      max: 1000000,
      step: 1,
      default: 1,
    },
    contract_size: 1,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/commodity/sugar.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  UKOILNOV24: {
    symbol: 'UKOILNOV24',
    path: 'CFD\\CFD.f\\Energy\\UKOILNOV24',
    full_name: 'UKOILNOV24',
    description: 'Brent Crude Oil Futures Nov24 (exp. 30/09/2024)',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2200:2|0000-2200:3|0000-2200:4|0000-2200:5|0000-2200:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  USOILNOV24: {
    symbol: 'USOILNOV24',
    path: 'CFD\\CFD.f\\Energy\\USOILNOV24',
    full_name: 'USOILNOV24',
    description: 'WTI Crude Oil Futures Contract Nov24 (expiration 21/10/2024)',
    type: 'futures',
    session:
      '1;2205-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  USOILOCT24: {
    symbol: 'USOILOCT24',
    path: 'CFD\\CFD.f\\Energy\\USOILOCT24',
    full_name: 'USOILOCT24',
    description: 'WTI Crude Oil Futures (Expiration: 19/09/2024) - close only',
    type: 'futures',
    session:
      '1;2200-0000:1|0000-2100,2200-0000:2|0000-2100,2200-0000:3|0000-2100,2200-0000:4|0000-2100,2200-0000:5|0000-2100:6',
    decimals: 3,
    qty: {
      min: 0.01,
      max: 1000000,
      step: 0.01,
      default: 0.01,
    },
    contract_size: 1000,
    currency_code: 'USD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
};
